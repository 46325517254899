import { AxiosError } from "axios"

import { GetTeamsPayload, PatchTeamPayload, Team } from "app/models/team"

export enum Types {
  GET_ALL = "TEAM_GET_ALL",
  GET_ALL_SUCCESS = "TEAM_GET_ALL_SUCCESS",
  GET_ALL_FAIL = "TEAM_GET_ALL_FAIL",
  PATCH = "TEAM_PATCH",
  PATCH_SUCCESS = "TEAM_PATCH_SUCCESS",
  PATCH_FAIL = "TEAM_PATCH_FAIL",
}

export type Action =
  | GetTeamsAction
  | GetTeamsSuccessAction
  | GetTeamsFailAction
  | PatchTeamAction
  | PatchTeamSuccessAction
  | PatchTeamFailAction

export type GetTeamsAction = {
  payload: GetTeamsPayload
  type: Types.GET_ALL
}

export type GetTeamsSuccessAction = {
  payload: Team[]
  type: Types.GET_ALL_SUCCESS
}

export type GetTeamsFailAction = {
  payload: AxiosError
  type: Types.GET_ALL_FAIL
}

export type PatchTeamAction = {
  payload: PatchTeamPayload
  type: Types.PATCH
}

export type PatchTeamSuccessAction = {
  payload: { name: string; party_id: string }
  type: Types.PATCH_SUCCESS
}

export type PatchTeamFailAction = {
  payload: AxiosError
  type: Types.PATCH_FAIL
}

export function getTeams(payload: GetTeamsPayload): GetTeamsAction {
  return {
    payload,
    type: Types.GET_ALL,
  }
}

export function getTeamsSuccess(payload: Team[]): GetTeamsSuccessAction {
  return {
    payload,
    type: Types.GET_ALL_SUCCESS,
  }
}

export function getTeamsFail(payload: AxiosError): GetTeamsFailAction {
  return {
    payload,
    type: Types.GET_ALL_FAIL,
  }
}

export function patchTeam(data: PatchTeamPayload): PatchTeamAction {
  return {
    payload: data,
    type: Types.PATCH,
  }
}

export function patchTeamSuccess({
  name,
  party_id,
}: {
  name: string
  party_id: string
}): PatchTeamSuccessAction {
  return {
    payload: { name, party_id },
    type: Types.PATCH_SUCCESS,
  }
}

export function patchTeamFail(error: AxiosError): PatchTeamFailAction {
  return {
    payload: error,
    type: Types.PATCH_FAIL,
  }
}
