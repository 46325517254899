import { FeaturesEnum, Organisation } from "app/models/organisation"
import { PermissionEnum } from "app/models/permission"
import { RoleNames } from "app/models/roles"

import { IRoute } from "."
import { PartyRelation } from "../models/partyRelation"
import { ROUTE_PATHS } from "./config"

export const generateRouteKey = (path: string | string[] | undefined) => {
  if (Array.isArray(path)) return path[0]
  return path
}

export const addAccessToRoute = ({
  organisation,
  hasPermissions,
  route,
  partyRelation,
}: {
  organisation: Organisation | undefined
  hasPermissions: (permissions: PermissionEnum[]) => boolean | undefined
  route: IRoute
  partyRelation?: PartyRelation
}) => {
  const activeFeatures = organisation?.active_features

  if (route?.features || route?.permissions) {
    const { features, permissions } = route
    const hasActiveFeature =
      activeFeatures && features
        ? features.every((feature: FeaturesEnum) => {
            return activeFeatures[feature]
          })
        : true

    const hasActivePermission = permissions ? hasPermissions(permissions) : true

    const getHasAccess = () => {
      if (route.path === ROUTE_PATHS.TEAM_MEMBER_DASHBOARD) {
        const isGroupAdmin = partyRelation?.roles?.find(
          (el) =>
            el === RoleNames.GROUP_ADMIN ||
            el === RoleNames.ORGANIZATION_ADMIN ||
            el === RoleNames.ORGANIZATION_OWNER,
        )

        return hasActiveFeature && hasActivePermission && Boolean(isGroupAdmin)
      } else return hasActiveFeature && hasActivePermission
    }

    const hasAccess = getHasAccess()

    const routeWithAccess: IRoute = {
      ...route,
      hasAccess,
      ...(hasAccess && route.routes
        ? {
            routes: route.routes.map((e) =>
              addAccessToRoute({
                organisation,
                hasPermissions,
                route: e,
              }),
            ),
          }
        : { routes: undefined }),
    }

    return routeWithAccess
  }
  return route
}
