import {
  SurveyRequest,
  PostSurveyAnswersPayload
} from "app/models/surveyRequest"

export enum SurveyRequestActions {
  GET_SURVEY_QUESTIONS = "GET_SURVEY_QUESTIONS",
  GET_SURVEY_QUESTIONS_SUCCESS = "GET_SURVEY_QUESTIONS_SUCCESS",
  GET_SURVEY_QUESTIONS_FAIL = "GET_SURVEY_QUESTIONS_FAIL",
  POST_SURVEY_ANSWERS = "POST_SURVEY_ANSWERS",
  POST_SURVEY_ANSWERS_SUCCESS = "POST_SURVEY_ANSWERS_SUCCESS",
  POST_SURVEY_ANSWERS_FAIL = "POST_SURVEY_ANSWERS_FAIL"
}

export type Actions =
  | GetSurveyQuestions
  | GetSurveyQuestionsFail
  | GetSurveyQuestionsSuccess
  | PostSurveyAnswers
  | PostSurveyAnswersSuccess
  | PostSurveyAnswersFail

export interface GetSurveyQuestionsPayload {
  survey_request_id: string
}

export type GetSurveyQuestions = {
  payload: GetSurveyQuestionsPayload
  type: SurveyRequestActions.GET_SURVEY_QUESTIONS
}

export type GetSurveyQuestionsSuccess = {
  payload: SurveyRequest
  type: SurveyRequestActions.GET_SURVEY_QUESTIONS_SUCCESS
}

export type GetSurveyQuestionsFail = {
  payload: Record<string, unknown>
  type: SurveyRequestActions.GET_SURVEY_QUESTIONS_FAIL
}

export type PostSurveyAnswers = {
  payload: { data: PostSurveyAnswersPayload[]; survey_request_id: string }
  type: SurveyRequestActions.POST_SURVEY_ANSWERS
}

export type PostSurveyAnswersSuccess = {
  type: SurveyRequestActions.POST_SURVEY_ANSWERS_SUCCESS
}

export type PostSurveyAnswersFail = {
  payload: { errors: object }
  type: SurveyRequestActions.POST_SURVEY_ANSWERS_FAIL
}

export function getSurveyQuestions(
  data: GetSurveyQuestionsPayload
): GetSurveyQuestions {
  return {
    payload: data,
    type: SurveyRequestActions.GET_SURVEY_QUESTIONS
  }
}

export function getSurveyQuestionsSuccess(
  data: SurveyRequest
): GetSurveyQuestionsSuccess {
  return {
    payload: data,
    type: SurveyRequestActions.GET_SURVEY_QUESTIONS_SUCCESS
  }
}

export function getSurveyQuestionsFail(
  errors: Record<string, unknown>
): GetSurveyQuestionsFail {
  return {
    payload: errors,
    type: SurveyRequestActions.GET_SURVEY_QUESTIONS_FAIL
  }
}

export function postSurveyAnswers({
  data,
  survey_request_id
}: {
  data: PostSurveyAnswersPayload[]
  survey_request_id: string
}): PostSurveyAnswers {
  return {
    payload: { data, survey_request_id },
    type: SurveyRequestActions.POST_SURVEY_ANSWERS
  }
}

export function postSurveyAnswersSuccess(): PostSurveyAnswersSuccess {
  return {
    type: SurveyRequestActions.POST_SURVEY_ANSWERS_SUCCESS
  }
}

export function postSurveyAnswersFail(errors: object): PostSurveyAnswersFail {
  return {
    payload: { errors },
    type: SurveyRequestActions.POST_SURVEY_ANSWERS_FAIL
  }
}
