import {
  ISideBarNavigation,
  ISideBarRoute,
} from "app/context/NavigationContext"
import { FeaturesEnum } from "app/models/organisation"
import { PermissionEnum } from "app/models/permission"
import { ROUTE_PATHS } from "app/routes/config"

import DailyCheck from "assets/icons/DailyCheck"
import Dashboard from "assets/icons/Dashboard"

type ISideBarDropdownLinks = Omit<ISideBarNavigation, "to">

export const accountNavigation: ISideBarRoute[] = [
  {
    label: "route.account.groups.manage",
    routes: [
      {
        label: "route.account.groups.all",
        permissions: [PermissionEnum.ORGANIZATION_ADMIN],
        to: "/account/groups",
      },
      {
        label: "route.account.groups.my",
        to: "/account/my-groups",
      },
    ],
  },
  {
    features: [FeaturesEnum.FEATURE_CUSTOM_FUNCTIONS],
    label: "route.account.settings",
    permissions: [PermissionEnum.FUNCTION_READ],
    routes: [
      {
        label: "route.account.settings.functions",
        to: "/account/settings/functions",
      },
    ],
  },
]

export const dailyCheckNavigation: ISideBarRoute[] = [
  {
    features: [FeaturesEnum.FEATURE_DAILY_CHECK_DASHBOARD],
    label: "route.dailycheck.dashboard",
    permissions: [PermissionEnum.DAILY_CHECK_TREE_READ],
    to: `${ROUTE_PATHS.REALITY_CHECK_OVERVIEW}/dashboard/`,
  },
  {
    label: "route.dailycheck.settings",
    routes: [
      {
        features: [FeaturesEnum.FEATURE_DAILY_CHECK_EXTRA_NUANCES],
        label: "route.dailycheck.settings.nuance",
        to: `${ROUTE_PATHS.REALITY_CHECK_OVERVIEW}/settings/nuance`,
      },
      {
        label: "route.dailycheck.settings.preferences",
        permissions: [PermissionEnum.GROUP_UPDATE],
        to: `${ROUTE_PATHS.REALITY_CHECK_OVERVIEW}/settings/preferences`,
      },
    ],
  },
]

export const leadershipNavigation: ISideBarRoute[] = [
  {
    features: [FeaturesEnum.FEATURE_SURVEY_LEADERSHIP_DASHBOARD],
    label: "route.leadership.overview",
    permissions: [PermissionEnum.SURVEY_READ],
    to: "/leadership/surveys",
  },
]
export const climateNavigation: ISideBarRoute[] = [
  {
    features: [FeaturesEnum.FEATURE_SURVEY_CLIMATE_DASHBOARD],
    label: "route.climate.overview",
    permissions: [PermissionEnum.SURVEY_READ],
    to: "/climate/surveys",
  },
]

export const dropdownLinks: ISideBarDropdownLinks[] = [
  {
    icon: Dashboard,
    id: "account",
    label: "route.account",
    permissions: [PermissionEnum.PARTY_RELATION_METADATA_UPDATE],
    routes: accountNavigation,
    color: "accent2",
  },
  {
    icon: DailyCheck,
    id: "dailycheck",
    label: "route.dailycheck",
    permissions: [PermissionEnum.DAILY_CHECK_TREE_READ],
    routes: dailyCheckNavigation,
    color: "accent1",
  },
  {
    icon: DailyCheck,
    id: "leadership",
    label: "route.leadership",
    permissions: [PermissionEnum.SURVEY_READ],
    routes: leadershipNavigation,
    color: "accent2",
  },
  {
    icon: DailyCheck,
    id: "climate",
    label: "route.climate",
    permissions: [PermissionEnum.SURVEY_READ],
    routes: climateNavigation,
    color: "secondary",
  },
]
