import React from "react"
import { SvgIcon, SvgIconProps } from "@material-ui/core"

const FunToWorkWithLogoWithText = ({
  viewBox = "0 0 103 97",
  ...props
}: SvgIconProps) => {
  return (
    <SvgIcon viewBox={viewBox} {...props}>
      <path
        d="M102.11 54.3579H68.1555C67.6641 54.3579 67.2656 54.7563 67.2656 55.2478V62.2959C67.2656 62.7874 67.6641 63.1858 68.1555 63.1858H102.11C102.602 63.1858 103 62.7874 103 62.2959V55.2478C103 54.7563 102.602 54.3579 102.11 54.3579Z"
        fill="#32A18D"
      />
      <path
        d="M0 30.2472C0 32.5337 0.678049 34.7689 1.9484 36.6702C3.21875 38.5714 5.02435 40.0532 7.13687 40.9282C9.24938 41.8033 11.5739 42.0322 13.8166 41.5861C16.0592 41.14 18.1192 40.0389 19.7361 38.4221C21.3529 36.8052 22.454 34.7452 22.9001 32.5026C23.3462 30.26 23.1172 27.9354 22.2422 25.8229C21.3672 23.7104 19.8853 21.9048 17.9841 20.6344C16.0829 19.3641 13.8477 18.686 11.5611 18.686C8.49492 18.686 5.5543 19.9041 3.38617 22.0722C1.21804 24.2403 0 27.181 0 30.2472Z"
        fill="#F7AD38"
      />
      <path
        d="M69.8058 1.05346C69.4721 0.719483 69.0757 0.454548 68.6395 0.273792C68.2033 0.0930355 67.7358 0 67.2636 0C66.7914 0 66.3239 0.0930355 65.8877 0.273792C65.4515 0.454548 65.0552 0.719483 64.7214 1.05346L53.4577 12.3194C53.1237 12.6531 52.8588 13.0493 52.6781 13.4854C52.4973 13.9215 52.4043 14.389 52.4043 14.8611C52.4043 15.3332 52.4973 15.8006 52.6781 16.2367C52.8588 16.6728 53.1237 17.0691 53.4577 17.4027L64.7236 28.6687C65.0574 29.0027 65.4537 29.2676 65.8899 29.4484C66.3261 29.6291 66.7937 29.7222 67.2658 29.7222C67.738 29.7222 68.2056 29.6291 68.6418 29.4484C69.078 29.2676 69.4743 29.0027 69.8081 28.6687L81.0729 17.4061C81.4069 17.0724 81.6718 16.6762 81.8525 16.2401C82.0332 15.804 82.1263 15.3365 82.1263 14.8644C82.1263 14.3923 82.0332 13.9249 81.8525 13.4888C81.6718 13.0527 81.4069 12.6564 81.0729 12.3228L69.8058 1.05346Z"
        fill="#7DB2FF"
      />
      <path
        d="M63.0618 78.2645V68.2315C63.0618 68.0085 62.9732 67.7946 62.8155 67.6369C62.6578 67.4792 62.4439 67.3906 62.2209 67.3906H54.1949L54.1013 78.2645C54.1013 83.3879 50.0917 87.5413 45.1443 87.5413C40.1968 87.5413 36.1883 83.3879 36.1883 78.2645V67.3906H28.169C27.946 67.3906 27.7321 67.4792 27.5744 67.6369C27.4167 67.7946 27.3281 68.0085 27.3281 68.2315V78.2824C27.3281 88.5292 35.2561 96.8191 45.1487 96.8191C55.0414 96.8191 63.0618 88.5114 63.0618 78.2645Z"
        fill="#3B82E0"
      />
      <path
        d="M57.4292 63.1828V55.9076H60.374V63.1828H62.9357V53.7157H57.4303V49.4721H54.8686V63.1828H57.4292ZM48.3941 63.1828H52.876V60.9553H50.9547V55.9076H53.15V53.7157H50.9547V51.2976H48.393V53.7157H47.502V55.9109H48.393L48.3941 63.1828ZM45.525 53.7157H42.9633V63.1828H45.525V53.7157ZM44.2241 52.4682C44.5201 52.4647 44.8084 52.3737 45.0528 52.2067C45.2972 52.0397 45.4867 51.8041 45.5975 51.5296C45.7083 51.2551 45.7354 50.9539 45.6754 50.6641C45.6155 50.3742 45.4711 50.1086 45.2605 49.9006C45.0498 49.6926 44.7824 49.5515 44.4918 49.4952C44.2012 49.4389 43.9004 49.4698 43.6273 49.584C43.3543 49.6982 43.1211 49.8907 42.9571 50.1371C42.7932 50.3836 42.7058 50.673 42.706 50.9691C42.706 51.1675 42.7454 51.364 42.8219 51.5472C42.8984 51.7303 43.0106 51.8964 43.1518 52.0359C43.293 52.1753 43.4605 52.2854 43.6446 52.3596C43.8287 52.4338 44.0256 52.4707 44.2241 52.4682ZM41.2792 53.7157H38.7922L37.5481 60.9553H37.4745L36.1948 53.7157H32.3901L31.1093 60.9553H31.0369L29.8117 53.7157H27.3057L29.3539 63.1828H32.8468L34.2557 55.9042H34.3281L35.7237 63.1828H39.2533L41.2792 53.7157Z"
        fill="#FEF6E9"
      />
      <path
        d="M82.3852 46.8678H84.9469V43.2101C85.1295 43.2279 85.3122 43.2279 85.477 43.2279C85.6786 43.2279 85.8802 43.2279 86.0629 43.2101L88.0933 46.8678H90.968L88.4063 42.4973C89.8698 41.6063 90.4178 40.0091 90.4178 38.4732C90.4209 38.1127 90.4026 37.7523 90.3632 37.3939H87.9129C87.9496 37.8873 87.9496 38.1435 87.9496 38.4364C87.9496 40.1004 86.8526 41.0705 85.3879 41.0705C85.2414 41.0737 85.0948 41.0674 84.9491 41.0516V33.1504H82.3874L82.3852 46.8678ZM76.1847 37.3939V46.8678H78.7464V39.6438H80.759V37.3939H76.1847ZM69.2347 44.8931C67.7533 44.8931 66.7286 43.7403 66.7286 42.0941C66.7286 40.4669 67.7533 39.3319 69.2347 39.3319C70.7338 39.3319 71.7407 40.4669 71.7407 42.0941C71.7407 43.7403 70.7383 44.8931 69.2347 44.8931ZM69.2347 47.1607C72.1795 47.1607 74.3191 45.0445 74.3191 42.1309C74.3191 39.2038 72.1795 37.101 69.2347 37.101C66.2898 37.101 64.1681 39.2038 64.1681 42.1309C64.1681 45.039 66.2842 47.1607 69.2347 47.1607ZM63.2347 37.3939H60.7476L59.5035 44.6335H59.43L58.1503 37.3939H54.3456L53.0647 44.6335H52.9879L51.7627 37.3939H49.2611L51.3094 46.8678H54.8033L56.2112 39.5881H56.2847L57.6747 46.8678H61.2043L63.2347 37.3939ZM39.0365 44.8931C37.5541 44.8931 36.5305 43.7403 36.5305 42.0941C36.5305 40.4669 37.5541 39.3319 39.0365 39.3319C40.519 39.3319 41.5426 40.4669 41.5426 42.0941C41.5426 43.7403 40.5402 44.8931 39.0365 44.8931ZM39.0365 47.1607C41.9814 47.1607 44.121 45.0445 44.121 42.1309C44.121 39.2038 41.9814 37.101 39.0365 37.101C36.0917 37.101 33.9699 39.2038 33.9699 42.132C33.9699 45.0401 36.0861 47.1618 39.0365 47.1618M28.2261 46.8689H32.708V44.6413H30.7867V39.5881H32.982V37.3939H30.7867V34.9792H28.225V37.3939H27.334V39.5881H28.225L28.2261 46.8689Z"
        fill="#FEF6E9"
      />
      <path
        d="M44.9256 22.9296V32.4046H47.4873V25.1248H50.4133V32.4046H52.975V22.9296H44.9256ZM42.4374 32.4046V22.9296H39.8757V30.1692H37.0233V22.9296H34.4616V32.4046H42.4374ZM27.3701 25.1248H28.2611V32.4046H30.8229V25.1248H32.9391V22.9296H30.8229V20.8267H32.9814V18.686H28.2611V22.9296H27.3701V25.1248Z"
        fill="#FEF6E9"
      />
    </SvgIcon>
  )
}

export default FunToWorkWithLogoWithText
