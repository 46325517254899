import styled from "@emotion/styled"
import { Paper } from "@material-ui/core"
import dailyCheckBackgroundBottomRight from "assets/images/daily_check_background_bottom_right.svg"
import dailyCheckBackgroundTopLeft from "assets/images/daily_check_background_top_left.svg"
import leadershipBackgroundMiddleLeft from "assets/images/leadership_background_middle_left.svg"
import leadershipBackgroundTopRight from "assets/images/leadership_background_top_right.svg"
import climateBackgroundTopLeft from "assets/images/climate_background_top_left.svg"
import climateBackgroundMiddleRight from "assets/images/climate_background_middle_right.svg"
import climateBackgroundBottomLeft from "assets/images/climate_background_bottom_left.svg"
import funToWorkWithTheme from "style/funToWorkWithTheme"
import customBackgroundMiddleLeft from "assets/images/custom_background_middle_left.svg"
import customBackgroundTopRight from "assets/images/custom_background_top_right.svg"

const SurveyBackground = styled.div`
  position: fixed;
  width: 100%;
  height: 100vh;
  top: 0;
  left: 0;

  overflow: auto;
  padding: 10rem 12rem;
`

export const DailyCheckSurveyWrapper = styled(SurveyBackground)`
  background-color: ${funToWorkWithTheme.colors.secondaryLight};
  background-image: url(${dailyCheckBackgroundTopLeft}),
    url(${dailyCheckBackgroundBottomRight});
  background-position: top left, bottom right;
  background-repeat: no-repeat;
`

export const LeadershipSurveyWrapper = styled(SurveyBackground)`
  background-color: ${funToWorkWithTheme.colors.primary};
  background-image: url(${leadershipBackgroundMiddleLeft}),
    url(${leadershipBackgroundTopRight});
  background-position: center left, top right;
  background-repeat: no-repeat;
`

export const CustomSurveyWrapper = styled(SurveyBackground)`
  background-color: ${funToWorkWithTheme.colors.primary};
  background-image: url(${customBackgroundMiddleLeft}),
    url(${customBackgroundTopRight});
  background-position: center left, top right;
  background-repeat: no-repeat;
`

export const ClimateSurveyWrapper = styled(SurveyBackground)`
  background-color: ${funToWorkWithTheme.colors.primary};
  background-image: url(${climateBackgroundTopLeft}),
    url(${climateBackgroundMiddleRight}), url(${climateBackgroundBottomLeft});
  background-position: top left, center right, bottom left;
  background-repeat: no-repeat;
`

export const SurveyContent = styled(Paper)`
  width: 64rem;
  margin-bottom: 15rem;
`

export const LogoContainer = styled.div`
  position: fixed;
  bottom: 0;
  right: 0;
  padding: 3.2rem 5.6rem;
  font-size: 14rem;
`
