import * as api from "app/api/nuance"
import { SagaIterator } from "redux-saga"
import { call, put, takeLatest } from "redux-saga/effects"
import * as actions from "./nuances.actions"

export function* getNuancesFlow({
  payload
}: actions.GetNuancesAction): SagaIterator {
  try {
    const response = yield call(api.getNuances, payload)
    yield put(actions.getNuancesSuccess(response.data))
  } catch (e) {
    yield put(actions.getNuancesFail(e))
  }
}

export function* putNuanceFlow({
  payload
}: actions.PutNuancesAction): SagaIterator {
  try {
    const response = yield call(api.putNuance, payload)
    yield put(actions.getNuances({ id: payload.party_id }))
    yield put(actions.putNuanceSuccess(response.data))
  } catch (e) {
    yield put(actions.putNuanceFail(e))
  }
}

export function* archiveNuanceFlow({
  payload
}: actions.ArchiveNuancesAction): SagaIterator {
  try {
    yield call(api.archiveNuance, payload)
    yield put(actions.getNuances({ id: payload.party_id }))
    yield put(actions.archiveNuanceSuccess())
  } catch (e) {
    yield put(actions.archiveNuanceFail(e))
  }
}

export function* deleteNuanceFlow({
  payload
}: actions.DeleteNuancesAction): SagaIterator {
  try {
    yield call(api.deleteNuance, payload)
    yield put(actions.getNuances(payload))
    yield put(actions.deleteNuanceSuccess())
  } catch (e) {
    yield put(actions.deleteNuanceFail(e))
  }
}

export default function* nuancesSaga(): SagaIterator {
  yield takeLatest(actions.Types.GET, getNuancesFlow)
  yield takeLatest(actions.Types.PUT, putNuanceFlow)
  yield takeLatest(actions.Types.ARCHIVE, archiveNuanceFlow)
  yield takeLatest(actions.Types.DELETE, deleteNuanceFlow)
}
