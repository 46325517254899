import { useSelector } from "react-redux"
import { getPreferredLanguage } from "app/redux/me/me.selectors"
import { locale, Locales, translate } from "i18n/config"

export const useTranslation = () => {
  const language = useSelector(getPreferredLanguage) || locale
  const handleTranslate = (message: string) => {
    return translate(message, language as Locales)
  }

  return handleTranslate
}
