import { MultipleInvites } from "app/models/invite"

export enum Types {
  POST = "INVITES_POST",
  POST_SUCCESS = "INVITES_POST_SUCCESS",
  POST_FAIL = "INVITES_POST_FAIL",
  TOGGLE_MODAL = "TOGGLE_INVITATION_MODAL"
}

export type Action =
  | PostInvitesAction
  | PostInvitesSuccessAction
  | PostInvitesFailAction
  | ToggleInvitationModalAction

export type PostInvitesAction = {
  payload: MultipleInvites
  type: Types.POST
}

export type PostInvitesSuccessAction = {
  type: Types.POST_SUCCESS
}

export type PostInvitesFailAction = {
  payload: { errors: object }
  type: Types.POST_FAIL
}

export type ToggleInvitationModalAction = {
  type: Types.TOGGLE_MODAL
}

export function postInvites(payload: MultipleInvites): PostInvitesAction {
  return {
    payload,
    type: Types.POST
  }
}

export function postInvitesSuccess(): PostInvitesSuccessAction {
  return {
    type: Types.POST_SUCCESS
  }
}

export function postInvitesFail(errors: object): PostInvitesFailAction {
  return {
    payload: { errors },
    type: Types.POST_FAIL
  }
}

export function toggleInvitationModal(): ToggleInvitationModalAction {
  return {
    type: Types.TOGGLE_MODAL
  }
}
