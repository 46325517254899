import React from "react"
import { useSelector } from "react-redux"
import { useHistory } from "react-router"

import { ISideBarNavigation } from "app/context/NavigationContext"
import * as meSelector from "app/redux/me/me.selectors"

import Translation from "components/Translation"

import { SidebarNavigationLink, SidebarNavigationTrigger } from "./style"

type Props = { item: ISideBarNavigation }

const NavigationItemLink = ({ item }: Props) => {
  const history = useHistory()
  const me = useSelector(meSelector.me)
  const redirectUserOnClick = () => {
    if (item.label === "route.dashboard" && me) {
      const updatedToPath = item.to?.replace(":id", me.party_id)
      updatedToPath && history.push(updatedToPath)
    } else {
      item?.to && history.push(item.to)
    }
  }

  return (
    <SidebarNavigationTrigger variant="h3" isActive={item?.isActive}>
      <SidebarNavigationLink onClick={redirectUserOnClick}>
        <Translation label={item.label} />
      </SidebarNavigationLink>
    </SidebarNavigationTrigger>
  )
}

export default NavigationItemLink
