import React from "react"

import { Navigation } from "../SidebarNew/Navigation"
import TopNavigationAvatar from "../TopNavigationAvatar"
import {
  AvatarWrapper,
  NavigationItemsWrapper,
  TopNavigationWrapper,
} from "./style"

const TopNavigation = () => {
  return (
    <TopNavigationWrapper>
      <NavigationItemsWrapper>
        <Navigation />
      </NavigationItemsWrapper>
      <AvatarWrapper>
        <TopNavigationAvatar />
      </AvatarWrapper>
    </TopNavigationWrapper>
  )
}

export default TopNavigation
