import { Typography } from "@material-ui/core"
import React from "react"

import Divider from "components/Divider"
import Translation from "components/Translation/Translation"

import Avatar from "../Avatar"
import { EditProfileDrawerProps } from "./EditProfileDrawer"
import {
  AvatarWrapper,
  CloseButton,
  CloseButtonIcon,
  EditProfileHeaderWrapper,
} from "./style"

type EditProfileHeaderProps = Pick<EditProfileDrawerProps, "onClose"> & {
  showClose: boolean
}

const EditProfileHeader: React.FC<EditProfileHeaderProps> = ({
  showClose,
  onClose,
}) => {
  return (
    <EditProfileHeaderWrapper>
      {showClose && (
        <>
          <CloseButton onClick={onClose}>
            <CloseButtonIcon />
            <Typography>
              <Translation label="action.close" />
            </Typography>
          </CloseButton>

          <Divider />
        </>
      )}

      <Typography variant="h4" component="h2" gutterBottom>
        <Translation label="profile.edit" />
      </Typography>
      <AvatarWrapper>
        <Avatar size={48} showName={false} />
      </AvatarWrapper>
    </EditProfileHeaderWrapper>
  )
}

export default EditProfileHeader
