import { ChipClassKey } from "@material-ui/core"
import {
  CreateCSSProperties,
  CSSProperties
} from "@material-ui/core/styles/withStyles"
import { PropsFunc } from "../types"

type Chip = Partial<
  Record<
    ChipClassKey,
    | CSSProperties
    | CreateCSSProperties<{}>
    | PropsFunc<{}, CreateCSSProperties<{}>>
  >
>
export const chip: Chip = {
  root: {
    borderRadius: "none",
    fontSize: "2rem",
    padding: ".6rem 1.4rem",
    height: "auto"
  },

  outlined: {
    borderColor: "currentColor"
  }
}
