import { Action, Types } from "./partySettings.actions"
import { PartySettings } from "app/models/partySettings"

export type PartySettingsState = {
  errors: object
  loading: boolean
  settings: PartySettings | undefined
}

const initialState: PartySettingsState = {
  errors: {},
  loading: false,
  settings: undefined
}

export default (state = initialState, action: Action): PartySettingsState => {
  switch (action.type) {
    case Types.GET:
      return {
        ...state,
        loading: true
      }

    case Types.GET_SUCCESS:
      return {
        ...state,
        loading: false,
        settings: action.payload
      }

    case Types.GET_FAIL:
      return {
        ...state,
        errors: action.payload.errors,
        loading: false
      }

    case Types.PATCH:
      return {
        ...state,
        loading: true
      }

    case Types.PATCH_SUCCESS:
      return {
        ...state,
        loading: false,
        settings: action.payload
      }

    case Types.PATCH_FAIL:
      return {
        ...state,
        errors: action.payload.errors,
        loading: false
      }

    default:
      return state
  }
}
