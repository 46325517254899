import React from "react"

interface ILayoutContext {
  aside?: React.ReactNode
  setAside: React.Dispatch<React.SetStateAction<any>>
}

export const LayoutContext = React.createContext<ILayoutContext | null>(null)
export const useLayout = () => React.useContext(LayoutContext)!
export const LayoutProvider: React.FC = ({ children }) => {
  const [aside, setAside] = React.useState()

  return (
    <LayoutContext.Provider value={{ aside, setAside }}>
      {children}
    </LayoutContext.Provider>
  )
}
