import React, { Fragment } from "react"
import { matchPath, useLocation } from "react-router"

import {
  ISideBarNavigation,
  ISideBarRoute,
  useNavigation,
} from "app/context/NavigationContext"

import Translation from "components/Translation"

import {
  SidebarArrow,
  SidebarBullet,
  SidebarLink,
  SidebarNav,
  SidebarSubNav,
} from "./style"

export const Navigation: React.FC = () => {
  const location = useLocation()
  const { navigation = [] } = useNavigation()

  const findNavigationById = (
    id?: string,
  ): ISideBarNavigation[] | undefined => {
    const result = navigation.find((n) => n.id === id)
    return result?.routes
  }

  const navigationRoute = navigation.find(({ to }) => {
    if (!to) return false

    const match = matchPath(to, {
      exact: false,
      path: location.pathname.split("/", 2).join("/"),
    })

    return !!match
  })
  const navigationKey = navigationRoute ? navigationRoute.id : undefined
  const currentNavigation = findNavigationById(navigationKey)
  let navigationDepth = 0

  const navigationItemRender = ({ to, label, routes }: any) => {
    navigationDepth = navigationDepth + 1
    const findRouteMatch = (r: ISideBarRoute) => {
      const { to: routeTo } = r
      const routeMatch = matchPath(location.pathname, {
        path: routeTo,
      })
      return !!routeMatch
    }
    const foundRouteMatch = routes ? routes.find(findRouteMatch) : undefined
    const toFromRoutes = foundRouteMatch ? foundRouteMatch.to : undefined
    const currentTo = to || toFromRoutes

    const match = matchPath(location.pathname, {
      path: currentTo,
    })

    const hasSubNavigation = routes && routes.length > 0
    const isOpen = hasSubNavigation && !!match
    const url = hasSubNavigation ? routes[0].to : to

    return (
      <Fragment key={url}>
        <SidebarLink to={url} exact={false}>
          {navigationDepth === 1 ||
          (navigationDepth > 1 && hasSubNavigation) ? (
            <SidebarArrow isOpen={isOpen ? isOpen : undefined} />
          ) : (
            <SidebarBullet />
          )}
          <Translation label={label} />
        </SidebarLink>
        {isOpen && (
          <SidebarSubNav>{routes.map(navigationItemRender)}</SidebarSubNav>
        )}
      </Fragment>
    )
  }

  return (
    <>
      {currentNavigation && (
        <SidebarNav>{currentNavigation.map(navigationItemRender)}</SidebarNav>
      )}
    </>
  )
}
