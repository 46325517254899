import { push } from "connected-react-router"
import { SagaIterator } from "redux-saga"
import { call, put, takeLatest } from "redux-saga/effects"

import * as api from "app/api/users"
import { ROUTE_PATHS } from "app/routes/config"

import * as actions from "./dailyCheck.actions"

export function* getDailyCheckFlow(
  action: actions.GetDailyCheckAction,
): SagaIterator {
  try {
    const dailyCheck = yield call(api.getDailyCheck, {
      data: action.payload,
      partyRelationId: action.payload.partyRelationId!,
    })
    yield put(actions.getDailyCheckSuccess(dailyCheck.data))
  } catch (e) {
    if (e.response.status === 401) {
      yield put(push("/signup"))
    }
    if (e.response.status === 500) {
      yield put(push(ROUTE_PATHS.REALITY_CHECK_OVERVIEW))
    }
    yield put(actions.getDailyCheckFail(e))
  }
}

export function* postDailyCheckFlow(
  action: actions.PostDailyCheckAction,
): SagaIterator {
  try {
    const {
      payload,
      payload: { party_relation_id },
    } = action
    yield call(api.postDailyCheck, payload.data, party_relation_id)
    yield put(actions.postDailyCheckSuccess())
    yield put(push(`/survey/${party_relation_id}`))
  } catch (e) {
    yield put(actions.postDailyCheckFail(e))
  }
}

export function* getRecentCommentsFlow(
  action: actions.GetRecentCommentsAction,
): SagaIterator {
  try {
    const response = yield call(api.getDailyCheckComments, {
      ...action.payload,
    })
    yield put(actions.getRecentCommentsSuccess(response.data))
  } catch (e) {
    yield put(actions.getRecentCommentsFail(e))
  }
}

export function* getAllCommentsFlow(
  action: actions.GetRecentCommentsAction,
): SagaIterator {
  try {
    const response = yield call(api.getDailyCheckComments, {
      ...action.payload,
    })
    yield put(actions.getAllCommentsSuccess(response.data))
  } catch (e) {
    yield put(actions.getAllCommentsFail(e))
  }
}

export default function* dailyCheckSaga(): SagaIterator {
  yield takeLatest(actions.Types.GET, getDailyCheckFlow)
  yield takeLatest(actions.Types.GET_RECENT_COMMENTS, getRecentCommentsFlow)
  yield takeLatest(actions.Types.GET_ALL_COMMENTS, getAllCommentsFlow)
  yield takeLatest(actions.Types.POST, postDailyCheckFlow)
}
