import { IconButtonClassKey } from "@material-ui/core"
import {
  CreateCSSProperties,
  CSSProperties
} from "@material-ui/core/styles/withStyles"
import funToWorkWithTheme from "style/funToWorkWithTheme"
import { PropsFunc } from "../types"

type IconButton = Partial<
  Record<
    IconButtonClassKey,
    | CSSProperties
    | CreateCSSProperties<{}>
    | PropsFunc<{}, CreateCSSProperties<{}>>
  >
>
export const iconButton: IconButton = {
  root: {
    color: "currentColor",
    padding: ".9rem",
    borderRadius: "0",

    "&:hover": {
      backgroundColor: funToWorkWithTheme.colors.primaryExtraLight
    }
  }
}
