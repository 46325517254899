import React, { memo } from "react"
import { Route, Switch } from "react-router-dom"

import { LayoutProvider } from "app/context/LayoutContext"
import { useCreateRoutesObject } from "app/hooks/useCreateRoutesObject"
import { IRoute, IRouteComponent } from "app/routes"

import { ErrorBoundary } from "components/ErrorBoundary"
import GeneralError from "components/GeneralError"
import LoadingIndicator from "components/LoadingIndicator"

import PrivateRoute from "../components/Router/PrivateRoute"
import Loader from "./Loader"
import { LoaderWrapper } from "./style"
import { generateRouteKey } from "./utils"

const NoAccess = React.lazy(() => import("../components/NoAccess"))

interface AllRoutes {
  [key: string]: {
    routes: IRoute[]
    layout: React.ComponentType<IRouteComponent>
    withSidebar?: boolean
  }
}

function RouteSwitcher({ allRoutes }: { allRoutes: AllRoutes }) {
  return (
    <Switch>
      {Object.values(allRoutes).map((element) => {
        const Layout = element.layout
        return element.routes.map(
          ({
            authorized = true,
            withSidebar = true,
            hasAccess = true,
            component: Component,
            routes: subRoutes,
            path,
            ...rest
          }) => {
            const key = generateRouteKey(path)

            const render = Component
              ? (props: any) => (
                  <Layout withSidebar={withSidebar} pathKey={key}>
                    <ErrorBoundary element={GeneralError}>
                      <React.Suspense fallback={<Loader />}>
                        <Component {...props} {...rest} />{" "}
                        <SubRoutes routes={subRoutes} />{" "}
                      </React.Suspense>
                    </ErrorBoundary>
                  </Layout>
                )
              : undefined

            if (!hasAccess) {
              return (
                <Route
                  key={key}
                  path={path}
                  component={(props: any) => (
                    <Layout withSidebar={false}>
                      <ErrorBoundary element={GeneralError}>
                        <React.Suspense fallback={<Loader />}>
                          <NoAccess {...props} {...rest} />
                          <SubRoutes routes={subRoutes} />
                        </React.Suspense>
                      </ErrorBoundary>
                    </Layout>
                  )}
                  {...rest}
                />
              )
            }

            return authorized ? (
              <PrivateRoute
                key={key}
                path={path}
                component={render}
                {...rest}
              />
            ) : (
              <Route key={key} path={path} component={render} {...rest} />
            )
          },
        )
      })}
    </Switch>
  )
}

// SubRoutes are necessary to make breadcrumbs work on /account/* pages
function SubRoutes({ routes }: { routes?: IRoute[] }) {
  if (!routes) return null

  return (
    <Switch>
      {routes.map(
        ({
          path,
          component: Component,
          routes: subRoutes,
          hasAccess = true,
          ...rest
        }: IRoute) => {
          const key = generateRouteKey(path)
          let render = Component
            ? (props: any) => (
                <>
                  <Component {...props} {...rest} />
                  <SubRoutes routes={subRoutes} />
                </>
              )
            : undefined

          if (!hasAccess) {
            render = (props: any) => (
              <React.Suspense fallback={<Loader />}>
                <NoAccess {...props} {...rest} />
                <SubRoutes routes={subRoutes} />
              </React.Suspense>
            )
          }

          return <Route key={key} path={path} component={render} {...rest} />
        },
      )}
    </Switch>
  )
}

function Router() {
  const allRoutes = useCreateRoutesObject()

  if (!allRoutes)
    return (
      <LoaderWrapper>
        <LoadingIndicator />
      </LoaderWrapper>
    )

  return (
    <LayoutProvider>
      <RouteSwitcher allRoutes={allRoutes} />
    </LayoutProvider>
  )
}

export default memo(Router)
