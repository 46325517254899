import Nuance, { GetNuancesPayload } from "app/models/nuance"

export enum Types {
  GET_PARTYRELATION_NUANCES = "NUANCES_GET_PARTYRELATIONS",
  GET_PARTYRELATION_NUANCES_SUCCESS = "NUANCES_GET_PARTYRELATIONS_SUCCESS",
  GET_PARTYRELATION_NUANCES_NOT_FOUND = "NUANCES_GET_PARTYRELATIONS_NOT_FOUND",
  GET_PARTYRELATION_NUANCES_FAIL = "NUANCES_GET_PARTYRELATIONS_FAIL"
}

export type Action =
  | GetPartyRelationNuancesAction
  | GetPartyRelationNuancesSuccessAction
  | GetPartyRelationNuancesNotFoundAction
  | GetPartyRelationNuancesFailAction

export type GetPartyRelationNuancesAction = {
  payload: GetNuancesPayload
  type: Types.GET_PARTYRELATION_NUANCES
}

export type GetPartyRelationNuancesSuccessAction = {
  payload: Nuance
  type: Types.GET_PARTYRELATION_NUANCES_SUCCESS
}

export type GetPartyRelationNuancesNotFoundAction = {
  type: Types.GET_PARTYRELATION_NUANCES_NOT_FOUND
}

export type GetPartyRelationNuancesFailAction = {
  payload: { errors: object }
  type: Types.GET_PARTYRELATION_NUANCES_FAIL
}

export function getPartyRelationNuances(
  data: GetNuancesPayload
): GetPartyRelationNuancesAction {
  return {
    payload: data,
    type: Types.GET_PARTYRELATION_NUANCES
  }
}

export function getPartyRelationNuancesSuccess(
  data: Nuance
): GetPartyRelationNuancesSuccessAction {
  return {
    payload: data,
    type: Types.GET_PARTYRELATION_NUANCES_SUCCESS
  }
}

export function getPartyRelationNuancesFail(
  errors: object
): GetPartyRelationNuancesFailAction {
  return {
    payload: { errors },
    type: Types.GET_PARTYRELATION_NUANCES_FAIL
  }
}

export function getPartyRelationNuancesNotFound(): GetPartyRelationNuancesNotFoundAction {
  return {
    type: Types.GET_PARTYRELATION_NUANCES_NOT_FOUND
  }
}
