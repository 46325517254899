import styled from "@emotion/styled"
import Select from "@material-ui/core/Select"
import { BaseFormControl } from "style/form"

export const SelectWrapper = styled.div`
  ${BaseFormControl}
`

export const StyledSelect = styled(Select)`
  width: 100%;
`
