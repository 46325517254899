import CircularProgress from "@material-ui/core/CircularProgress"
import React, { memo } from "react"
import { StyledLoadingContainer } from "./style"

type Props = {
  color?: "primary" | "secondary" | "inherit"
}

function LoadingIndicator({ color = "primary" }: Props) {
  return (
    <>
      <StyledLoadingContainer>
        <CircularProgress color={color} />
      </StyledLoadingContainer>
    </>
  )
}

export default memo(LoadingIndicator)
