import { TokenPayload } from "./token"

export enum LanguageEnum {
  ENGLISH = "en",
  DUTCH = "nl",
  FRENCH = "fr",
}

export interface Invite {
  first_name: string
  last_name: string
  email: string
  language: LanguageEnum
}

export interface MultipleInvites extends Partial<TokenPayload> {
  data: Invite[]
  party_id: string
  organisation_id?: string
  teamId?: string
}

export interface InvitePayload extends Invite, Partial<TokenPayload> {
  party_id: string
}
