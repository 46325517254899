import CssBaseline from "@material-ui/core/CssBaseline"
import { MuiThemeProvider, withStyles } from "@material-ui/core/styles"
import { StylesProvider } from "@material-ui/styles"
import { ConnectedRouter } from "connected-react-router"
import { ThemeProvider } from "emotion-theming"
import { ENV_FEATURE_FLAG } from "enums"
import React, { useEffect } from "react"
import { Provider } from "react-redux"

import ConnectedIntlProvider from "app/components/ConnectedIntlProvider"
import { setNewNavigation } from "app/components/NavigationSwitch"
import Router from "app/routes/router"

import applyFeature from "utils/applyFeature"

import { API_URLS, AUDIENCE, CLIENT_ID, REDIRECT_URI } from "./app/api/config"
import { Auth0Provider } from "./app/context/AuthContext"
import store, { history } from "./store"
import theme, { styles } from "./style/theme"

const onRedirectCallback = (appState: any) => {
  history.push(
    appState && appState.targetUrl
      ? appState.targetUrl
      : window.location.pathname,
  )
}

function App() {
  //remove when refactored new navigation checks
  useEffect(() => {
    const isNewNavigation = applyFeature(ENV_FEATURE_FLAG.IS_NEW_NAVIGATION)
    if (!isNewNavigation) {
      setNewNavigation(true)
      window.location.reload()
    }
  }, [])

  return (
    <StylesProvider injectFirst>
      <MuiThemeProvider theme={theme}>
        <ThemeProvider theme={theme}>
          <Provider store={store}>
            <CssBaseline />
            <ConnectedIntlProvider>
              <ConnectedRouter history={history}>
                <Auth0Provider
                  domain={API_URLS.oauth}
                  client_id={CLIENT_ID}
                  redirect_uri={REDIRECT_URI || window.location.origin}
                  audience={AUDIENCE}
                  onRedirectCallback={onRedirectCallback}
                >
                  <Router />
                </Auth0Provider>
              </ConnectedRouter>
            </ConnectedIntlProvider>
          </Provider>
        </ThemeProvider>
      </MuiThemeProvider>
    </StylesProvider>
  )
}

export default withStyles(styles)(App)
