import { Organisation } from "app/models/organisation"
import { TokenPayload } from "app/models/token"

export enum Types {
  POST = "ORGANISATIONS_POST",
  POST_SUCCESS = "ORGANISATIONS_POST_SUCCESS",
  POST_FAIL = "ORGANISATIONS_POST_FAIL",
  GET = "ORGANISATIONS_GET",
  GET_SUCCESS = "ORGANISATIONS_GET_SUCCESS",
  GET_FAIL = "ORGANISATIONS_GET_FAIL",
  GET_NAME = "ORGANISATIONS_GET_NAME",
  CHECK = "ORGANISATION_CHECK"
}

export type Action =
  | PostOrganisationsAction
  | PostOrganisationsSuccessAction
  | PostOrganisationsFailAction
  | GetOrganisationsAction
  | GetOrganisationsSuccessAction
  | GetOrganisationsFailAction
  | GetOrganisationNameAction

export type PostOrganisationsAction = {
  payload: { data: Organisation }
  type: Types.POST
}

export type PostOrganisationsSuccessAction = {
  type: Types.POST_SUCCESS
}

export type PostOrganisationsFailAction = {
  payload: { errors: object }
  type: Types.POST_FAIL
}

export type GetOrganisationsAction = {
  payload: TokenPayload
  type: Types.GET
}

export type GetOrganisationsSuccessAction = {
  payload: Organisation
  type: Types.GET_SUCCESS
}

export type GetOrganisationsFailAction = {
  payload: { errors: object }
  type: Types.GET_FAIL
}

export type GetOrganisationNameAction = {
  payload: TokenPayload
  type: Types.GET_NAME
}

export function postOrganisations(data: Organisation): PostOrganisationsAction {
  return {
    payload: { data },
    type: Types.POST
  }
}

export function postOrganisationsSuccess(): PostOrganisationsSuccessAction {
  return {
    type: Types.POST_SUCCESS
  }
}

export function postOrganisationsFail(
  errors: object
): PostOrganisationsFailAction {
  return {
    payload: { errors },
    type: Types.POST_FAIL
  }
}

export function getOrganisations(
  payload: TokenPayload
): GetOrganisationsAction {
  return {
    payload,
    type: Types.GET
  }
}

export function getOrganisationsSuccess(
  payload: Organisation
): GetOrganisationsSuccessAction {
  return {
    payload,
    type: Types.GET_SUCCESS
  }
}

export function getOrganisationsFail(
  errors: object
): GetOrganisationsFailAction {
  return {
    payload: { errors },
    type: Types.GET_FAIL
  }
}

export function getOrganisationName(
  payload: TokenPayload
): GetOrganisationNameAction {
  return {
    payload,
    type: Types.GET_NAME
  }
}
