import { Box, Divider } from "@material-ui/core"
import React, { Fragment } from "react"

import { ITopBarRoute_new } from "app/context/NavigationContext"

import Translation from "components/Translation"

import { SidebarLink } from "./style"

type Props = {
  to: string
  label: string
  routes: ITopBarRoute_new[]
  dividerLeft: boolean
}

const NavigationItem = ({ to, label, routes, dividerLeft }: Props) => {
  let navigationDepth = 0
  navigationDepth = navigationDepth + 1

  const hasSubNavigation = routes && routes.length > 0
  const url = hasSubNavigation ? routes[0].to : to

  return (
    <Fragment key={url}>
      {dividerLeft && (
        <Box marginX="1rem" paddingY="1.5rem">
          <Divider orientation="vertical" />
        </Box>
      )}
      <SidebarLink to={url || ""} exact={false}>
        <Translation label={label} />
      </SidebarLink>
    </Fragment>
  )
}

export default NavigationItem
