import styled from "@emotion/styled"
import { Grid } from "@material-ui/core"
import { viorasTheme } from "style/theme"

export const LayoutWrapper = styled(Grid)`
  min-height: 100vh;
`

LayoutWrapper.defaultProps = {
  container: true
}

export const LayoutItem = styled(Grid)`
  flex-direction: column;
  border-left: 1px solid ${viorasTheme.colors.grey[80]};
  border-right: 1px solid ${viorasTheme.colors.grey[80]};
  margin-left: -1px;
  &:last-child {
    border-right: none;
  }
`

LayoutItem.defaultProps = {
  container: true,
  item: true
}
