import { useSelector } from "react-redux"
import * as surveyTypesSelector from "app/redux/surveyTypes/surveyTypes.selectors"
import { useIntl } from "react-intl"
import { LanguageEnum } from "app/models/invite"

export const useSurveyTypes = () => {
  const surveyTypes = useSelector(surveyTypesSelector.surveyTypes)

  return {
    surveyTypes
  }
}

export const useSurveyType = (id: string) => {
  const surveyTypes = useSelector(surveyTypesSelector.surveyTypes)
  const surveyType = surveyTypes.find(surveyType => surveyType.id === id)
  return surveyType
}

export const useSurveyTypeTranslation = (id: string) => {
  const surveyType = useSurveyType(id)
  const { locale, formatMessage } = useIntl()
  const check_name = surveyType?.check_name_i18n[locale as LanguageEnum]
  const name = surveyType?.name_i18n[locale as LanguageEnum]

  return (label: string) => {
    return formatMessage({ id: label }, { check_name, name })
  }
}
