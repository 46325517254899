const funToWorkWithTheme = {
  typography: {
    fontFamily: "verdana",
  },
  colors: {
    primary: "#3d3c3d",
    primaryLight: "#b8b7b8",
    primaryExtraLight: "#ebeaeb",
    primarySuperLight: "#f7f7f7",
    primaryDark: "#2e2e2e",
    secondary: "#f7ad38",
    secondaryDark: "#d6880d",
    secondaryLight: "#fef6e9",
    accent1: "#3b82e0",
    accent2: "#33A18D",
    accent3: "#7db2ff",
    accent4: "#3c82df",
    white: "#fff",
    black: "#000",
    error: "#e13434",
    success: "#54981e",
    lightBrown: "#cec7be",
  },
}

export default funToWorkWithTheme
