import { Survey, SURVEY_TYPE } from "app/models/survey"
import { Action, Types } from "./survey.actions"

export type SurveyState = {
  errors?: {}
  loading: boolean
  dispatched: boolean
  data: Survey[]
  showDeleteConfirmation: string
  surveyType: SURVEY_TYPE | undefined
}

const initialState: SurveyState = {
  data: [],
  dispatched: false,
  loading: true,
  showDeleteConfirmation: "",
  surveyType: undefined
}

export default (state = initialState, action: Action): SurveyState => {
  switch (action.type) {
    case Types.GET_ALL:
      return {
        ...state,
        loading: true,
        data: []
      }

    case Types.GET_ALL_SUCCESS:
      return {
        ...state,
        data: action.payload,
        loading: false
      }

    case Types.GET_ALL_FAIL:
    case Types.POST_FAIL:
      return {
        ...state,
        errors: action.payload.toJSON(),
        loading: false
      }

    case Types.DELETE:
      return {
        ...state,
        loading: true
      }

    case Types.DELETE_SUCCESS:
      return {
        ...state,
        loading: false,
        showDeleteConfirmation: ""
      }

    case Types.DELETE_FAIL:
      return {
        ...state,
        loading: false
      }

    case Types.TOGGLE_DELETE_CONFIRMATION: {
      const modalId = action.payload
      const newId = modalId === state.showDeleteConfirmation ? "" : modalId

      return {
        ...state,
        showDeleteConfirmation: newId
      }
    }

    case Types.SET_SURVEY_TYPE: {
      return {
        ...state,
        surveyType: action.payload
      }
    }
    default:
      return state
  }
}
