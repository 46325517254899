import React from "react"
import { SvgIconProps, SvgIcon } from "@material-ui/core"

function Close(props: SvgIconProps): JSX.Element {
  return (
    <SvgIcon viewBox="0 0 16 16" {...props}>
      <path
        xmlns="http://www.w3.org/2000/svg"
        d="M13.5893 0.838379L7.93196 6.49438L2.27596 0.838379L0.390625 2.72371L6.04662 8.37971L0.390625 14.0357L2.27596 15.921L7.93196 10.265L13.5893 15.921L15.4746 14.0357L9.81862 8.37971L15.4746 2.72371L13.5893 0.838379Z"
        fill="#3D3C3D"
      />
    </SvgIcon>
  )
}

export default Close
