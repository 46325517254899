import { Action, Types } from "./me.actions"
import { Me } from "app/models/user"
import { Child } from "app/models/groups"

export type MeState = {
  dispatched: boolean
  errors: object
  loading: boolean
  me?: Me
  groups?: Child[]
}

const initialState: MeState = {
  dispatched: false,
  errors: {},
  loading: false,
  me: undefined
}

export default (state = initialState, action: Action): MeState => {
  switch (action.type) {
    case Types.GET:
    case Types.PATCH:
    case Types.PATCH_TERMS_OF_USE:
      return {
        ...state,
        dispatched: false,
        loading: true
      }

    case Types.GET_SUCCESS:
      return {
        ...state,
        dispatched: true,
        loading: false,
        me: action.payload
      }

    case Types.GET_FAIL:
    case Types.PATCH_FAIL:
      return {
        ...state,
        dispatched: true,
        errors: action.payload.errors,
        loading: false
      }

    case Types.PATCH_SUCCESS:
    case Types.PATCH_TERMS_OF_USE_SUCCESS:
      return {
        ...state,
        dispatched: true,
        loading: false,
        me: {
          ...state.me!,
          ...action.payload
        }
      }

    case Types.GET_GROUPS:
      return {
        ...state,
        loading: true
      }

    case Types.GET_GROUPS_SUCCESS:
      return {
        ...state,
        groups: action.payload,
        loading: false
      }

    default:
      return state
  }
}
