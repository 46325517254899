import { FormControl, InputBase, OutlinedInputProps } from "@material-ui/core"
import FormLabel from "components/FormLabel"
import React, { memo } from "react"
import { TextFieldWrapper } from "./style"

interface Props extends Omit<OutlinedInputProps, "error"> {
  error?: string
  label?: string
  name: string
  type?: string
  tooltip?: string
  tooltipValues?: object
  fullWidth?: boolean
  disabled?: boolean
}

const TextField = ({
  label,
  name,
  error,
  tooltip,
  tooltipValues,
  type,
  className,
  disabled,
  fullWidth = true,
  ...rest
}: Props) => {
  return (
    <TextFieldWrapper>
      {label && (
        <FormLabel
          tooltipValues={tooltipValues}
          label={label}
          error={error}
          name={name}
          tooltip={tooltip}
        />
      )}
      <FormControl fullWidth={fullWidth}>
        <InputBase
          disabled={disabled}
          error={Boolean(error)}
          id={name as string}
          type={type}
          name={name}
          {...rest}
        />
      </FormControl>
    </TextFieldWrapper>
  )
}

export default memo(TextField)
