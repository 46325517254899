import { push } from "connected-react-router"
import { SagaIterator } from "redux-saga"
import { call, put, takeLatest } from "redux-saga/effects"

import * as api from "app/api/users"
import { ErrorCode } from "app/models/errorCode"
import { PartyType } from "app/models/parties"

import { getGroups } from "../groups/groups.actions"
import * as meActions from "../me/me.actions"
import { showMessage } from "../message/message.actions"
import { getPeople } from "../people/people.actions"
import { getTeams } from "../teams/teams.actions"
import * as actions from "./partyRelations.actions"

export function* getPartyRelationsFlow({
  payload,
}: actions.GetPartyRelationsAction): SagaIterator {
  try {
    const response = yield call(api.getPartyRelations, payload)
    yield put(actions.getPartyRelationsSuccess(response.data))
  } catch (e) {
    yield put(actions.getPartyRelationsFail(e))
  }
}

export function* deletePartyRelationFlow({
  payload,
}: actions.DeletePartyRelationAction): SagaIterator {
  try {
    const response = yield call(api.deletePartyRelation, payload)
    yield put(actions.deletePartyRelationSuccess(response.data))
    yield put(showMessage(`groups.delete.${payload.party_type}.success`))

    if (payload.parent_id) {
      const { parent_id, q, sort, organisation_id, teamId, token } = payload
      yield put(getGroups({ id: parent_id, q: q, sort: sort }))
      //getTeams is for the new teamsoverview, getGroups should be deleted when we swap on production
      yield put(getTeams({ group_id: parent_id }))
      //getPeople is for the new peopleoverview, getGroups should be deleted when we swap on production
      yield put(
        getPeople({
          organisation_id: organisation_id!,
          team_id: teamId,
          token: token,
        }),
      )
      // getMyGroups is for the new navigation menu
      yield put(meActions.getMyGroups({ sort: ["name=asc"], token }))
    }

    const userIsDeletingItself =
      payload.party_type === PartyType.PERSON &&
      payload.currentUserPartyId === payload.child_id

    if (userIsDeletingItself) {
      yield put(push("/"))
      window.location.reload()
    }
  } catch (e) {
    if (e.response.data.errorCode === ErrorCode.LAST_GROUP_ADMIN) {
      yield put(showMessage("user.error.last_admin"))
    }
    yield put(actions.deletePartyRelationFail(e))
  }
}

export default function* partyRelationSaga(): SagaIterator {
  yield takeLatest(actions.Types.GET, getPartyRelationsFlow)
  yield takeLatest(actions.Types.DELETE, deletePartyRelationFlow)
}
