import State from "../state"
import { User } from "app/models/user"
import { Child } from "app/models/groups"
import { LanguageEnum } from "app/models/invite"

export const loading = (state: State): boolean => state.me.loading
export const me = (state: State): User | undefined => state.me.me
export const myGroups = (state: State): Child[] | undefined => state.me.groups
export const errors = (state: State): object => state.me.errors
export const dispatched = (state: State): boolean | undefined =>
  state.me.dispatched
export const getPreferredLanguage = (state: State): LanguageEnum | undefined =>
  state.me.me?.pref_language as LanguageEnum
export const getPermissions = (state: State): string[] | undefined =>
  state.me.me?.permissions
