import { ENV_FEATURE_FLAG } from "enums"
import React, { ComponentType } from "react"
import { Redirect, RouteProps } from "react-router-dom"

import GroupsBreadcrumb from "app/components/GroupsBreadcrumb"
import AppLayout from "app/components/Layout/App/AppLayout"
import SurveyLayout from "app/components/Layout/Survey"
import { FeaturesEnum } from "app/models/organisation"
import { PermissionEnum } from "app/models/permission"

import applyFeature from "utils/applyFeature"

import { ROUTE_PATHS } from "./config"

const Logout = React.lazy(() => import("../components/Logout"))
const SignUp = React.lazy(() => import("../components/SignUp"))
const Verify = React.lazy(() => import("../components/Verify"))
const TermsOfUse = React.lazy(() => import("../components/TermsOfUse"))
const Success = React.lazy(() => import("../components/DailyCheckSuccess"))

const DailyCheckDashboard = React.lazy(() =>
  import("../components/DailyCheckDashboard"),
)
const TeamDailyCheckDashboard = React.lazy(() =>
  import("../components/DailyCheckDashboard/TeamDailyCheckDashboard"),
)
const DailyCheckDetail = React.lazy(() =>
  import("../components/DailyCheckDetail"),
)
const DailyCheckOverview = React.lazy(() =>
  import("../components/DailyCheckOverview"),
)
const SurveyJs = React.lazy(() => import("poc/routes/SurveyJs"))

const GroupOverview = React.lazy(() => import("../components/GroupsOverview"))
const MyGroups = React.lazy(() => import("../components/MyGroups"))
const GroupsHeader = React.lazy(() => import("../components/GroupsHeader"))
const GroupSettings = React.lazy(() => import("../components/GroupSettings"))

const FunctionOverview = React.lazy(() =>
  import("../components/FunctionOverview"),
)

const NuanceSettings = React.lazy(() => import("../components/NuanceSettings"))
const RealityCheckSettings = React.lazy(() =>
  import("../components/RealityCheckSettings"),
)

const LeadershipOverview = React.lazy(() =>
  import("../components/LeadershipOverview"),
)

const ClimateOverview = React.lazy(() =>
  import("../components/ClimateOverview"),
)

const SurveyOverview = React.lazy(() => import("../components/SurveyOverview"))
const TeamOverview = React.lazy(() => import("../components/TeamOverview"))

const PeopleOverview = React.lazy(() => import("../components/PeopleOverview"))
const TeamMemberOverview = React.lazy(() =>
  import("../components/TeamMemberOverview"),
)

const SurveyPage = React.lazy(() => import("../components/Surveys/SurveyPage"))
export interface IRoute extends RouteProps {
  authorized?: boolean
  breadcrumb?: React.ReactNode | string
  component?: React.LazyExoticComponent<(props: any) => JSX.Element>
  link?: boolean
  withSidebar?: boolean
  hasAccess?: boolean
  routes?: IRoute[]
  features?: FeaturesEnum[]
  permissions?: PermissionEnum[]
  key?: string
  pathKey?: string
}

export interface AdminRoutes {
  layout: ComponentType
  routes: IRoute[]
}

export interface Routes {
  layout: ComponentType
  routes: IRoute[]
}

export type IRouteComponent = Omit<IRoute, "authorized" | "component">

const isNewNavigation = applyFeature(ENV_FEATURE_FLAG.IS_NEW_NAVIGATION)

// TODO: make sure new navigation routes use the correct ROUTE_PATHS
// so that useSurveys survey_type will still work.
const oldAdminRoutes: AdminRoutes = {
  layout: AppLayout,
  routes: [
    {
      // Sub routes here are needed to make /account/* breadcrumbs work.
      // Preferably sub routes are not used to reduce complexity.
      component: GroupsHeader,
      path: ["/account/groups", "/account/my-groups"],
      permissions: [PermissionEnum.PARTY_RELATION_METADATA_UPDATE],
      routes: [
        {
          breadcrumb: GroupsBreadcrumb,
          component: GroupOverview,
          exact: true,
          link: false,
          path: "/account/groups/:id?",
          permissions: [PermissionEnum.ORGANIZATION_ADMIN],
        },
        {
          breadcrumb: GroupsBreadcrumb,
          component: MyGroups,
          exact: true,
          link: false,
          path: "/account/my-groups/:id?",
        },
      ],
    },
    {
      component: FunctionOverview,
      features: [FeaturesEnum.FEATURE_CUSTOM_FUNCTIONS],
      path: "/account/settings/functions",
      permissions: [PermissionEnum.FUNCTION_UPDATE],
    },
    {
      component: DailyCheckDashboard,
      exact: true,
      features: [FeaturesEnum.FEATURE_DAILY_CHECK_DASHBOARD],
      path: ROUTE_PATHS.REALITY_CHECK_DASHBOARD,
      permissions: [PermissionEnum.DAILY_CHECK_TREE_READ],
    },
    {
      component: NuanceSettings,
      features: [FeaturesEnum.FEATURE_DAILY_CHECK_EXTRA_NUANCES],
      path: `${ROUTE_PATHS.REALITY_CHECK_OVERVIEW}/settings/nuance/:id?`,
      permissions: [PermissionEnum.NUANCE_UPDATE],
      exact: true,
    },
    {
      component: GroupSettings,
      path: `${ROUTE_PATHS.REALITY_CHECK_OVERVIEW}/settings/preferences/:id?`,
      permissions: [PermissionEnum.PARTY_SETTINGS_UPDATE],
      exact: true,
    },
    {
      component: LeadershipOverview,
      path: ROUTE_PATHS.LEADERSHIP_ADMIN,
      features: [FeaturesEnum.FEATURE_SURVEY_LEADERSHIP_DASHBOARD],
      permissions: [PermissionEnum.SURVEY_READ],
    },
    {
      component: ClimateOverview,
      path: ROUTE_PATHS.CLIMATE_ADMIN,
      features: [FeaturesEnum.FEATURE_SURVEY_CLIMATE_DASHBOARD],
      permissions: [PermissionEnum.SURVEY_READ],
    },
  ],
}

const newAdminRoutes: AdminRoutes = {
  layout: AppLayout,
  routes: [
    {
      component: DailyCheckDashboard,
      exact: true,
      path: ROUTE_PATHS.REALITY_CHECK_DASHBOARD,
    },
    {
      component: TeamDailyCheckDashboard,
      exact: true,
      path: ROUTE_PATHS.TEAM_REALITY_CHECK_DASHBOARD,
      permissions: [PermissionEnum.GROUP_READ],
    },
    {
      component: RealityCheckSettings,
      exact: true,
      path: ROUTE_PATHS.TEAM_REALITY_CHECK_SETTINGS,
      permissions: [PermissionEnum.DAILY_CHECK_TREE_READ],
    },
    {
      component: SurveyPage,
      exact: true,
      path: ROUTE_PATHS.TEAM_SURVEYS,
      permissions: [PermissionEnum.DAILY_CHECK_TREE_READ],
    },
    {
      component: TeamMemberOverview,
      exact: true,
      link: false,
      path: ROUTE_PATHS.TEAM_MEMBER_DASHBOARD,
      permissions: [PermissionEnum.PEOPLE_READ],
    },
  ],
}

export const adminRoutes = isNewNavigation ? newAdminRoutes : oldAdminRoutes

export const oldAppRoutes: Routes = {
  layout: AppLayout,
  routes: [
    {
      exact: true,
      path: "/",
      render: () => <Redirect to={ROUTE_PATHS.REALITY_CHECK_OVERVIEW} />,
    },
    {
      exact: true,
      path: `${ROUTE_PATHS.REALITY_CHECK_OVERVIEW}/settings`,
      render: () => (
        <Redirect
          to={`${ROUTE_PATHS.REALITY_CHECK_OVERVIEW}/settings/nuance`}
        />
      ),
    },
    {
      authorized: false,
      component: Logout,
      exact: true,
      path: "/logout",
    },
    {
      component: Success,
      exact: true,
      path: "/success",
      withSidebar: false,
    },
  ],
}

export const newAppRoutes: Routes = {
  layout: AppLayout,
  routes: [
    {
      exact: true,
      path: "/",
      render: () => (
        <Redirect to={`${ROUTE_PATHS.REALITY_CHECK_OVERVIEW}/dashboard`} />
      ),
    },
    {
      component: TeamOverview,
      exact: true,
      link: false,
      path: `${ROUTE_PATHS.ORGANISATION_TEAMS}/:id?`,
      permissions: [PermissionEnum.ORGANIZATION_ADMIN],
    },
    {
      component: PeopleOverview,
      exact: true,
      link: false,
      path: `${ROUTE_PATHS.ORGANISATION_PEOPLE}/:id?`,
      permissions: [PermissionEnum.PEOPLE_READ],
    },
    {
      component: FunctionOverview,
      features: [FeaturesEnum.FEATURE_CUSTOM_FUNCTIONS],
      path: "/organisation/functions",
      permissions: [PermissionEnum.FUNCTION_UPDATE],
    },
  ],
}

export const appRoutes = isNewNavigation ? newAppRoutes : oldAppRoutes

export const authRoutes: Routes = {
  layout: SurveyLayout,
  routes: [
    {
      component: SignUp,
      exact: true,
      path: "/signup",
    },
    {
      component: Verify,
      exact: true,
      path: "/verify",
    },
    {
      component: TermsOfUse,
      exact: true,
      path: "/terms-of-use",
      withSidebar: false,
    },
  ],
}

export const surveyRoutes: Routes = {
  layout: SurveyLayout,
  routes: [
    {
      exact: true,
      render: () => <Redirect to={ROUTE_PATHS.REALITY_CHECK_OVERVIEW} />,
      path: ROUTE_PATHS.DAILY_CHECK_OVERVIEW,
    },
    {
      component: DailyCheckOverview,
      exact: true,
      path: ROUTE_PATHS.REALITY_CHECK_OVERVIEW,
    },
    {
      component: DailyCheckDetail,
      exact: true,
      path: ROUTE_PATHS.DAILY_CHECK,
    },

    {
      component: DailyCheckDetail,
      exact: true,
      path: `${ROUTE_PATHS.REALITY_CHECK}`,
    },
    {
      component: SurveyOverview,
      authorized: false,
      exact: true,
      path: ROUTE_PATHS.LEADERSHIP_OVERVIEW,
    },
    {
      component: SurveyOverview,
      authorized: false,
      exact: true,
      path: ROUTE_PATHS.CLIMATE_OVERVIEW,
    },
    {
      component: SurveyOverview,
      authorized: false,
      exact: true,
      path: ROUTE_PATHS.CUSTOM_OVERVIEW,
    },
    {
      component: SurveyJs,
      exact: true,
      path: "/survey/:parent_id",
    },
  ],
}
