import { ISideBarNavigation } from "app/context/NavigationContext"
import React from "react"
import { useLocation } from "react-router"
import { SidebarSubTitle } from "./style"
import LoadingIndicator from "components/LoadingIndicator"
import { Box } from "@material-ui/core"
import Group from "app/models/groups"
import LinkContent from "./LinkContent"
import NavigationItemLink from "./NavigationItemLink"

const TeamsSideNavigation = ({
  nav,
  groups,
  isLoading
}: {
  nav: ISideBarNavigation
  groups: Group[]
  isLoading: boolean
}) => {
  const location = useLocation()

  const teamNavgiatonItems = groups?.map((el) => {
    const { name, party_id } = el
    const { to, isActive } = nav

    const updatedToPath = to?.replace(":id", party_id)
    const isGroupActiveInNavigation =
      isActive && location.pathname?.includes(party_id)

    const item = {
      ...el,
      label: name,
      to: updatedToPath,
      isActive: isGroupActiveInNavigation
    }

    return <NavigationItemLink item={item as ISideBarNavigation} />
  })

  if (!groups && isLoading) {
    return (
      <Box my={8}>
        <LoadingIndicator color="secondary" />
      </Box>
    )
  }

  return (
    <SidebarSubTitle colorName="primary" variant="caption">
      <LinkContent label={nav.label} />
      {teamNavgiatonItems}
    </SidebarSubTitle>
  )
}

export default TeamsSideNavigation
