import { Link as MuiLink, TypographyProps } from "@material-ui/core"
import React from "react"
import { NavLink as RouterLink } from "react-router-dom"

export const Link = <C extends React.ElementType>({
  children,
  isActive: _,
  ...props
}: TypographyProps<C, { component?: C }>) => {
  const { onClick } = props
  const exact = onClick ? undefined : true
  return (
    <MuiLink component={onClick ? "a" : RouterLink} exact={exact} {...props}>
      {children}
    </MuiLink>
  )
}
