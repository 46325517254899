import { SvgIcon, SvgIconProps } from "@material-ui/core"
import React from "react"

function Arrow(props: SvgIconProps): JSX.Element {
  return (
    <SvgIcon viewBox="0 0 45 25" {...props}>
      <path d="M22.5,25a2.49,2.49,0,0,1-1.77-.73l-20-20A2.5,2.5,0,0,1,4.27.73L22.5,19,40.73.73a2.5,2.5,0,0,1,3.54,3.54l-20,20A2.49,2.49,0,0,1,22.5,25Z" />{" "}
    </SvgIcon>
  )
}

export default Arrow
