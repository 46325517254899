import * as api from "app/api/me"
import * as usersApi from "app/api/users"
import { SagaIterator } from "redux-saga"
import { call, put, takeLatest, select } from "redux-saga/effects"
import * as actions from "./me.actions"
import { push } from "connected-react-router"
import { router as routerSelector } from "app/redux/router/router.selectors"

export function* getMeFlow({ payload }: actions.GetMeAction): SagaIterator {
  try {
    const response = yield call(api.getMe, payload)
    yield put(actions.getMeSuccess(response.data))
  } catch (e) {
    const { location } = yield select(routerSelector)
    if (e.response.status === 404) {
      if (location.pathname !== "/signup") {
        yield put(push("/signup"))
      }
    }
    yield put(actions.getMeFail(e))
  }
}

export function* patchMeFlow({ payload }: actions.PatchMeAction): SagaIterator {
  try {
    const response = yield call(usersApi.patchUser, payload)
    yield put(actions.patchMeSuccess(response.data))
  } catch (e) {
    yield put(actions.patchMeFail(e))
  }
}

export function* patchMeTermsOfUseFlow({ payload }: actions.PatchMeTermsOfUseAction): SagaIterator {
  try {
    const response = yield call(usersApi.patchUserTermsOfUseAccept, payload)
    yield put(actions.patchMeTermsOfUseSuccess(response.data))
  } catch (e) {
    yield put(actions.patchMeTermsOfUseFail(e))
  }
}

export function* getMyGroupsFlow(
  action: actions.GetMyGroupsAction
): SagaIterator {
  try {
    const response = yield call(api.getMyGroups, action.payload)
    yield put(actions.getMyGroupsSuccess(response.data))
  } catch (e) {
    yield put(actions.getMeFail(e))
  }
}

export default function* meSaga(): SagaIterator {
  yield takeLatest(actions.Types.GET, getMeFlow)
  yield takeLatest(actions.Types.PATCH, patchMeFlow)
  yield takeLatest(actions.Types.PATCH_TERMS_OF_USE, patchMeTermsOfUseFlow)
  yield takeLatest(actions.Types.GET_GROUPS, getMyGroupsFlow)
}
