import isPropValid from "@emotion/is-prop-valid"
import styled from "@emotion/styled"
import { BoxProps, Theme, Typography } from "@material-ui/core"
import { Icon } from "@material-ui/core/"
import { ISideBarNavigation } from "app/context/NavigationContext"
import Arrow from "assets/icons/Arrow"
import Button from "components/Button"
import DropDown from "components/Dropdown"
import { NavLink } from "react-router-dom"
import { funToWorkWithTheme, IStyled, viorasTheme } from "style/theme"
import { Link } from "../Link"

export const SidebarWrapper = styled.div<BoxProps>`
  padding: 4rem;
  flex: 1;
  display: flex;
  flex-direction: column;
  background: ${funToWorkWithTheme.colors.secondaryLight};
`

export const SidebarSection = styled.div`
  margin-bottom: 5.8rem;
`

export const SidebarFooter = styled.div`
  margin-top: auto;
`

export const SidebarNav = styled.nav`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
`

export const SidebarLink = styled(NavLink)`
  color: ${funToWorkWithTheme.colors.primary};
  font-size: 2rem;
  cursor: pointer;
  display: flex;
  text-decoration: none;
  font-weight: 400;

  &:hover,
  &:focus {
    color: #000;
  }
  &.active {
    font-weight: 700;
  }
  & ~ & {
    margin-top: 1.6rem;
  }
`

interface ISidebarArrow {
  isOpen?: boolean
}

export const SidebarArrow = styled(Arrow, {
  shouldForwardProp: (prop) => prop !== "isOpen"
}) <ISidebarArrow>`
  width: 0.9rem;
  height: 0.9rem;
  margin-top: 0.8rem;
  margin-right: 0.6rem;
  ${({ isOpen }) => !isOpen && "transform: rotate(-90deg);"}
`

export const SidebarBullet = styled.div`
  &:before {
    content: "\\2022";
    line-height: 1;
    margin-right: 0.6rem;
  }
`

export const SidebarSubNav = styled.div`
  margin-left: 1.5rem;
  margin-top: 1.4rem;
`

export const SidebarDropdownWrapper = styled(DropDown)`
  display: block;
  margin-bottom: 5.8rem;
`

export const SidebarDropdownContent = styled.div`
  padding: ${({ theme }: IStyled) => theme.spacing(3)};
  background: ${({ theme }: IStyled) => theme.palette.common.white};
  box-shadow: 0px 3px 10px rgba(0, 0, 0, 0.25);
  border: 1px solid ${viorasTheme.colors.grey[80]};
  border-radius: 4px;
`

interface IDropDownLink {
  theme: Theme
  isActive?: boolean
  colorName: ISideBarNavigation["color"]
}

export const SidebarDropdownLink = styled(Link, {
  shouldForwardProp: (prop) => isPropValid(prop) && prop !== "colorName"
}) <IDropDownLink>`
  --icon-color: ${funToWorkWithTheme.colors.primaryLight};
  display: flex;
  border: unset;
  align-items: center;
  color: ${({ theme }) => theme.palette.text.primary};
  font-size: 2rem;
  text-decoration: none;
  ${({ isActive, colorName }) =>
    isActive &&
    `
      --icon-color: ${colorName && funToWorkWithTheme.colors[colorName]};
      font-weight: 700;
    `}
  &:hover,
  &:focus {
    --icon-color: ${({ colorName }) =>
    colorName && funToWorkWithTheme.colors[colorName]};
    color: ${funToWorkWithTheme.colors.black};
    text-decoration: none;
  }
`

export const SidebarDropdownIcon = styled(Icon) <{
  theme: Theme
  component: any
}>`
  width: 3.6rem;
  height: 3.6rem;
  margin-right: ${({ theme }: IStyled) => theme.spacing(1)};
  color: var(--icon-color);
`

export const SidebarDropdownTrigger = styled(Typography, {
  shouldForwardProp: (prop) => prop !== "colorName"
}) <{ theme: Theme; colorName: ISideBarNavigation["color"] }>`
  --icon-color: ${({ colorName }) =>
    colorName && funToWorkWithTheme.colors[colorName]};
  color: ${funToWorkWithTheme.colors.primary};
  cursor: pointer;
  background: none;
  padding: 0;
  border: none;

  display: flex;
  align-items: center;
  text-align: left;

  &:focus {
    outline: none;
  }
  &:hover,
  &:focus {
    color: ${funToWorkWithTheme.colors.black};
  }
`

export const StyledButton = styled(Button)`
  margin-top: ${({ theme }: IStyled) => theme.spacing(5)};
  padding: 1.6rem 2.5rem;
`
