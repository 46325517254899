import State from "../state"
import Group from "app/models/groups"
import { PartyParents, PartyParent, PartyType } from "app/models/parties"

export const isLoading = (state: State): boolean => state.groups.loading
export const errors = (state: State): object => state.groups.errors
export const groups = (state: State): Group | undefined => state.groups.groups
export const parents = (state: State): PartyParents => state.groups.parents
export const parameters = (state: State) => state.groups.parameters
export const organisation = (state: State): PartyParent | undefined =>
  state.groups.parents.find(
    (parent: PartyParent) => parent.party_type === PartyType.ORGANIZATION
  )
export const parentsLoading = (state: State): boolean =>
  state.groups.parentsLoading
export const getEditedField = (state: State): string | undefined =>
  state.groups.editedField
export const getPermissions = (state: State): string[] | undefined =>
  state.groups.groups?.permissions?.roles
