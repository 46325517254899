import React from "react"
import { useSelector } from "react-redux"

import { PermissionEnum } from "app/models/permission"
import { getPermissions as getGroupPermissions } from "app/redux/groups/groups.selectors"
import * as selectors from "app/redux/me/me.selectors"

export const usePermission = () => {
  const groupPermissions = useSelector(getGroupPermissions)
  const userPermissions = useSelector(selectors.getPermissions)
  const isDispatched = useSelector(selectors.dispatched)

  const hasGroupPermission = React.useCallback(
    (permission: PermissionEnum) => {
      const foundPermission = groupPermissions?.find((p) => p === permission)
      return !!foundPermission
    },
    [groupPermissions],
  )

  const hasPermission = React.useCallback(
    (permission: PermissionEnum) => {
      if (!isDispatched || !userPermissions) return true

      const foundPermission = userPermissions?.find((p) => p === permission)
      return !!foundPermission
    },
    [userPermissions, isDispatched],
  )

  const hasPermissions = React.useCallback(
    (permissions: PermissionEnum[]) => {
      if (!isDispatched || !userPermissions) return true

      const hasAllPermissions = permissions.every((p) =>
        userPermissions?.includes(p),
      )

      return hasAllPermissions
    },
    [userPermissions, isDispatched],
  )

  return { hasGroupPermission, hasPermission, hasPermissions }
}
