import React, { memo } from "react"
import FormLabel from "components/FormLabel"
import { StyledSelect, SelectWrapper } from "./style"
import MenuItem from "@material-ui/core/MenuItem"
import TextField from "components/TextField"
import { SelectProps } from "@material-ui/core"

type Option = {
  label: string
  value: string
}

interface Props extends Omit<SelectProps, "error"> {
  error?: string
  label?: string
  name: string
  options: Option[]
  value?: string
}

const Select = ({
  error,
  label,
  name,
  options,
  value,
  onChange,
  ...rest
}: Props) => {
  return (
    <SelectWrapper>
      {label && <FormLabel label={label} name={name} error={error} />}
      <StyledSelect
        variant="filled"
        value={value}
        onChange={onChange}
        input={<TextField name={name} />}
        name={name}
        error={Boolean(error)}
        id={name}
        {...rest}
      >
        {options.map((o) => (
          <MenuItem key={o.value} value={o.value}>
            {o.label}
          </MenuItem>
        ))}
      </StyledSelect>
    </SelectWrapper>
  )
}

export default memo(Select)
