import funToWorkWithTheme from "style/funToWorkWithTheme"
import { ButtonGroupClassKey } from "@material-ui/core"
import { CreateCSSProperties, CSSProperties } from "@material-ui/core/styles/withStyles"
import { PropsFunc } from "../types"

type ButtonGroup = Partial<
  Record<
    ButtonGroupClassKey,
    | CSSProperties
    | CreateCSSProperties<{}>
    | PropsFunc<{}, CreateCSSProperties<{}>>
  >
>
export const buttonGroup: ButtonGroup = {
  groupedOutlinedPrimary: {
    border: `2px solid ${funToWorkWithTheme.colors.secondary}`,
    "&:hover": {
      border: "2px solid",
      borderColor: funToWorkWithTheme.colors.secondary,
      backgroundColor: funToWorkWithTheme.colors.secondaryLight
    }
  }
}
