import { AxiosError } from "axios"

import { Nodes } from "app/models/nodes"
import { TokenPayload } from "app/models/token"

export enum Types {
  GET_MY_NODES = "GET_MY_NODES",
  GET_MY_NODES_SUCCESS = "GET_MY_NODES_SUCCESS",
  GET_MY_NODES_FAIL = "GET_MY_NODES_FAIL",
  GET_TEAM_NODES = "GET_TEAM_NODES",
  GET_TEAM_NODES_SUCCESS = "GET_TEAM_NODES_SUCCESS",
  GET_TEAM_NODES_FAIL = "GET_TEAM_NODES_FAIL",
  SET_ACTIVE_ID = "SET_ACTIVE_ID",
  SET_ACTIVE_ID_SUCCESS = "SET_ACTIVE_ID_SUCCESS",
}

export type Action =
  | GetMyNodesAction
  | GetMyNodesSuccessAction
  | GetMyNodesFailAction
  | GetTeamNodesAction
  | GetTeamNodesSuccessAction
  | GetTeamNodesFailAction
  | SetActiveNodesIdAction
  | SetActiveNodesIdSuccessAction

export type GetMyNodesAction = {
  payload: TokenPayload
  type: Types.GET_MY_NODES
}

export type GetMyNodesSuccessAction = {
  payload: Nodes
  type: Types.GET_MY_NODES_SUCCESS
}

export type GetMyNodesFailAction = {
  payload: AxiosError
  type: Types.GET_MY_NODES_FAIL
}

export type GetTeamNodesAction = {
  payload: { id: string } & TokenPayload
  type: Types.GET_TEAM_NODES
}

export type GetTeamNodesSuccessAction = {
  payload: Nodes
  type: Types.GET_TEAM_NODES_SUCCESS
}

export type GetTeamNodesFailAction = {
  payload: AxiosError
  type: Types.GET_TEAM_NODES_FAIL
}

export type SetActiveNodesIdAction = {
  payload: { id: string }
  type: Types.SET_ACTIVE_ID
}

export type SetActiveNodesIdSuccessAction = {
  payload: { id: string }
  type: Types.SET_ACTIVE_ID_SUCCESS
}

export function getMyNodes(payload: TokenPayload): GetMyNodesAction {
  return {
    payload,
    type: Types.GET_MY_NODES,
  }
}

export function getMyNodesSuccess(payload: Nodes): GetMyNodesSuccessAction {
  return {
    payload,
    type: Types.GET_MY_NODES_SUCCESS,
  }
}

export function getMyNodesFail(payload: AxiosError): GetMyNodesFailAction {
  return {
    payload,
    type: Types.GET_MY_NODES_FAIL,
  }
}

export function getTeamNodes(
  payload: { id: string } & TokenPayload,
): GetTeamNodesAction {
  return {
    payload,
    type: Types.GET_TEAM_NODES,
  }
}

export function getTeamNodesSuccess(payload: Nodes): GetTeamNodesSuccessAction {
  return {
    payload,
    type: Types.GET_TEAM_NODES_SUCCESS,
  }
}

export function getTeamNodesFail(payload: AxiosError): GetTeamNodesFailAction {
  return {
    payload,
    type: Types.GET_TEAM_NODES_FAIL,
  }
}

export function setActiveNodesId(payload: {
  id: string
}): SetActiveNodesIdAction {
  return {
    payload,
    type: Types.SET_ACTIVE_ID,
  }
}

export function setActiveNodesIdSuccess(payload: {
  id: string
}): SetActiveNodesIdSuccessAction {
  return {
    payload,
    type: Types.SET_ACTIVE_ID_SUCCESS,
  }
}
