import React, { FC, useEffect, useState } from "react"
import { useSelector } from "react-redux"
import { matchPath, useLocation } from "react-router"

import { findTo, useNavigation } from "app/context/NavigationContext"
import { useApi } from "app/hooks/api"
import Group from "app/models/groups"
import { getMyGroups } from "app/redux/me/me.actions"
import * as selectors from "app/redux/me/me.selectors"

import Divider from "components/Divider"

import NavigationItemLink from "./NavigationItemLink"
import TeamsSideNavigation from "./TeamsSideNavigation"

export const SideNavigation: FC = () => {
  const location = useLocation()
  const { navigation = [] } = useNavigation()
  const { callApi } = useApi()
  const groups = useSelector(selectors.myGroups)

  const [isLoading, setIsLoading] = useState<boolean>(false)
  const activeNavigation = navigation.map((n) => {
    const { routes = [] } = n
    const isActive = routes.some((r) => {
      const to = r.to || findTo(r.routes || [])
      if (!to) return false

      const match = matchPath(to, {
        exact: false,
        path: location.pathname.split("/", 2).join("/"),
      })

      return !!match
    })

    return {
      ...n,
      ...(isActive ? { isActive } : {}),
    }
  })

  useEffect(() => {
    if (!groups) {
      setIsLoading(true)
      callApi(getMyGroups, { sort: ["name=asc"] })
        .then(() => setIsLoading(false))
        .catch(() => setIsLoading(false))
    }
  }, [callApi, groups])

  const sideNavigationItems = activeNavigation?.map((nav, index) => {
    const isTeamNavigation =
      !nav?.isLink && nav?.isSubTitle && nav?.id === "teams"

    const navigationItemLinks = isTeamNavigation ? (
      <TeamsSideNavigation
        nav={nav}
        groups={groups as Group[]}
        isLoading={isLoading}
      />
    ) : (
      <NavigationItemLink item={nav} />
    )
    return (
      <>
        {navigationItemLinks}
        {index + 1 !== activeNavigation.length ? (
          <Divider backgroundColor="#6D6A67" />
        ) : null}
      </>
    )
  })

  return <>{sideNavigationItems}</>
}
