import { TokenPayload } from "./token"
import { PermissionEnum } from "./permission"

export type GetRolesPayload = TokenPayload

export enum RoleNames {
  ORGANIZATION_OWNER = "organization_owner",
  ORGANIZATION_ADMIN = "organization_admin",
  GROUP_ADMIN = "group_admin",
  TEAM_LEAD = "team_lead",
  USER = "user"
}

export default interface Role {
  id: string
  name: RoleNames
  permissions: {
    roles: PermissionEnum[]
    daily_check: string[]
  }
  is_deleted?: boolean
  deleted_on?: string | null
}
