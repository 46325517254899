import { Theme } from "@material-ui/core/styles"
import viorasTheme from "./viorasTheme"
import funToWorkWithTheme from "./funToWorkWithTheme"
import muiTheme from "./muiTheme"

export { viorasTheme, funToWorkWithTheme }

export interface IStyled {
  theme: Theme
}

export const styles = () => ({
  "@global": {
    html: {
      fontSize: viorasTheme.typography.fontSizeHTML
    }
  }
})

export default muiTheme
