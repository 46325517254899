import {
  CSSProperties,
  CreateCSSProperties
} from "@material-ui/core/styles/withStyles"
import { PropsFunc } from "../types"
import { TableClassKey } from "@material-ui/core"

type Table = Partial<
  Record<
    TableClassKey,
    | CSSProperties
    | CreateCSSProperties<{}>
    | PropsFunc<{}, CreateCSSProperties<{}>>
  >
>
export const table: Table = {
  root: {
    borderCollapse: "separate"
  }
}
