import * as actions from "./surveyTypes.actions"
import * as api from "app/api/surveyTypes"
import { SagaIterator } from "redux-saga"
import { call, put, takeLatest } from "redux-saga/effects"

function* getSurveyTypesFlow(action: actions.GetSurveyTypes): SagaIterator {
  try {
    const response = yield call(api.getSurveyTypes, action.payload)
    yield put(actions.getSurveyTypesSuccess(response.data))
  } catch (errors) {
    yield put(actions.getSurveyTypesFail(errors))
  }
}

export default function* surveyTypesSaga(): SagaIterator {
  yield takeLatest(actions.Type.GET_SURVEY_TYPES, getSurveyTypesFlow)
}
