import { AxiosPromise } from "axios"

import {
  DeleteFunctionPayload,
  GetFunctionsPayload,
  PatchFunctionPayload,
  PostFunctionPayload,
} from "app/models/functions"
import { Organisation } from "app/models/organisation"
import { PeoplePayload } from "app/models/people"
import { TokenPayload } from "app/models/token"

import { usersApi } from "./api"

export const postOrganisation = (data: Organisation): AxiosPromise =>
  usersApi("/me/organizations", { method: "POST", data })

export const getOrganisations = (data: TokenPayload): AxiosPromise =>
  usersApi("/me/organizations", { method: "GET", data })

export const getOrganisationName = (data: TokenPayload): AxiosPromise =>
  usersApi("/me/organizations/name", { method: "GET", data })

export const getFunctions = ({
  organization_id,
  sort,
  ...data
}: GetFunctionsPayload): AxiosPromise =>
  usersApi(`/organizations/${organization_id}/functions/`, {
    data,
    method: "GET",
    params: {
      sort,
    },
  })

export const patchFunctions = ({
  organization_id,
  id,
  ...data
}: PatchFunctionPayload) => {
  return usersApi(`/organizations/${organization_id}/functions/${id}`, {
    data,
    method: "PATCH",
  })
}

export const postFunction = ({
  organization_id,
  data,
}: PostFunctionPayload): AxiosPromise =>
  usersApi(`/organizations/${organization_id}/functions`, {
    data,
    method: "POST",
  })

export const deleteFunction = ({
  organization_id,
  id,
  ...data
}: DeleteFunctionPayload) => {
  return usersApi(`/organizations/${organization_id}/functions/${id}`, {
    data,
    method: "DELETE",
  })
}

export const getOrganisationPeople = (data: PeoplePayload): AxiosPromise => {
  const { organisation_id, team_id } = data
  return usersApi(`/organizations/${organisation_id}/people`, {
    data,
    method: "GET",
    params: { team_id },
  })
}
