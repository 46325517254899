import { User } from "app/models/user"
import { Action, Types } from "./users.actions"

export type UsersState = {
  data: User[]
  errors: object
  loading: boolean
  user: User | undefined
}

const initialState: UsersState = {
  data: [],
  errors: {},
  loading: false,
  user: undefined
}

export default (state = initialState, action: Action): UsersState => {
  switch (action.type) {
    case Types.GET:
      return {
        ...state,
        loading: true
      }

    case Types.GET_SUCCESS:
      return {
        ...state,
        data: action.payload.data || [],
        loading: false
      }

    case Types.GET_FAIL:
      return {
        ...state,
        errors: action.payload.errors,
        loading: false
      }

    case Types.DELETE:
      return {
        ...state,
        loading: true
      }

    case Types.DELETE_SUCCESS:
      return {
        ...state,
        loading: false
      }

    case Types.DELETE_FAIL:
      return {
        ...state,
        errors: action.payload.errors,
        loading: false
      }

    case Types.POST:
      return {
        ...state,
        loading: true
      }

    case Types.POST_SUCCESS:
      return {
        ...state,
        loading: false
      }

    case Types.POST_FAIL:
      return {
        ...state,
        errors: action.payload.errors,
        loading: false
      }

    case Types.GET_BY_ID:
      return {
        ...state,
        loading: true
      }

    case Types.GET_BY_ID_SUCCESS:
      return {
        ...state,
        loading: false,
        user: action.payload.data
      }

    case Types.GET_BY_ID_FAIL:
      return {
        ...state,
        errors: action.payload.errors,
        loading: false
      }

    case Types.PATCH_META:
      return {
        ...state,
        loading: true
      }

    case Types.PATCH_META_SUCCESS:
      return {
        ...state,
        loading: false
      }

    case Types.PATCH_META_FAIL:
      return {
        ...state,
        errors: action.payload.errors,
        loading: false
      }

    case Types.POST_META:
      return {
        ...state,
        loading: true
      }

    case Types.POST_META_SUCCESS:
      return {
        ...state,
        loading: false
      }

    case Types.POST_META_FAIL:
      return {
        ...state,
        errors: action.payload.errors,
        loading: false
      }

    case Types.DELETE_META:
      return {
        ...state,
        loading: true
      }

    case Types.DELETE_META_SUCCESS:
      return {
        ...state,
        loading: false
      }

    case Types.DELETE_META_FAIL:
      return {
        ...state,
        errors: action.payload.errors,
        loading: false
      }

    default:
      return state
  }
}
