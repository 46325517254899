import { Box } from "@material-ui/core"
import { ENV_FEATURE_FLAG } from "enums"
import React, { useEffect } from "react"
import { useSelector } from "react-redux"

import { SidebarNew } from "app/components/SidebarNew/SidebarNew"
import TopNavigation from "app/components/TopNavigation"
import { NavigationProvider } from "app/context/NavigationContext"
import { useApi } from "app/hooks/api"
import { getOrganisations } from "app/redux/organisation/organisation.actions"
import { getSurveyTypes } from "app/redux/surveyTypes/surveyTypes.actions"
import * as organisationSelectors from "app/redux/organisation/organisation.selectors"
import { getPartyRelations } from "app/redux/partyRelations/partyRelations.actions"
import * as partyRelationSelector from "app/redux/partyRelations/partyRelations.selectors"
import { IRouteComponent } from "app/routes"
import * as surveyTypesSelectors from "app/redux/surveyTypes/surveyTypes.selectors"

import applyFeature from "utils/applyFeature"

import { Sidebar } from "../../Sidebar"
import { LayoutWrapper } from "../style"
import AppNotifications from "./AppNotifications"
import { AppMain, AppSidebar, AppTopNavigation } from "./style"

export const AppLayout: React.FC<IRouteComponent> = ({
  children,
  withSidebar,
}) => {
  const isNewNavigation = applyFeature(ENV_FEATURE_FLAG.IS_NEW_NAVIGATION)

  const { callApi } = useApi()
  const organisation = useSelector(organisationSelectors.organisation)
  const isOrganisationLoading = useSelector(organisationSelectors.loading)
  const partyRelations = useSelector(partyRelationSelector.partyRelations)
  const isPartyRelationLoading = useSelector(partyRelationSelector.loading)
  const isSurveyTypesLoading = useSelector(surveyTypesSelectors.isLoading)
  const surveyTypes = useSelector(surveyTypesSelectors.surveyTypes)

  useEffect(() => {
    if (isOrganisationLoading || organisation) return
    callApi(getOrganisations)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    if (isPartyRelationLoading || partyRelations?.length) return
    callApi(getPartyRelations)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    if (isSurveyTypesLoading || surveyTypes?.length) return
    callApi(getSurveyTypes, { is_active: true })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <>
      {isNewNavigation ? (
        <>
          <AppNotifications />
          <Box display="flex" height="100%">
            <NavigationProvider>
              {withSidebar && <SidebarNew />}
              <AppMain>
                {withSidebar && (
                  <AppTopNavigation>
                    <TopNavigation />
                  </AppTopNavigation>
                )}
                {children}
              </AppMain>
            </NavigationProvider>
          </Box>
        </>
      ) : (
          <LayoutWrapper>
            <AppNotifications />
            <>
              {withSidebar && (
                <AppSidebar>
                  <Sidebar />
                </AppSidebar>
              )}
              <AppMain>{children}</AppMain>
            </>
          </LayoutWrapper>
        )}
    </>
  )
}

export default AppLayout
