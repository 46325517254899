let token = ""

export const setToken = (t: string) => {
  token = t
}

export const getToken = () => {
  return token
}

export const resetToken = () => {
  token = ""
}
