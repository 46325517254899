import * as api from "app/api/users"
import { AxiosError } from "axios"
import { SagaIterator } from "redux-saga"
import { call, put, takeLatest, select } from "redux-saga/effects"
import { showMessage } from "../message/message.actions"
import * as actions from "./survey.actions"
import * as meSelectors from "app/redux/me/me.selectors"
import * as surveyTypesSelectors from "app/redux/surveyTypes/surveyTypes.selectors"
import { Me } from "app/models/user"
import { LanguageEnum } from "app/models/invite"
import { SurveyType } from "app/models/surveyType"

export function* getSurveysFlow(
  action: actions.GetSurveysAction
): SagaIterator {
  const { payload } = action
  try {
    const { data } = yield call(api.getSurveys, payload)
    yield put(actions.getSurveysSuccess(data))
  } catch (e) {
    yield put(actions.getSurveysFail(e as AxiosError))
  }
}

export function* postSurveyFlow(
  action: actions.PostSurveyAction
): SagaIterator {
  const { payload } = action
  try {
    yield call(api.postSurvey, payload)

    yield put(actions.postSurveySuccess())
    yield put(
      actions.getSurveys({
        group_id: payload.group_id,
        token: payload.token,
        survey_type_id: payload.survey_type_id
      })
    )
  } catch (e) {
    yield put(actions.postSurveyFail(e as AxiosError))
  }
}

export function* deleteSurveyFlow({
  payload
}: actions.DeleteSurveyAction): SagaIterator {
  try {
    yield call(api.deleteSurvey, payload)
    yield put(actions.deleteSurveySuccess())
    const { pref_language: locale }: Me = yield select(meSelectors.me)
    const surveyType: SurveyType = yield select(surveyTypesSelectors.surveyType(payload.survey_type_id))
    yield put(showMessage("survey.delete.success", { check_name: surveyType.check_name_i18n[locale as LanguageEnum || "en"]! }))

    if (payload.group_id) {
      yield put(
        actions.getSurveys({
          group_id: payload.group_id,
          token: payload.token,
          survey_type_id: payload.survey_type_id
        })
      )
    }
  } catch (e) {
    yield put(actions.deleteSurveyFail(e))
  }
}

export function* patchSurveyFlow(
  action: actions.PatchSurveyAction
): SagaIterator {
  const { payload } = action
  try {
    const { data: updatedSurvey } = yield call(api.patchSurvey, payload)

    yield put(actions.patchSurveySuccess())
    yield put(
      actions.getSurveys({
        group_id: updatedSurvey.group_id,
        token: payload.token,
        survey_type_id: updatedSurvey.survey_type_id
      })
    )
  } catch (e) {
    yield put(actions.patchSurveyFail(e as AxiosError))
  }
}

export default function* surveySaga(): SagaIterator {
  yield takeLatest(actions.Types.GET_ALL, getSurveysFlow)
  yield takeLatest(actions.Types.POST, postSurveyFlow)
  yield takeLatest(actions.Types.DELETE, deleteSurveyFlow)
  yield takeLatest(actions.Types.PATCH, patchSurveyFlow)
}
