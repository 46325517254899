import dailyCheckSaga from "./redux/dailyCheck/dailyCheck.saga"
import eventsSaga from "./redux/events/events.saga"
import functionsSaga from "./redux/functions/functions.saga"
import groupsSaga from "./redux/groups/groups.saga"
import invitesSaga from "./redux/invites/invites.saga"
import meSaga from "./redux/me/me.saga"
import nodesSaga from "./redux/nodes/nodes.saga"
import nuancesSaga from "./redux/nuances/nuances.saga"
import organisationsSaga from "./redux/organisation/organisation.saga"
import partyRelationNuancesSaga from "./redux/partyRelationNuances/partyRelationNuances.saga"
import partyRelationSaga from "./redux/partyRelations/partyRelations.saga"
import partySettingsSaga from "./redux/partySettings/partySettings.saga"
import peopleSaga from "./redux/people/people.saga"
import rolesSaga from "./redux/roles/roles.saga"
import surveySaga from "./redux/survey/survey.saga"
import surveyRequestSaga from "./redux/surveyRequest/surveyRequest.saga"
import surveyJsSaga from "./redux/surveyjs/surveyjs.saga"
import teamsSaga from "./redux/teams/teams.saga"
import usersSaga from "./redux/users/users.saga"
import surveyTypesSaga from "./redux/surveyTypes/surveyTypes.saga"

export default [
  dailyCheckSaga,
  eventsSaga,
  functionsSaga,
  groupsSaga,
  invitesSaga,
  meSaga,
  nuancesSaga,
  organisationsSaga,
  partyRelationSaga,
  partyRelationNuancesSaga,
  partySettingsSaga,
  rolesSaga,
  surveySaga,
  surveyJsSaga,
  usersSaga,
  surveyRequestSaga,
  teamsSaga,
  peopleSaga,
  nodesSaga,
  surveyTypesSaga,
]
