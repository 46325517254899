import PersonFunction from "app/models/functions"
import Group, { GroupPayload, MyGroupPayload } from "app/models/groups"
import { PartyParents } from "app/models/parties"
import Role from "app/models/roles"
import { MetaTypes } from "app/models/user"

import { Action, Types } from "./groups.actions"

export type GroupsState = {
  errors: object
  editedField: string | undefined
  loading: boolean
  groups: Group | undefined
  parents: PartyParents
  parentsLoading: boolean
  parameters?: GroupPayload | MyGroupPayload
}

const initialState: GroupsState = {
  editedField: undefined,
  errors: {},
  groups: undefined,
  loading: false,
  parents: [],
  parentsLoading: false,
}

const defineMetaKey = (type: MetaTypes) => {
  switch (type) {
    case MetaTypes.Function:
      return "functions"
    case MetaTypes.Role:
      return "roles"
    default:
      return
  }
}

export default (state = initialState, action: Action): GroupsState => {
  const { groups } = state
  const children = groups?.children || []

  switch (action.type) {
    case Types.GET:
      return {
        ...state,
        errors: {},
        loading: true,
        parameters: action.payload,
      }

    case Types.REFRESH_GROUPS:
      return {
        ...state,
        errors: {},
        loading: true,
      }

    case Types.GET_SUCCESS:
      return {
        ...state,
        groups: action.payload,
        loading: false,
      }

    case Types.GET_FAIL:
      const statusCode = action.payload.errors.response?.status
      const newState = {
        ...state,
        errors: action.payload.errors,
        loading: false,
      }

      if (statusCode === 403) {
        newState.groups = undefined
      }

      return newState

    case Types.PATCH:
      return {
        ...state,
        errors: {},
      }

    case Types.PATCH_SUCCESS:
      const { name, party_id } = action.payload
      return {
        ...state,
        editedField: undefined,
        groups: {
          ...state.groups!,
          children: [
            ...state.groups!.children.map((e) => {
              if (e.child_id === party_id) {
                return {
                  ...e,
                  name,
                }
              }
              return e
            }),
          ],
        },
      }

    case Types.PATCH_FAIL:
      return {
        ...state,
        errors: action.payload.errors,
      }

    case Types.POST:
      return {
        ...state,
        errors: {},
        loading: true,
      }

    case Types.POST_SUCCESS:
      return {
        ...state,
        loading: false,
      }

    case Types.POST_FAIL:
      return {
        ...state,
        errors: action.payload.errors,
        loading: false,
      }

    case Types.GET_PARENTS:
      return {
        ...state,
        errors: {},
        parentsLoading: true,
      }

    case Types.GET_PARENTS_SUCCESS:
      return {
        ...state,
        parents: action.payload,
        parentsLoading: false,
      }

    case Types.GET_PARENTS_FAIL:
      return {
        ...state,
        errors: action.payload.errors,
        loading: false,
      }

    case Types.SET_EDITED:
      return {
        ...state,
        editedField: action.payload.id,
      }

    case Types.PATCH_META_SUCCESS:
      const {
        current_metadata_id: patchCurrentMetaDataId,
        metadata_type: patchMetaDataType,
        metadata_id: patchMetaDataId,
        party_relation_id: patchPartyRelationId,
      } = action.payload

      const patchMetaKey = defineMetaKey(patchMetaDataType)

      if (!patchMetaKey) return state

      const updatedChildrenFunctions = children.map((child) => {
        const meta = child[patchMetaKey] as (PersonFunction | Role)[]
        const { party_relation_id: childPartyRelationId } = child
        const shouldPathMeta = childPartyRelationId === patchPartyRelationId

        const patchMeta = (m: PersonFunction | Role) => {
          const { id } = m
          const shouldPatch = id === patchCurrentMetaDataId
          return shouldPatch ? { ...m, id: patchMetaDataId } : m
        }
        const updatedMeta = shouldPathMeta ? meta.map(patchMeta) : meta

        return { ...child, [patchMetaKey]: updatedMeta }
      })

      return {
        ...state,
        groups: {
          ...state.groups!,
          children: updatedChildrenFunctions,
        },
      }

    case Types.POST_META_SUCCESS:
      const {
        metadata,
        metadata_type: postMetaDataType,
        party_relation: { id: postPartyRelationId },
      } = action.payload

      const postMetaKey = defineMetaKey(postMetaDataType)

      if (!postMetaKey) return state

      const addedChildrenMeta = children.map((child) => {
        const { party_relation_id } = child

        return {
          ...child,
          ...(party_relation_id === postPartyRelationId
            ? { [postMetaKey]: [...child[postMetaKey], { ...metadata }] }
            : {}),
        }
      })

      return {
        ...state,
        groups: {
          ...state.groups!,
          children: addedChildrenMeta,
        },
      }

    case Types.DELETE_META_SUCCESS:
      const {
        metadata_id: deleteMetaDataId,
        metadata_type: deleteMetaDataType,
        party_relation_id: deletePartyRelationId,
      } = action.payload

      const deleteMetaKey = defineMetaKey(deleteMetaDataType)

      if (!deleteMetaKey) return state

      const deletedChildrenMeta = children.map((child) => {
        const meta = child[deleteMetaKey] as (PersonFunction | Role)[]
        const { party_relation_id } = child

        const deletedMeta =
          party_relation_id === deletePartyRelationId
            ? meta.filter((m) => m.id !== deleteMetaDataId)
            : meta

        return {
          ...child,
          [deleteMetaKey]: deletedMeta,
        }
      })

      return {
        ...state,
        groups: {
          ...state.groups!,
          children: deletedChildrenMeta,
        },
      }

    default:
      return state
  }
}
