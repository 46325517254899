export const getInitials = (name: string) => {
  if (name) {
    const nameArray = name.split(" ")
    const regExp = /[a-z]/i
    const filteredNames = nameArray.filter(
      (name) => regExp.test(name.charAt(0)?.toLowerCase()) && name,
    )

    const firstName = filteredNames[0].charAt(0).toUpperCase()
    const lastName =
      filteredNames.length > 1 && filteredNames?.[filteredNames.length - 1]
        ? filteredNames[1].charAt(0).toUpperCase()
        : ""
    return firstName + lastName
  }
}
