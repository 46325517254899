import styled from "@emotion/styled"
import funToWorkWithTheme from "style/funToWorkWithTheme"

import { LayoutItem } from "../style"

export const AppMain = styled(LayoutItem)`
  flex: 1;
  flex-wrap: nowrap;
  overflow: auto;
`

export const AppSidebar = styled(LayoutItem)`
  position: sticky;
  top: 0;
  height: 100vh;
  align-self: flex-start;
  max-width: 34rem;
  flex: 0 1 37rem;
  display: flex;
  flex-direction: column;
`

export const AppTopNavigation = styled(LayoutItem)`
  position: sticky;
  top: 0;
  align-self: flex-start;
  background-color: ${funToWorkWithTheme.colors.primarySuperLight};
  z-index: 100;
`
