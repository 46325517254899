import { IntlShape, createIntl, createIntlCache } from "react-intl"

import enTranslations from "./en.translations.json"
import frTranslations from "./fr.translations.json"
import nlTranslations from "./nl.translations.json"

interface INavigator extends Navigator {
  userLanguage?: string
}

export enum Locales {
  EN = "en",
  NL = "nl",
  FR = "fr",
}

type IIntl = Record<Locales, IntlShape>

const locales = [Locales.EN, Locales.NL, Locales.FR]

const translations = {
  [Locales.EN]: {
    ...enTranslations,
  },
  [Locales.NL]: {
    ...nlTranslations,
  },
  [Locales.FR]: {
    ...frTranslations,
  },
}

const formats = {}

const navigator: INavigator = window.navigator
const navigatorLanguage = navigator.userLanguage || navigator.language
const navigatorLanguageCode = navigatorLanguage.substr(0, 2)

const locale = locales.find((l) => l === navigatorLanguageCode) || Locales.EN

const IntlCache = createIntlCache()
const Intl = {} as IIntl

const translate = (message: string, messageLocale: Locales = locale) => {
  if (!Intl[messageLocale]) {
    Intl[messageLocale] = createIntl(
      { locale: messageLocale, messages: translations[messageLocale] },
      IntlCache,
    )
  }
  const { formatMessage } = Intl[messageLocale]
  return formatMessage({
    id: message,
  })
}

const languages = [
  {
    label: `${translate("language.en", Locales.EN)}`,
    value: Locales.EN,
  },
  {
    label: `${translate("language.fr", Locales.FR)}`,
    value: Locales.FR,
  },
  {
    label: `${translate("language.nl", Locales.NL)}`,
    value: Locales.NL,
  },
]

export { locales, translations, formats, locale, translate, languages }
