import { AxiosPromise } from "axios"

import { Child, MyGroupPayload } from "app/models/groups"
import { Nodes } from "app/models/nodes"
import { TokenPayload } from "app/models/token"

import { usersApi } from "./api"

export const getMe = (data: Partial<TokenPayload>): AxiosPromise =>
  usersApi("/me", {
    data,
    method: "GET",
  })

export const getMyGroups = ({
  token,
  ...params
}: MyGroupPayload): AxiosPromise<Child[]> => {
  return usersApi("/me/groups", {
    data: { token },
    method: "GET",
    params,
  })
}

export const getMyNodes = ({ token }: TokenPayload): AxiosPromise<Nodes[]> => {
  return usersApi("/me/daily_checks_tree", {
    data: { token },
    method: "GET",
  })
}
