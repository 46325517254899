import Role, { GetRolesPayload } from "app/models/roles"

export enum Types {
  GET = "ROLES_GET",
  GET_SUCCESS = "ROLES_GET_SUCCESS",
  GET_FAIL = "ROLES_GET_FAIL"
}

export type Action = GetRolesAction | GetRolesSuccessAction | GetRolesFailAction

export type GetRolesAction = {
  payload: GetRolesPayload
  type: Types.GET
}

export type GetRolesSuccessAction = {
  payload: Role[]
  type: Types.GET_SUCCESS
}

export type GetRolesFailAction = {
  payload: { errors: object }
  type: Types.GET_FAIL
}

export function getRoles(data: GetRolesPayload): GetRolesAction {
  return {
    payload: data,
    type: Types.GET
  }
}

export function getRolesSuccess(data: Role[]): GetRolesSuccessAction {
  return {
    payload: data,
    type: Types.GET_SUCCESS
  }
}

export function getRolesFail(errors: object): GetRolesFailAction {
  return {
    payload: { errors },
    type: Types.GET_FAIL
  }
}
