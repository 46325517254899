import styled from "@emotion/styled"
import { Typography } from "@material-ui/core"

export const IconWrapper = styled.div`
  cursor: pointer;
  svg {
    height: 1.4rem;
    width: 1.4rem;
  }
`

export const TooltipWrapper = styled(Typography)`
  line-height: 1.4em;
  color: white;
`
