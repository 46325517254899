function getEnvVariable(name: string) {
  return process.env[name] || ""
}

const AUTH0_DOMAIN = getEnvVariable("REACT_APP_AUTH0_DOMAIN")

export const API_URLS = {
  oauth: AUTH0_DOMAIN,
}

export const CLIENT_ID: string = getEnvVariable("REACT_APP_CLIENT_ID")
export const USER_SERVICE: string = getEnvVariable("REACT_APP_USERS_URL")
export const REDIRECT_URI: string = getEnvVariable("REACT_APP_REDIRECT_URI")
export const AUDIENCE: string = getEnvVariable("REACT_APP_AUTH0_AUDIENCE")

export const REACT_APP_TERMS_OF_USE_LINK: string = getEnvVariable(
  "REACT_APP_TERMS_OF_USE_LINK",
)
export const REACT_APP_PRIVACY_POLICY_LINK: string = getEnvVariable(
  "REACT_APP_PRIVACY_POLICY_LINK",
)
