import { Action, Types } from "./invites.actions"

export type InvitesState = {
  dispatched: boolean
  errors: object
  loading: boolean
  showModal: boolean
}

const initialState: InvitesState = {
  dispatched: false,
  errors: {},
  loading: false,
  showModal: false
}

export default (state = initialState, action: Action): InvitesState => {
  switch (action.type) {
    case Types.POST:
      return {
        ...state,
        loading: true
      }

    case Types.POST_SUCCESS:
      return {
        ...state,
        dispatched: true,
        errors: {},
        loading: false
      }

    case Types.POST_FAIL:
      return {
        ...state,
        errors: action.payload.errors,
        loading: false
      }

    case Types.TOGGLE_MODAL:
      return {
        ...state,
        errors: {},
        showModal: !state.showModal
      }

    default:
      return state
  }
}
