import { Event } from "app/models/event"

export enum Types {
  POST = "EVENTS_POST",
  POST_SUCCESS = "EVENTS_POST_SUCCESS",
  POST_FAIL = "EVENTS_POST_FAIL"
}

export type Action =
  | PostEventsAction
  | PostEventsSuccessAction
  | PostEventsFailAction

export type PostEventsAction = {
  payload: { data: Event }
  type: Types.POST
}

export type PostEventsSuccessAction = {
  type: Types.POST_SUCCESS
}

export type PostEventsFailAction = {
  payload: { errors: object }
  type: Types.POST_FAIL
}

export function postEvents(data: Event): PostEventsAction {
  return {
    payload: { data },
    type: Types.POST
  }
}

export function postEventsSuccess(): PostEventsSuccessAction {
  return {
    type: Types.POST_SUCCESS
  }
}

export function postEventsFail(errors: object): PostEventsFailAction {
  return {
    payload: { errors },
    type: Types.POST_FAIL
  }
}
