import { createMuiTheme } from "@material-ui/core/styles"
import { MuiBaseInputStyle } from "../form"
import { viorasTheme } from "../theme"

const primary = viorasTheme.colors.primary.normal
const secondary = viorasTheme.colors.secondary.normal

const baseTheme = createMuiTheme({
  palette: {
    background: {
      default: viorasTheme.colors.background
    },
    common: {
      black: viorasTheme.colors.body
    },
    error: {
      dark: viorasTheme.colors.error.dark,
      light: viorasTheme.colors.error.light,
      main: viorasTheme.colors.error.normal
    },
    info: {
      dark: viorasTheme.colors.info.dark,
      light: viorasTheme.colors.info.light,
      main: viorasTheme.colors.info.normal
    },
    primary: {
      main: primary
    },
    secondary: {
      main: secondary
    },
    success: {
      dark: viorasTheme.colors.success.dark,
      light: viorasTheme.colors.success.light,
      main: viorasTheme.colors.success.normal
    },
    text: {
      disabled: "rgba(0, 0, 0, 0.38)",
      hint: "rgba(0, 0, 0, 0.38)",
      primary: viorasTheme.colors.body,
      secondary: viorasTheme.colors.grey[40]
    },
    warning: {
      dark: viorasTheme.colors.warning.dark,
      light: viorasTheme.colors.warning.light,
      main: viorasTheme.colors.warning.normal
    }
  },
  spacing: (factor) => `${(0.8 * factor).toPrecision(2)}rem`,
  typography: {
    body1: {
      fontSize: viorasTheme.typography.fontSize
    },
    body2: {
      fontSize: viorasTheme.typography.fontSize
    },
    button: {
      fontSize: viorasTheme.typography.fontSize_sm
    },
    caption: {
      fontSize: viorasTheme.typography.fontSize_sm
    },
    fontFamily: "verdana",
    h1: {
      color: viorasTheme.colors.black,
      fontSize: viorasTheme.typography.fontSize_h1,
      fontWeight: 600
    },
    h2: {
      color: viorasTheme.colors.black,
      fontSize: viorasTheme.typography.fontSize_h2,
      fontWeight: 600
    },
    h3: {
      color: viorasTheme.colors.black,
      fontSize: viorasTheme.typography.fontSize_h3,
      fontWeight: 600
    },
    h4: {
      color: viorasTheme.colors.black,
      fontSize: viorasTheme.typography.fontSize_h4,
      fontWeight: 600
    },
    h5: {
      color: viorasTheme.colors.black,
      fontSize: viorasTheme.typography.fontSize_h5,
      fontWeight: 600
    },
    h6: {
      color: viorasTheme.colors.body,
      fontSize: viorasTheme.typography.fontSize_h6,
      fontWeight: 600
    },
    htmlFontSize: viorasTheme.typography.fontSizeHTML
  }
})

const theme = createMuiTheme({
  ...baseTheme,
  overrides: {
    MuiBreadcrumbs: {
      li: {
        "&:last-child": {
          fontWeight: 600
        }
      },
      separator: {
        marginLeft: `${baseTheme.spacing(2)}`,
        marginRight: `${baseTheme.spacing(2)}`
      }
    },
    MuiButton: {
      outlined: {
        fontSize: "1.2rem",
        padding: ".8rem 2.2rem"
      },
      outlinedPrimary: {
        color: viorasTheme.colors.body
      },
      root: {
        fontSize: "1.2rem",
        padding: ".8rem 2.2rem"
      },
      sizeLarge: {
        "&$outlinedSizeLarge": {
          padding: "calc(.8rem - 1px) calc(2.2rem - 1px)"
        },
        fontSize: "1.4rem",
        padding: ".8rem 2.2rem"
      },
      sizeSmall: {
        fontSize: "1.2rem",
        padding: ".2rem .8rem"
      },
      startIcon: {
        marginRight: "1.6rem"
      }
    },
    MuiButtonGroup: {
      grouped: {
        fontSize: "1.6rem",
        lineHeight: "1.8rem",
        padding: `${baseTheme.spacing(1)} ${baseTheme.spacing(2)}`
      },
      groupedOutlinedPrimary: {
        color: viorasTheme.colors.primary.normal
      }
    },
    MuiChip: {
      root: {
        backgroundColor: viorasTheme.colors.grey[90]
      }
    },
    MuiDrawer: {
      paper: {
        maxWidth: "100%",
        minHeight: "100%",
        width: "55rem"
      }
    },
    MuiFormControlLabel: {
      label: {
        fontSize: "1rem"
      }
    },
    MuiIcon: {
      root: {
        width: "auto"
      }
    },
    MuiInputAdornment: {
      positionStart: {
        marginLeft: "9px",
        marginRight: 0
      }
    },
    MuiInputBase: {
      ...MuiBaseInputStyle,
      multiline: {
        ...MuiBaseInputStyle.input,
        alignItems: "flex-start",
        minHeight: "6rem"
      }
    },
    MuiInputLabel: {
      root: {
        borderRadius: "4px",
        color: viorasTheme.colors.primary.normal,
        fontSize: "1.4rem",
        marginBottom: "0.8rem"
      }
    },
    MuiLink: {
      root: {
        cursor: "pointer"
      }
    },
    MuiMenuItem: {
      root: {
        fontSize: "1.2rem"
      }
    },
    MuiSelect: {
      icon: {
        right: "0.4rem"
      },
      selectMenu: {
        paddingRight: "3.2rem !important"
      }
    },
    MuiSnackbarContent: {
      root: {
        backgroundColor: viorasTheme.colors.grey[90],
        color: viorasTheme.colors.black
      }
    },
    MuiTable: {
      root: {
        borderCollapse: "separate"
      }
    },
    MuiTableCell: {
      head: {
        borderBottom: `1px solid ${viorasTheme.colors.grey[90]}`,
        borderTop: `1px solid ${viorasTheme.colors.grey[90]}`,
        padding: `${baseTheme.spacing(1)} 4rem`
      },
      root: {
        borderBottom: "none",
        padding: "2rem 4rem"
      }
    },
    MuiTableRow: {
      hover: {
        backgroundColor: "white"
      }
    },
    MuiTooltip: {
      arrow: {
        ".MuiTooltip-popperArrow[x-placement*='top'] &::before": {
          borderColor: "rgba(0, 0, 0, 0.95) transparent transparent transparent"
        }
      },
      tooltip: {
        backgroundColor: "rgba(0, 0, 0, 0.95)",
        lineHeight: "1.4rem"
      }
    },
    MuiTypography: {
      gutterBottom: {
        marginBottom: baseTheme.spacing(2)
      }
    }
  }
})

export default theme
