import * as api from "app/api/users"
import { SagaIterator } from "redux-saga"
import { call, put, takeLatest } from "redux-saga/effects"
import * as actions from "./surveyjs.actions"

export function* getSurveyFlow(
  action: actions.GetJsSurveyAction
): SagaIterator {
  const { payload } = action
  try {
    const response = yield call(api.getPartyRelations, payload)
    const parentId = response.data[0].parent_id
    const survey = yield call(api.getJsSurvey, { ...payload, id: parentId })
    yield put(actions.getJsSurveySuccess(survey))
  } catch (e) {
    yield put(actions.getJsSurveyFail(e))
  }
}
export function* postSurveyFlow(
  action: actions.PostJsSurveyAction
): SagaIterator {
  try {
    const { payload } = action
    yield call(api.postJsSurvey, payload)
    yield put(actions.postJsSurveySuccess())
    yield put(actions.postJsSurveySuccessCallback())
  } catch (e) {
    yield put(actions.postJsSurveyFail(e))
  }
}

export default function* surveySaga(): SagaIterator {
  yield takeLatest(actions.Types.GET, getSurveyFlow)
  yield takeLatest(actions.Types.POST, postSurveyFlow)
}
