export enum ROUTE_PATHS {
  DAILY_CHECK_OVERVIEW = "/daily-check",
  REALITY_CHECK_OVERVIEW = "/reality-check",
  REALITY_CHECK_DASHBOARD = "/reality-check/dashboard/:id?",
  REALITY_CHECK_REPORTS = "/team-reports/:id",
  DAILY_CHECK = "/daily-check/check/:id",
  REALITY_CHECK = "/reality-check/check/:id",
  LEADERSHIP_OVERVIEW = "/survey/leadership/:survey_request_id",
  LEADERSHIP_ADMIN = "/leadership/surveys/:id?",
  CLIMATE_OVERVIEW = "/survey/climate/:survey_request_id",
  CUSTOM_OVERVIEW = "/survey/custom/:survey_request_id",
  CLIMATE_ADMIN = "/climate/surveys/:id?",
  TEAM_MEMBER_DASHBOARD = "/team/:id/members/dashboard",

  // New admin routes
  TEAM_REALITY_CHECK_DASHBOARD = "/team/:id/reality-check/dashboard",
  TEAM_LEADERSHIP_SURVEYS = "/team/:id/leadership",
  TEAM_CLIMATE_SURVEYS = "/team/:id/climate",
  TEAM_SURVEYS = "/team/:id/surveys/:surveyTypeId",
  ORGANISATION_PEOPLE = "/organisation/people",
  ORGANISATION_TEAMS = "/organisation/teams",
  TEAM_REALITY_CHECK_SETTINGS = "/team/:id/reality-check/dashboard/settings",
}
