import { AxiosPromise } from "axios"

import DailyCheck, {
  DailyCheckCommentsPayload,
} from "app/models/dailycheck"
import {
  GroupPayload,
  PatchGroupPayload,
  PostGroupPayload,
} from "app/models/groups"
import { InvitePayload } from "app/models/invite"
import { Nodes } from "app/models/nodes"
import { PartyChildrenPayload, PartyPayload } from "app/models/parties"
import {
  DeletePartyRelationPayload,
  PartyRelationPayload,
} from "app/models/partyRelation"
import { PeoplePayload } from "app/models/people"
import {
  DeleteSurveyPayload,
  GetSurveysPayload,
  PatchSurveyPayload,
  PostSurveyPayload,
} from "app/models/survey"
import { PostSurveyAnswersPayload } from "app/models/surveyRequest"
import JsSurvey, { SurveyJsPayload } from "app/models/surveyjs"
import { GetTeamsPayload } from "app/models/team"
import { TokenPayload } from "app/models/token"
import {
  DeleteMetaPayload,
  PatchMetaPayload,
  PostMetaPayload,
  User,
  UserPayload,
  UserTermsOfUsePayload,
} from "app/models/user"

import { GetSurveyQuestionsPayload } from "../redux/surveyRequest/surveyRequest.actions"
import { usersApi } from "./api"

export const getUsersById = (id: string): AxiosPromise =>
  usersApi(`/users/${id}`)

export const getUsers = (): AxiosPromise =>
  usersApi("/users", {
    method: "GET",
  })

export const patchUsers = (data: User): AxiosPromise =>
  usersApi(`/users/${data.party_id}`, {
    data,
    method: "PATCH",
  })

export const deleteUsersById = (id: string): AxiosPromise => {
  return usersApi(`/users/${id}`, {
    method: "DELETE",
  })
}

export const postUsers = (payload: UserPayload): AxiosPromise => {
  return usersApi("/people", {
    data: payload,
    method: "POST",
  })
}

export const patchUser = ({
  data: { party_id, ...data },
}: UserPayload): AxiosPromise => {
  return usersApi(`/people/${party_id}`, {
    data,
    method: "PATCH",
  })
}

export const patchUserTermsOfUseAccept = ({
  data: { party_id },
}: UserTermsOfUsePayload): AxiosPromise => {
  return usersApi(`/people/${party_id}/terms-of-use/accept`, {
    data: {},
    method: "PATCH",
  })
}

export const getDailyCheck = ({
  data,
  partyRelationId,
}: {
  data: any
  partyRelationId: string
}): AxiosPromise => {
  return usersApi(`/party_relations/${partyRelationId}/daily_checks/previous`, {
    data,
    method: "GET",
  })
}

export const postDailyCheck = (data: DailyCheck, id: string): AxiosPromise => {
  return usersApi(`/party_relations/${id}/daily_checks`, {
    data,
    method: "POST",
  })
}

export const getPartyRelations = (
  data: Partial<TokenPayload>,
): AxiosPromise => {
  return usersApi("/party_relations", {
    data,
    method: "GET",
  })
}

export const postPartyRelations = (
  data: PartyRelationPayload,
): AxiosPromise => {
  return usersApi("/party_relations", {
    data,
    method: "POST",
  })
}

export const deletePartyRelation = (
  data: DeletePartyRelationPayload,
): AxiosPromise => {
  const { id: partyRelationId } = data

  return usersApi(`/party_relations/${partyRelationId}`, {
    data,
    method: "DELETE",
  })
}

export const getJsSurvey = (data: SurveyJsPayload): AxiosPromise<JsSurvey> => {
  const { id: partyId } = data
  return usersApi(`/parties/${partyId}/surveyjs`, {
    data,
    method: "GET",
  })
}

export const postJsSurvey = ({ id, data }: SurveyJsPayload): AxiosPromise => {
  return usersApi(`/surveyjs/${id}/surveyjs_answers`, {
    data,
    method: "POST",
  })
}

export const patchGroups = (data: PatchGroupPayload) => {
  const { party_id, name, token, ...params } = data

  return usersApi(`/groups/${party_id}`, {
    data: { name, token },
    params,
    method: "PATCH",
  })
}

export const postGroups = (data: PostGroupPayload) => {
  return usersApi("/me/groups", {
    data,
    method: "POST",
  })
}

export const getPartyChildren = (data: PartyChildrenPayload): AxiosPromise => {
  const { party_id } = data
  return usersApi(`/parties/${party_id}/children?limit=25&depth=1`, {
    data,
    method: "GET",
  })
}

export const getPartyRelationChildren = (data: GroupPayload): AxiosPromise => {
  const { limit = 25, id, depth = 3, q = "", sort = "name=asc" } = data
  delete data.id
  return usersApi(
    `/parties/${id}/children?limit=${limit}&depth=${depth}&q=${q}&sort[]=${sort}`,
    {
      data,
      method: "GET",
    },
  )
}

export const getOrganisationPeople = (data: PeoplePayload): AxiosPromise => {
  const { organisation_id } = data
  return usersApi(`/organizations/${organisation_id}/people`, {
    data,
    method: "GET",
  })
}

export const getPartyParents = (data: PartyPayload): AxiosPromise => {
  const { party_id } = data
  return usersApi(`/parties/${party_id}/parents`, {
    data,
    method: "GET",
  })
}

export const getDailyCheckComments = (
  data: DailyCheckCommentsPayload,
): AxiosPromise => {
  const {
    date = new Date().toISOString(),
    party_id,
    page = 1,
    perPage = 10,
    limit = 25,
    depth = 1,
  } = data
  return usersApi(
    `/parties/${party_id}/daily_checks?limit=${limit}&depth=${depth}&date=${date}&page=${page}&perPage=${perPage}`,
    {
      data: {
        token: data.token,
      },
      method: "GET",
    },
  )
}

export const postInvite = ({
  party_id,
  ...data
}: InvitePayload): AxiosPromise => {
  return usersApi(`/parties/${party_id}/invite`, {
    data,
    method: "POST",
  })
}

export const patchPartyRelationsMeta = ({
  current_metadata_id,
  party_relation_id,
  ...data
}: PatchMetaPayload): AxiosPromise => {
  return usersApi(
    `/party_relations/${party_relation_id}/metadata/${current_metadata_id}`,
    {
      data,
      method: "PATCH",
    },
  )
}

export const postPartyRelationsMeta = ({
  party_relation_id,
  ...data
}: PostMetaPayload): AxiosPromise => {
  return usersApi(`/party_relations/${party_relation_id}/metadata/`, {
    data,
    method: "POST",
  })
}

export const deletePartyRelationsMeta = ({
  party_relation_id,
  metadata_id,
  ...data
}: DeleteMetaPayload): AxiosPromise => {
  return usersApi(
    `/party_relations/${party_relation_id}/metadata/${metadata_id}`,
    {
      data,
      method: "DELETE",
    },
  )
}

export const getSurveys = ({
  group_id,
  survey_type_id,
  ...data
}: GetSurveysPayload) =>
  usersApi("/surveys", {
    data,
    params: {
      group_id,
      survey_type_id,
    },
  })

export const postSurvey = (data: PostSurveyPayload) =>
  usersApi("/surveys", {
    data,
    method: "POST",
  })

export const deleteSurvey = (data: DeleteSurveyPayload): AxiosPromise => {
  const { id } = data

  return usersApi(`/surveys/${id}`, {
    data,
    method: "DELETE",
  })
}

export const patchSurvey = ({ survey_id, ...data }: PatchSurveyPayload) =>
  usersApi(`/surveys/${survey_id}`, {
    data,
    method: "PATCH",
  })

export const getSurveyProcessing = ({
  survey_request_id,
  ...data
}: GetSurveyQuestionsPayload): AxiosPromise => {
  return usersApi(`/surveys/processing/${survey_request_id}`, {
    data,
    method: "GET",
  })
}

export const postSurveyAnswers = ({
  data,
  survey_request_id,
}: {
  data: PostSurveyAnswersPayload[]
  survey_request_id: string
}): AxiosPromise => {
  return usersApi(`/answers?survey_request_id=${survey_request_id}`, {
    data,
    method: "POST",
  })
}

export const getTeams = ({ group_id, ...data }: GetTeamsPayload) => {
  return usersApi(`/groups/${group_id}/children`, {
    data,
    method: "GET",
  })
}

export const getTeamNodes = ({
  id,
  token,
}: {
  id: string
} & TokenPayload): AxiosPromise<Nodes[]> => {
  return usersApi(`/v2/parties/${id}/daily_checks_tree`, {
    data: { token },
    method: "GET",
  })
}
