import styled from "@emotion/styled"
import { Grid, Typography } from "@material-ui/core"
import funToWorkWithTheme from "style/funToWorkWithTheme"

export const AvatarWrapper = styled(Grid)`
  display: inline-flex;
  width: auto;
  height: auto;
  flex-wrap: nowrap;
  font-size: 1.3rem;
`

export const AvatarInitials = styled.div`
  width: 20px;
  height: 20px;
  text-align: center;
  color: white;
  font-weight: bold;
  display: flex;
  justify-content: center;
  align-items: center;
`

export const AvatarInitialsWrapper = styled.div`
  background-color: ${funToWorkWithTheme.colors.accent1};
  border-radius: 50%;
  padding: 10px;
`

export const AvatarText = styled(Typography)`
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  margin-left: 0.8rem;
  overflow: hidden;
  text-overflow: ellipsis;
`
