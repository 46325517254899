import {
  Box,
  FormControlLabel,
  FormGroup,
  Switch,
  Typography,
} from "@material-ui/core"
import { ENV_FEATURE_FLAG } from "enums"
import React from "react"
import { useHistory } from "react-router"
import funToWorkWithTheme from "style/funToWorkWithTheme"

import applyFeature from "utils/applyFeature"

export function setNewNavigation(isNewNavigation: boolean): void {
  localStorage.setItem(
    ENV_FEATURE_FLAG.IS_NEW_NAVIGATION,
    isNewNavigation ? "true" : "false",
  )
}

// THIS FULL COMPONENT WILL BE DELETED WHEN NEW NAVIGATION IS BEING USED.
// THEREFORE I OPTED FOR USING LOCALSTORAGE INSTEAD OF PROPER STATE MANAGEMENT TO WIN TIME
const NavigationSwitch = () => {
  const history = useHistory()

  const isNewNavigation = applyFeature(ENV_FEATURE_FLAG.IS_NEW_NAVIGATION)

  const handleNavigationSwitch = (event: Record<string, any>) => {
    const checked = event.target.checked
    isNewNavigation === false
      ? history.push("/")
      : history.push("/account/groups")
    setNewNavigation(checked)

    window.location.reload()
  }

  return (
    <Box>
      <FormGroup>
        <FormControlLabel
          control={
            <Switch
              checked={isNewNavigation ? true : false}
              onChange={handleNavigationSwitch}
            />
          }
          label={
            <Typography
              variant="caption"
              style={{ color: funToWorkWithTheme.colors.lightBrown }}
            >
              New Navigation
            </Typography>
          }
        />
      </FormGroup>
    </Box>
  )
}

export default NavigationSwitch
