import { Palette } from "@material-ui/core/styles/createPalette"
import { TypographyOptions } from "@material-ui/core/styles/createTypography"
import { funToWorkWithTheme } from "style/theme"

type Typography = TypographyOptions | ((palette: Palette) => TypographyOptions)

export const typography: Typography = {
  fontFamily: funToWorkWithTheme.typography.fontFamily,
  fontSize: 10,
  htmlFontSize: 10,

  h1: {
    fontFamily: "Cy",
    color: funToWorkWithTheme.colors.primary,
    fontSize: "4rem",
    fontWeight: "bold",
    lineHeight: "5.3rem"
  },
  h2: {
    color: funToWorkWithTheme.colors.primary,
    fontSize: "3.2rem",
    fontWeight: "bold",
    lineHeight: "3.9rem"
  },
  h3: {
    color: funToWorkWithTheme.colors.primary,
    fontSize: "2.4rem",
    fontWeight: "bold",
    lineHeight: "3.4rem"
  },
  h4: {
    color: funToWorkWithTheme.colors.primary,
    fontSize: "2.4rem",
    fontWeight: "bold",
    lineHeight: "2.9rem"
  },
  body1: {
    color: funToWorkWithTheme.colors.primary,
    fontSize: "2rem",
    fontWeight: "normal",
    lineHeight: "2.4rem"
  },
  body2: {
    color: funToWorkWithTheme.colors.primary,
    fontSize: "1.6rem",
    fontWeight: "normal",
    lineHeight: "2.0rem"
  },
  overline: {
    color: funToWorkWithTheme.colors.primary,
    fontSize: "1.4rem",
    fontWeight: "bold",
    lineHeight: "1.7rem",
    textTransform: "uppercase"
  },
  caption: {
    color: funToWorkWithTheme.colors.primary,
    fontSize: "1.4rem",
    fontWeight: "normal",
    lineHeight: "1.8rem"
  }
}
