import * as api from "app/api/partySettings"
import { SagaIterator } from "redux-saga"
import { call, put, takeLatest, select } from "redux-saga/effects"
import * as actions from "./partySettings.actions"
import { push } from "connected-react-router"
import { router as routerSelector } from "app/redux/router/router.selectors"
import { showMessage } from "../message/message.actions"

export function* getPartySettingsFlow({
  payload
}: actions.GetPartySettingsAction): SagaIterator {
  try {
    const response = yield call(api.getPartySettings, payload)
    yield put(actions.getPartySettingsSuccess(response.data))
  } catch (e) {
    const { location } = yield select(routerSelector)
    if (e.response.status === 404) {
      if (location.pathname !== "/signup") {
        yield put(push("/signup"))
      }
    }
    yield put(actions.getPartySettingsFail(e))
  }
}

export function* patchPartySettingsFlow({
  payload
}: actions.PatchPartySettingsAction): SagaIterator {
  try {
    const response = yield call(api.patchPartySettings, payload)
    yield put(actions.patchPartySettingsSuccess(response.data))
    yield put(showMessage("dailycheck.reminder.success"))
  } catch (e) {
    yield put(actions.getPartySettingsFail(e))
  }
}
export default function* partySettingsSaga(): SagaIterator {
  yield takeLatest(actions.Types.GET, getPartySettingsFlow)
  yield takeLatest(actions.Types.PATCH, patchPartySettingsFlow)
}
