import styled from "@emotion/styled"

export const TooltipContainer = styled.div`
  z-index: 1;
`

export const StyledButton = styled.button`
  background: none;
  border: 0;
  padding: 0;
  font: inherit;
  color: inherit;
`
