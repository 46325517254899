import React from "react"

const GeneralErrorIcon = () => {
  return (
    <svg
      width="463"
      height="491"
      viewBox="0 0 463 491"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M133.267 397.407L152.966 470.922C153.403 472.554 154.469 473.948 155.929 474.795C157.389 475.642 159.124 475.874 160.753 475.44L219.392 459.727L198.702 379.874C188.642 342.329 209.78 304.035 245.928 294.349C282.075 284.664 319.526 307.25 329.588 344.803L350.938 424.48L409.529 408.78C411.158 408.344 412.547 407.276 413.389 405.811C414.232 404.347 414.459 402.606 414.021 400.972L394.286 327.319C374.166 252.229 299.964 207 227.686 226.367C155.407 245.734 113.145 322.309 133.267 397.407Z"
        fill="#3B82E0"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M140.125 86.953C142.886 82.17 141.247 76.0541 136.464 73.2927L123.495 65.8047C118.712 63.0433 112.596 64.6821 109.834 69.465L79.8578 121.386L27.9373 91.4096C23.1543 88.6482 17.0384 90.2869 14.277 95.0698L6.78901 108.039C4.02758 112.822 5.66634 118.938 10.4493 121.7L62.3698 151.676L32.3945 203.595C29.633 208.378 31.2718 214.494 36.0547 217.255L49.0243 224.743C53.8072 227.505 59.9231 225.866 62.6845 221.083L92.6599 169.164L144.579 199.14C149.362 201.901 155.478 200.262 158.239 195.479L165.727 182.51C168.489 177.727 166.85 171.611 162.067 168.85L110.148 138.874L140.125 86.953Z"
        fill="#3D3C3D"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M361.49 27.7686C364.251 22.9857 362.613 16.8698 357.83 14.1084L344.86 6.62039C340.077 3.85897 333.961 5.49773 331.2 10.2807L301.223 62.2016L249.303 32.2253C244.52 29.4638 238.404 31.1026 235.642 35.8855L228.154 48.8551C225.393 53.638 227.032 59.7539 231.815 62.5154L283.735 92.4917L253.76 144.411C250.999 149.194 252.637 155.309 257.42 158.071L270.39 165.559C275.173 168.32 281.289 166.682 284.05 161.899L314.025 109.98L365.945 139.955C370.728 142.717 376.844 141.078 379.605 136.295L387.093 123.325C389.854 118.543 388.216 112.427 383.433 109.665L331.513 79.6896L361.49 27.7686Z"
        fill="#3D3C3D"
      />
    </svg>
  )
}

export default GeneralErrorIcon
