import { Grid } from "@material-ui/core"
import {
  findTo,
  ISideBarNavigation,
  useNavigation
} from "app/context/NavigationContext"
import { useTranslation } from "app/hooks/translate"
import Translation from "components/Translation"
import React from "react"
import { matchPath, useLocation } from "react-router"
import {
  SidebarDropdownContent,
  SidebarDropdownIcon,
  SidebarDropdownLink,
  SidebarDropdownTrigger,
  SidebarDropdownWrapper
} from "./style"

export interface IDropdownContent {
  navigation: ISideBarNavigation[]
}

type TDropdownContent = Pick<ISideBarNavigation, "label" | "icon">

const DropdownLinkContent = ({ label, icon: LinkIcon }: TDropdownContent) => {
  return (
    <>
      {LinkIcon && <SidebarDropdownIcon component={LinkIcon} />}
      <Translation label={label} />
    </>
  )
}

const DropdownLink = ({ to, isActive, color, ...rest }: ISideBarNavigation) => {
  return (
    <Grid item>
      <SidebarDropdownLink colorName={color} to={to} isActive={isActive}>
        <DropdownLinkContent {...rest} />
      </SidebarDropdownLink>
    </Grid>
  )
}

const DropdownContent: React.FC<IDropdownContent> = ({ navigation }) => {
  return (
    <SidebarDropdownContent>
      {navigation.length > 0 && (
        <Grid container spacing={2} direction="column">
          {navigation.map((link, index) => {
            return (
              <DropdownLink key={`sidebar-dropdown-link-${index}`} {...link} />
            )
          })}
        </Grid>
      )}
    </SidebarDropdownContent>
  )
}

export const SidebarDropdown: React.FC = () => {
  const translate = useTranslation()
  const location = useLocation()
  const { navigation = [] } = useNavigation()

  const activeNavigation = navigation.map((n) => {
    const { routes = [] } = n
    const isActive = routes.some((r) => {
      const to = r.to || findTo(r.routes || [])
      if (!to) return false

      const match = matchPath(to, {
        exact: false,
        path: location.pathname.split("/", 2).join("/")
      })

      return !!match
    })

    return {
      ...n,
      ...(isActive ? { isActive } : {})
    }
  })

  const activeLink = activeNavigation.find((nav) => {
    return nav.isActive
  })

  const label = activeLink ? activeLink.label : translate("navigation.modules")
  const icon = activeLink ? activeLink.icon : null
  const content = <DropdownLinkContent icon={icon} label={label} />

  return (
    <SidebarDropdownWrapper
      placement="bottom-start"
      usePortal={false}
      trigger="click"
      content={<DropdownContent navigation={activeNavigation} />}
    >
      <SidebarDropdownTrigger colorName={activeLink?.color} variant="h3">
        {content}
      </SidebarDropdownTrigger>
    </SidebarDropdownWrapper>
  )
}
