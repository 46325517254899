import React from "react"
import { useDispatch } from "react-redux"
import { useAuth0 } from "app/context/AuthContext"

export const useApi = () => {
  const dispatch = useDispatch()
  const { getTokenSilently, isAuthenticated } = useAuth0()

  const callApi = React.useCallback(
    async <T>(call: React.Dispatch<any>, data?: T) => {
      try {
        if (!isAuthenticated) {
          return
        }

        const token = await getTokenSilently()
        const dataWithToken = { ...data, token }
        dispatch(call(dataWithToken))
      } catch (err) {
        console.warn(err)
      }
    },
    [dispatch, getTokenSilently, isAuthenticated],
  )

  return {
    callApi,
  }
}
