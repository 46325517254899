import JsSurvey from "app/models/surveyjs"
import { Action, Types } from "./surveyjs.actions"

export type SurveyJsState = {
  errors: object
  loading: boolean
  dispatched: boolean
  success?: boolean
  data?: JsSurvey[]
}

const initialState: SurveyJsState = {
  data: [],
  dispatched: false,
  errors: {},
  loading: true
}

export default (state = initialState, action: Action): SurveyJsState => {
  switch (action.type) {
    case Types.GET:
      return {
        ...state,
        loading: true
      }

    case Types.GET_SUCCESS:
      return {
        ...state,
        data: action.payload,
        loading: false
      }

    case Types.GET_FAIL:
      return {
        ...state,
        errors: action.payload.errors,
        loading: false
      }

    case Types.POST:
      return {
        ...state,
        dispatched: false,
        loading: true
      }

    case Types.POST_SUCCESS:
      return {
        ...state,
        dispatched: true,
        loading: false,
        success: true
      }

    case Types.POST_SUCCESS_CALLBACK:
      return {
        ...state,
        dispatched: false,
        loading: false,
        success: undefined
      }

    case Types.POST_FAIL:
      return {
        ...state,
        dispatched: false,
        errors: action.payload.errors,
        loading: false,
        success: false
      }

    default:
      return state
  }
}
