import React from "react"
import { StyledBreadcrumbs } from "./style"
import ChevronRight from "assets/icons/ChevronRight"

type Props = {
  children: any
}

export const BreadcrumbsContainer = ({ children }: Props) => {
  return (
    <StyledBreadcrumbs
      aria-label="breadcrumb"
      separator={<ChevronRight fontSize="small" />}
    >
      {children}
    </StyledBreadcrumbs>
  )
}

export default BreadcrumbsContainer
