import State from "../state"
import { SurveyRequest } from "app/models/surveyRequest"

export const isLoading = (state: State): boolean => state.surveyRequest.loading
export const errors = (state: State): object => state.surveyRequest.errors
export const questions = (state: State): SurveyRequest =>
  state.surveyRequest.data
export const getSurveyRequestPreferredLanguage = (
  state: State
): string | undefined => state.surveyRequest.data?.language
export const getOrganizationActiveFeatures = (state: State) =>
  state.surveyRequest.data.active_features
