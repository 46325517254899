import React, { memo } from "react"
import Translation from "components/Translation"
import MuiTooltip, { TooltipProps } from "@material-ui/core/Tooltip"
import Info from "@material-ui/icons/InfoOutlined"
import { IconWrapper, TooltipWrapper } from "./style"

type Props = {
  label: string
  tooltipValues?: object
} & Pick<TooltipProps, "placement">

const Tooltip = ({ label, tooltipValues, placement = "top-end" }: Props) => {
  return (
    <MuiTooltip
      placement={placement}
      title={
        <TooltipWrapper variant="body1">
          <Translation label={label} values={tooltipValues} />
        </TooltipWrapper>
      }
    >
      <IconWrapper>
        <Info />
      </IconWrapper>
    </MuiTooltip>
  )
}

export default memo(Tooltip)
