import styled from "@emotion/styled"

export const ErrorContainer = styled.div`
  position: absolute;
  width: 100vw;
  height: 100vh;
  background-color: white;
  left: 0;
  top: 0;
  z-index: 10;
`

export const IconWrapper = styled.div`
  display: flex;
  justify-content: ceneter;
  align-items: center;
  margin-right: 2em;
  & > svg {
    font-size: 5em;
  }
  max-width: 25%;
`
