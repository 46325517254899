import { SurveyType } from "app/models/surveyType";
import { AxiosError } from "axios";

export enum Type {
  GET_SURVEY_TYPES = "GET_SURVEY_TYPES",
  GET_SURVEY_TYPES_SUCCESS = "GET_SURVEY_TYPES_SUCCESS",
  GET_SURVEY_TYPES_FAIL = "GET_SURVEY_TYPES_FAIL"
}

export type Action =
  | GetSurveyTypes
  | GetSurveyTypesSuccess
  | GetSurveyTypesFail

export interface GetSurveyTypesPayload {
  is_active: boolean
}

export type GetSurveyTypes = {
  payload: SurveyType
  type: Type.GET_SURVEY_TYPES
}

export function getSurveyTypes(payload: GetSurveyTypesPayload) {
  return {
    payload,
    type: Type.GET_SURVEY_TYPES
  }
}

export type GetSurveyTypesSuccess = {
  payload: SurveyType[]
  type: Type.GET_SURVEY_TYPES_SUCCESS
}

export function getSurveyTypesSuccess(payload: SurveyType[]) {
  return {
    payload,
    type: Type.GET_SURVEY_TYPES_SUCCESS
  }
}

export type GetSurveyTypesFail = {
  payload: { errors: AxiosError }
  type: Type.GET_SURVEY_TYPES_FAIL
}

export function getSurveyTypesFail(errors: AxiosError): GetSurveyTypesFail {
  return {
    payload: { errors },
    type: Type.GET_SURVEY_TYPES_FAIL
  }
}
