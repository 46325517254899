import { Action, Types } from "./partyRelationNuances.actions"
import Nuance from "app/models/nuance"

export type PartyRelationNuancesState = {
  errors: object | undefined
  loading: boolean
  nuance: Nuance | undefined
}

const initialState: PartyRelationNuancesState = {
  errors: undefined,
  loading: false,
  nuance: undefined
}

export default (
  state = initialState,
  action: Action
): PartyRelationNuancesState => {
  switch (action.type) {
    case Types.GET_PARTYRELATION_NUANCES:
      return {
        ...state,
        errors: undefined,
        loading: true
      }

    case Types.GET_PARTYRELATION_NUANCES_SUCCESS:
      return {
        ...state,
        loading: false,
        nuance: action.payload
      }

    case Types.GET_PARTYRELATION_NUANCES_NOT_FOUND:
      return initialState

    case Types.GET_PARTYRELATION_NUANCES_FAIL:
      return {
        ...state,
        loading: false
      }

    default:
      return state
  }
}
