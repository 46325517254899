import { SvgIcon, SvgIconProps } from "@material-ui/core"
import React from "react"

function FunToWorkWithLogo({
  viewBox = "0 0 515.34 538.08",
  ...props
}: SvgIconProps): JSX.Element {
  return (
    <SvgIcon viewBox={viewBox} {...props}>
      <g style={{ clipPath: "url(#clip-path)" }}>
        <rect
          style={{ fill: "#32a28d" }}
          x="277.02"
          y="254.89"
          width="238.32"
          height="58.88"
          rx="5.93"
        />
        <path
          style={{ fill: "#f8ad39" }}
          d="M148.55,243.41a77.11,77.11,0,1,0,77.1-77.1,77.1,77.1,0,0,0-77.1,77.1"
          transform="translate(-148.55 -35.6)"
        />
        <path
          style={{ fill: "#85addd" }}
          d="M522.22,42.62a24,24,0,0,0-33.91,0l-75.13,75.13a24,24,0,0,0,0,33.91l75.13,75.14a24,24,0,0,0,33.91,0l75.14-75.14a24,24,0,0,0,0-33.91Z"
          transform="translate(-148.55 -35.6)"
        />
        <path
          style={{ fill: "#487cbf" }}
          d="M525.88,449.93h0V383a5.61,5.61,0,0,0-5.61-5.61H466.75l-.61,72.52c0,34.17-26.74,61.87-59.73,61.87s-59.74-27.7-59.74-61.87V377.41H293.16a5.61,5.61,0,0,0-5.61,5.61v67c0,68.34,52.88,123.63,118.86,123.63S525.88,518.27,525.88,449.93Z"
          transform="translate(-148.55 -35.6)"
        />
      </g>
      <defs>
        <clipPath id="clip-path" transform="translate(-148.55 -35.6)">
          <rect
            style={{ fill: "none" }}
            x="77.48"
            y="-45.27"
            width="686.93"
            height="716.99"
          />
        </clipPath>
      </defs>
    </SvgIcon>
  )
}

export default FunToWorkWithLogo
