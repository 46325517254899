import css from "@emotion/css"

export const BaseFormControl = css`
  margin-bottom: 2.4rem;
`

export const MuiBaseInputStyle = {
  input: {
    fontSize: "1.4rem",
    height: "auto",
    lineHeight: "1.8rem",
    padding: "1rem"
  },
  root: {
    borderRadius: "2px",
    padding: 0
  }
}
