import { Action, Types } from "./message.actions"

export type MessageState = {
  message?: string
  values?: MessageStateValues
}

export type MessageStateValues = Record<string, string | number>

const initialState: MessageState = {}

export default (state = initialState, action: Action): MessageState => {
  switch (action.type) {
    case Types.SHOW:
      return {
        ...state,
        message: action.payload,
        values: action.values,
      }

    case Types.HIDE:
      const { message, values, ...rest } = state
      return rest

    default:
      return state
  }
}
