import { InputBaseClassKey } from "@material-ui/core"
import {
  CSSProperties,
  CreateCSSProperties
} from "@material-ui/core/styles/withStyles"
import { PropsFunc } from "../types"
import funToWorkWithTheme from "style/funToWorkWithTheme"

type InputBase = Partial<
  Record<
    InputBaseClassKey,
    | CSSProperties
    | CreateCSSProperties<{}>
    | PropsFunc<{}, CreateCSSProperties<{}>>
  >
>
export const inputBase: InputBase = {
  root: {
    "&$error .MuiInputBase-input": {
      borderColor: funToWorkWithTheme.colors.error
    }
  },
  input: {
    backgroundColor: funToWorkWithTheme.colors.secondaryLight,
    color: funToWorkWithTheme.colors.primary,
    fontSize: "2.0rem",
    lineHeight: "2.4rem",
    border: `2px solid ${funToWorkWithTheme.colors.primaryLight}`,
    padding: "1.6rem",
    "&:focus": {
      borderColor: funToWorkWithTheme.colors.secondary
    },
    "&.Mui-disabled": {
      backgroundColor: funToWorkWithTheme.colors.primaryExtraLight
    }
  },
  inputMultiline: {
    padding: "1.6rem",
    resize: "block"
  }
}
