import isPropValid from "@emotion/is-prop-valid"
import styled from "@emotion/styled"
import { BoxProps, Theme, Typography } from "@material-ui/core"
import { ISideBarNavigation } from "app/context/NavigationContext"
import Button from "components/Button"
import { NavLink } from "react-router-dom"
import { funToWorkWithTheme, IStyled } from "style/theme"
import { Link } from "../Link"

export const SidebarWrapper = styled.div<BoxProps>`
  flex: 1;
  display: flex;
  flex-direction: column;
  background: ${funToWorkWithTheme.colors.primary};
`

export const SidebarSection = styled.div`
  margin-bottom: 5.8rem;
`

export const SidebarFooter = styled.div`
  margin-top: auto;
`

export const SidebarNav = styled.nav`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
`

export const SidebarLink = styled(NavLink)`
  color: ${funToWorkWithTheme.colors.primary};
  font-size: 2rem;
  cursor: pointer;
  display: flex;
  text-decoration: none;
  font-weight: 600;
  padding: 2.5rem 3rem 2.5rem 3rem;
  border-bottom: 4px solid transparent;

  &.active {
    border-bottom: 4px solid ${funToWorkWithTheme.colors.accent4};
  }
`

export const SidebarNavigationTrigger = styled(Typography)<{
  theme: Theme
  isActive?: boolean
}>`
  color: ${funToWorkWithTheme.colors.secondaryLight};
  background: none;
  padding: 0.7rem 4rem 0.7rem 4rem;
  border: none;

  display: flex;
  align-items: center;
  text-align: left;

  ${({ isActive }) =>
    isActive
      ? `
      border-left: 10px solid ${funToWorkWithTheme.colors.secondary};
      color: ${funToWorkWithTheme.colors.white};
      background-color: ${funToWorkWithTheme.colors.primaryDark};
    `
      : `border-left: 10px solid transparent;`}

  &:focus {
    outline: none;
  }
  &:hover,
  &:focus {
    color: ${funToWorkWithTheme.colors.secondaryLight};
  }
`

export const SidebarLinkContentWrapper = styled.div<{
  theme: Theme
  isActive?: boolean
}>`
  ${({ isActive }) =>
    isActive
      ? `
      border-left: 10px solid ${funToWorkWithTheme.colors.secondary};
      color: ${funToWorkWithTheme.colors.white};
      background-color: ${funToWorkWithTheme.colors.primaryDark};
    `
      : `border-left: 10px solid transparent;`}
  padding: 0.7rem 4rem 0.7rem 4rem;
  border-left: 10px solid transparent;
`

export const SidebarSubTitle = styled(Typography, {
  shouldForwardProp: (prop) => prop !== "colorName"
})<{ theme: Theme; colorName: ISideBarNavigation["color"] }>`
  color: ${funToWorkWithTheme.colors.lightBrown};
  background: none;
  padding: 0;
  border: none;
  text-align: left;
  text-transform: uppercase;
`

export const StyledButton = styled(Button)`
  margin-top: ${({ theme }: IStyled) => theme.spacing(5)};
`

export const SidebarNavigationLink = styled(Link)`
  display: flex;
  border: unset;
  align-items: center;
  color: ${funToWorkWithTheme.colors.lightBrown};
  font-size: 2rem;
  text-decoration: none;
  cursor: pointer;
`
