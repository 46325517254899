import * as api from "app/api/events"
import { SagaIterator } from "redux-saga"
import { call, put, takeLatest } from "redux-saga/effects"
import * as actions from "./events.actions"

export function* postEventsFlow(
  action: actions.PostEventsAction
): SagaIterator {
  try {
    const { data } = action.payload
    yield call(api.postEvents, data)
    yield put(actions.postEventsSuccess())
  } catch (e) {
    yield put(actions.postEventsFail(e))
  }
}

export default function* eventsSaga(): SagaIterator {
  yield takeLatest(actions.Types.POST, postEventsFlow)
}
