import React from "react"
import { SvgIconProps, SvgIcon } from "@material-ui/core"

function Dashboard(props: SvgIconProps): JSX.Element {
  return (
    <SvgIcon viewBox="0 0 40 40" {...props}>
      <path
        fillRule="evenodd"
        d="M4 0C1.79086 0 0 1.79086 0 4V36C0 38.2091 1.79086 40 4 40H36C38.2091 40 40 38.2091 40 36V4C40 1.79086 38.2091 0 36 0H4ZM10 16C11.6569 16 13 14.6569 13 13C13 11.3431 11.6569 10 10 10C8.34315 10 7 11.3431 7 13C7 14.6569 8.34315 16 10 16ZM34 6C34 7.65685 32.6569 9 31 9C29.3431 9 28 7.65685 28 6C28 4.34315 29.3431 3 31 3C32.6569 3 34 4.34315 34 6ZM33 25C34.6569 25 36 23.6569 36 22C36 20.3431 34.6569 19 33 19C31.3431 19 30 20.3431 30 22C30 23.6569 31.3431 25 33 25ZM19 31C19 32.6569 17.6569 34 16 34C14.3431 34 13 32.6569 13 31C13 29.3431 14.3431 28 16 28C17.6569 28 19 29.3431 19 31ZM21 25C23.7614 25 26 22.7614 26 20C26 17.2386 23.7614 15 21 15C18.2386 15 16 17.2386 16 20C16 22.7614 18.2386 25 21 25Z"
      />
    </SvgIcon>
  )
}

export default Dashboard
