import { useSurveyTypes } from "./surveyTypes"
import { PermissionEnum } from "app/models/permission"
import { ROUTE_PATHS } from "app/routes/config"
import { FeaturesEnum } from "app/models/organisation"
import { ITopBarNavigation_new, ITopBarRoute_new } from "app/context/NavigationContext"
import { useSelector } from "react-redux"
import { getPreferredLanguage } from "app/redux/me/me.selectors"

type ISideBarLinks = Omit<ITopBarNavigation_new, "to" | "dividerLeft">

export const useSidebarLinkNew = (): ISideBarLinks[] => {
  const preferredLanguage = useSelector(getPreferredLanguage)
  const { surveyTypes } = useSurveyTypes()

  const teamsRoutes = surveyTypes.map(surveyType => {
    return {
      label: surveyType.name_i18n[preferredLanguage || "en"],
      permissions: [PermissionEnum.SURVEY_READ],
      to: ROUTE_PATHS.TEAM_SURVEYS.replace(":surveyTypeId", surveyType.id),
    } as ITopBarNavigation_new
  })

  return [
    {
      id: "dailycheck",
      routes: [
        {
          label: "route.realitycheck",
          to: `/reality-check/dashboard`,
        },
      ] as ITopBarRoute_new[],
      color: "accent2",
      label: "route.dashboard",
    },
    {
      id: "teams",
      label: "route.teams",
      permissions: [PermissionEnum.GROUP_READ],
      routes: [
        {
          label: "route.realitycheck",
          permissions: [PermissionEnum.DAILY_CHECK_READ],
          to: "/team/:id/reality-check/dashboard",
        }, ...teamsRoutes,
        {
          label: "route.members",
          permissions: [PermissionEnum.PEOPLE_READ],
          to: ROUTE_PATHS.TEAM_MEMBER_DASHBOARD,
          dividerLeft: true,
        },
      ] as ITopBarRoute_new[],
      color: "accent1",
      isSubTitle: true,
      isLink: false,
    },
    {
      id: "organisation",
      permissions: [PermissionEnum.PEOPLE_READ, PermissionEnum.FUNCTION_UPDATE],
      routes: [
        {
          label: "route.teams",
          id: "teams",
          permissions: [PermissionEnum.ORGANIZATION_ADMIN],
          to: ROUTE_PATHS.ORGANISATION_TEAMS,
        },
        {
          label: "route.people",
          id: "people",
          permissions: [PermissionEnum.ORGANIZATION_ADMIN],
          to: ROUTE_PATHS.ORGANISATION_PEOPLE,
        },
        {
          features: [FeaturesEnum.FEATURE_CUSTOM_FUNCTIONS],
          label: "route.functions",
          permissions: [PermissionEnum.SURVEY_READ],
          to: "/organisation/functions",
          id: "functions",
        },
      ],
      color: "accent2",
      label: "route.organisation",
    }
  ]
}
