import dailyCheckReducer from "./redux/dailyCheck/dailyCheck.reducer"
import eventsReducer from "./redux/events/events.reducer"
import functionsReducer from "./redux/functions/functions.reducer"
import groupsReducer from "./redux/groups/groups.reducer" 
import groupsTableReducer from "./redux/groupsTable/groupsTable.reducer"
import invitesReducer from "./redux/invites/invites.reducer"
import leadershipTableReducer from "./redux/leadershipTable/leadershipTable.reducer"
import meReducer from "./redux/me/me.reducer"
import messageReducer from "./redux/message/message.reducer"
import nodesReducer from "./redux/nodes/nodes.reducer"
import nuancesReducer from "./redux/nuances/nuances.reducer"
import organisationsReducer from "./redux/organisation/organisation.reducer"
import partyRelationNuancesReducer from "./redux/partyRelationNuances/partyRelationNuances.reducer"
import partyRelationsReducer from "./redux/partyRelations/partyRelations.reducer"
import partySettingsReducer from "./redux/partySettings/partySettings.reducer"
import peopleReducer from "./redux/people/people.reducer"
import rolesReducer from "./redux/roles/roles.reducer"
import surveyReducer from "./redux/survey/survey.reducer"
import surveyRequestReducer from "./redux/surveyRequest/surveyRequest.reducer"
import surveyJsReducer from "./redux/surveyjs/surveyjs.reducer"
import teamsReducer from "./redux/teams/teams.reducer"
import usersReducer from "./redux/users/users.reducer"
import surveyTypesReducer from "./redux/surveyTypes/surveyTypes.reducer"

export default {
  dailyCheck: dailyCheckReducer,
  events: eventsReducer,
  functions: functionsReducer,
  groups: groupsReducer,
  groupsTable: groupsTableReducer,
  leadershipTable: leadershipTableReducer,
  invites: invitesReducer,
  me: meReducer,
  message: messageReducer,
  nuances: nuancesReducer,
  organisations: organisationsReducer,
  partyRelationNuances: partyRelationNuancesReducer,
  partyRelations: partyRelationsReducer,
  partySettings: partySettingsReducer,
  roles: rolesReducer,
  survey: surveyReducer,
  surveyJs: surveyJsReducer,
  users: usersReducer,
  surveyRequest: surveyRequestReducer,
  teams: teamsReducer,
  people: peopleReducer,
  nodes: nodesReducer,
  surveyTypes: surveyTypesReducer,
}
