import React, { useState, useEffect, useContext, useRef } from "react"
import createAuth0Client from "@auth0/auth0-spa-js"
import Auth0Client from "@auth0/auth0-spa-js/dist/typings/Auth0Client"
import {
  PopupLoginOptions,
  getIdTokenClaimsOptions,
  IdToken,
  RedirectLoginOptions,
  GetTokenSilentlyOptions,
  GetTokenWithPopupOptions,
  LogoutOptions,
  RedirectLoginResult
} from "@auth0/auth0-spa-js/dist/typings/global"

interface IAuth0Context {
  isAuthenticated: boolean
  user: any
  loading: boolean
  popupOpen: boolean
  loginWithPopup(options: PopupLoginOptions): Promise<void>
  handleRedirectCallback(): Promise<void>
  getIdTokenClaims(o?: getIdTokenClaimsOptions): Promise<IdToken>
  loginWithRedirect(o: RedirectLoginOptions): Promise<void>
  getTokenSilently(o?: GetTokenSilentlyOptions): Promise<string | undefined>
  getTokenWithPopup(o?: GetTokenWithPopupOptions): Promise<string | undefined>
  logout(o?: LogoutOptions): void
}

interface IAuth0Provider {
  domain: string
  client_id: string
  redirect_uri: string
  audience?: string
  onRedirectCallback: (result?: RedirectLoginResult) => void
}

const DEFAULT_REDIRECT_CALLBACK = () =>
  window.history.replaceState({}, document.title, window.location.pathname)

export const Auth0Context = React.createContext<IAuth0Context | null>(null)
export const useAuth0 = () => useContext(Auth0Context)!
export const Auth0Provider: React.FC<IAuth0Provider> = ({
  children,
  onRedirectCallback = DEFAULT_REDIRECT_CALLBACK,
  ...initOptions
}) => {
  const options = useRef(initOptions)
  const [isAuthenticated, setIsAuthenticated] = useState<boolean>(false)
  const [user, setUser] = useState()
  const [auth0Client, setAuth0] = useState<Auth0Client>()
  const [loading, setLoading] = useState<boolean>(true)
  const [popupOpen, setPopupOpen] = useState<boolean>(false)

  useEffect(() => {
    const initAuth0 = async () => {
      const auth0FromHook = await createAuth0Client(options.current)
      setAuth0(auth0FromHook)

      if (
        window.location.search.includes("code=") &&
        window.location.search.includes("state=")
      ) {
        const { appState } = await auth0FromHook.handleRedirectCallback()
        onRedirectCallback(appState)
      }

      const authenticated = await auth0FromHook.isAuthenticated()

      setIsAuthenticated(authenticated)

      if (authenticated) {
        const authenticatedUser = await auth0FromHook.getUser()
        setUser(authenticatedUser)
      }

      setLoading(false)
    }
    initAuth0()
  }, [options, onRedirectCallback])

  const loginWithPopup = async (params = {}) => {
    setPopupOpen(true)
    try {
      await auth0Client!.loginWithPopup(params)
    } catch (error) {
      console.error(error)
    } finally {
      setPopupOpen(false)
    }
    const authenticatedUser = await auth0Client!.getUser()
    setUser(authenticatedUser)
    setIsAuthenticated(true)
  }

  const handleRedirectCallback = async () => {
    setLoading(true)
    await auth0Client!.handleRedirectCallback()
    const authenticatedUser = await auth0Client!.getUser()
    setLoading(false)
    setIsAuthenticated(true)
    setUser(authenticatedUser)
  }

  return (
    <Auth0Context.Provider
      value={{
        getIdTokenClaims: (...p) => auth0Client!.getIdTokenClaims(...p),
        getTokenSilently: (...p) => auth0Client!.getTokenSilently(...p),
        getTokenWithPopup: (...p) => auth0Client!.getTokenWithPopup(...p),
        handleRedirectCallback,
        isAuthenticated,
        loading,
        loginWithPopup,
        loginWithRedirect: (...p) => auth0Client!.loginWithRedirect(...p),
        logout: (...p) => auth0Client!.logout(...p),
        popupOpen,
        user
      }}
    >
      {children}
    </Auth0Context.Provider>
  )
}
