import { PartySettings, PartySettingsPayload } from "app/models/partySettings"

export enum Types {
  GET = "PARTY_SETTINGS_GET",
  GET_SUCCESS = "PARTY_SETTINGS_GET_SUCCESS",
  GET_FAIL = "PARTY_SETTINGS_GET_FAIL",
  PATCH = "PARTY_SETTINGS_PATCH",
  PATCH_SUCCESS = "PARTY_SETTINGS_PATCH_SUCCESS",
  PATCH_FAIL = "PARTY_SETTINGS_PATCH_FAIL"
}

export type Action =
  | GetPartySettingsAction
  | GetPartySettingsSuccessAction
  | GetPartySettingsFailAction
  | PatchPartySettingsAction
  | PatchPartySettingsSuccessAction
  | PatchPartySettingsFailAction

export type GetPartySettingsAction = {
  payload: PartySettingsPayload
  type: Types.GET
}

export type GetPartySettingsSuccessAction = {
  payload: PartySettings | undefined
  type: Types.GET_SUCCESS
}

export type GetPartySettingsFailAction = {
  payload: { errors: object }
  type: Types.GET_FAIL
}

export type PatchPartySettingsAction = {
  payload: PartySettingsPayload
  type: Types.PATCH
}

export type PatchPartySettingsSuccessAction = {
  payload: PartySettings | undefined
  type: Types.PATCH_SUCCESS
}

export type PatchPartySettingsFailAction = {
  payload: { errors: object }
  type: Types.PATCH_FAIL
}

export function getPartySettings(
  payload: PartySettingsPayload
): GetPartySettingsAction {
  return {
    payload,
    type: Types.GET
  }
}

export function getPartySettingsSuccess(
  payload: PartySettings | undefined
): GetPartySettingsSuccessAction {
  return {
    payload,
    type: Types.GET_SUCCESS
  }
}

export function getPartySettingsFail(
  errors: object
): GetPartySettingsFailAction {
  return {
    payload: { errors },
    type: Types.GET_FAIL
  }
}

export function patchPartySettings(
  payload: PartySettingsPayload
): PatchPartySettingsAction {
  return {
    payload,
    type: Types.PATCH
  }
}

export function patchPartySettingsSuccess(
  payload: PartySettings | undefined
): PatchPartySettingsSuccessAction {
  return {
    payload,
    type: Types.PATCH_SUCCESS
  }
}

export function patchPartySettingsFail(
  errors: object
): PatchPartySettingsFailAction {
  return {
    payload: { errors },
    type: Types.PATCH_FAIL
  }
}
