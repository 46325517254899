import { useLogout } from "app/hooks/logout"
import Translation from "components/Translation"
import React from "react"
import { Link } from "../Link"

const LogOutLink = () => {
  const { handleLogout } = useLogout()
  return (
    <Link component="button" onClick={handleLogout}>
      <Translation label="action.logout" />
    </Link>
  )
}

export default LogOutLink
