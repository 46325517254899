import React, { useEffect } from "react"
import { useSelector } from "react-redux"

import { useApi } from "app/hooks/api"
import { getOrganisations } from "app/redux/organisation/organisation.actions"
import * as organisationSelectors from "app/redux/organisation/organisation.selectors"
import { getPartyRelations } from "app/redux/partyRelations/partyRelations.actions"
import * as partyRelationSelector from "app/redux/partyRelations/partyRelations.selectors"
import { IRouteComponent } from "app/routes"
import { ROUTE_PATHS } from "app/routes/config"

import ClimateSurveyLayout from "./ClimateSurveyLayout"
import DailyCheckSurveyLayout from "./DailyCheckSurveyLayout"
import LeadershipSurveyLayout from "./LeadershipSurveyLayout"
import { CustomSurveyLayout } from "./CustomSurveyLayout"

export const SurveyLayout: React.FC<IRouteComponent> = ({
  children,
  pathKey,
}) => {
  const { callApi } = useApi()
  const organisation = useSelector(organisationSelectors.organisation)
  const isOrganisationLoading = useSelector(organisationSelectors.loading)
  const partyRelations = useSelector(partyRelationSelector.partyRelations)
  const isPartyRelationLoading = useSelector(partyRelationSelector.loading)
  useEffect(() => {
    if (isOrganisationLoading || organisation) return
    callApi(getOrganisations)
  }, [])

  useEffect(() => {
    if (isPartyRelationLoading || partyRelations?.length) return
    callApi(getPartyRelations)
  }, [])

  switch (pathKey) {
    case ROUTE_PATHS.LEADERSHIP_OVERVIEW:
      return <LeadershipSurveyLayout children={children} />
    case ROUTE_PATHS.CLIMATE_OVERVIEW:
      return <ClimateSurveyLayout children={children} />
    case ROUTE_PATHS.CUSTOM_OVERVIEW:
      return <CustomSurveyLayout children={children} />
    // DAILY_CHECK
    default:
      return <DailyCheckSurveyLayout children={children} />
  }
}

export default SurveyLayout
