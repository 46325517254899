import * as api from "app/api/roles"
import { SagaIterator } from "redux-saga"
import { call, put, takeLatest } from "redux-saga/effects"
import * as actions from "./roles.actions"

export function* getRolesFlow({
  payload
}: actions.GetRolesAction): SagaIterator {
  try {
    const response = yield call(api.getRoles, payload)
    yield put(actions.getRolesSuccess(response.data))
  } catch (e) {
    yield put(actions.getRolesFail(e))
  }
}

export default function* RolesSaga(): SagaIterator {
  yield takeLatest(actions.Types.GET, getRolesFlow)
}
