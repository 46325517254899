import React from "react"
import Avatar from "../Avatar"
import { ButtonBase, Grid } from "@material-ui/core"
import { AppHeaderWrapper, StyledEditProfileDrawer } from "./style"
import { useSelector } from "react-redux"
import * as selectors from "app/redux/me/me.selectors"
import LogOutLink from "../LogoutLink"

const AppHeader = () => {
  const [isDrawerVisible, setDrawerVisibility] = React.useState(false)
  const me = useSelector(selectors.me)

  const handleClick = () => {
    setDrawerVisibility(true)
  }

  const handleClose = () => {
    setDrawerVisibility(false)
  }

  return (
    <>
      <AppHeaderWrapper>
        {me && (
          <Grid container justify="space-between" alignItems="center">
            <Grid item>
              <ButtonBase disableRipple onClick={handleClick}>
                <Avatar />
              </ButtonBase>
            </Grid>
            <Grid item>
              <LogOutLink />
            </Grid>
          </Grid>
        )}
      </AppHeaderWrapper>

      <StyledEditProfileDrawer onClose={handleClose} open={isDrawerVisible} />
    </>
  )
}

export default AppHeader
