import { SvgIconComponent } from "@material-ui/icons"
import { ENV_FEATURE_FLAG } from "enums"
import React from "react"
import { useSelector } from "react-redux"
import { useParams } from "react-router"

import { dropdownLinks } from "app/components/Sidebar/config"
import { useOrganisation } from "app/hooks/organisation"
import { usePermission } from "app/hooks/permissions"
import { FeaturesEnum } from "app/models/organisation"
import { PermissionEnum } from "app/models/permission"
import { RoleNames } from "app/models/roles"
import * as partySelectors from "app/redux/partyRelations/partyRelations.selectors"
import { ROUTE_PATHS } from "app/routes/config"

import applyFeature from "utils/applyFeature"
import { useSidebarLinkNew } from "app/hooks/useSidebarLinksNew"

export interface ISideBarNavigation {
  id?: string
  icon?: SvgIconComponent | null
  label: string
  to?: string
  routes?: ISideBarRoute[]
  isActive?: boolean
  permissions?: PermissionEnum[]
  color?: "accent1" | "accent2" | "accent3" | "primary" | "secondary"
}

export interface ITopBarNavigation_new extends ISideBarNavigation {
  id?: string
  label: string
  to?: string
  routes?: ISideBarRoute[]
  isActive?: boolean
  permissions?: PermissionEnum[]
  color?: "accent1" | "accent2" | "accent3" | "primary" | "secondary"
  title?: string
  isLink?: boolean
  isSubTitle?: boolean
}

export interface ITopBarRoute_new {
  label: string
  to?: string
  routes?: ITopBarRoute_new[]
  features?: FeaturesEnum[]
  permissions?: PermissionEnum[]
  id?: string
  dividerLeft?: boolean
}

export interface ISideBarRoute extends ITopBarNavigation_new {
  label: string
  to?: string
  routes?: ISideBarRoute[]
  features?: FeaturesEnum[]
  permissions?: PermissionEnum[]
}

interface INavigationContext {
  navigation?: ITopBarNavigation_new[]
  setNavigation: React.Dispatch<React.SetStateAction<any>>
}

type FindTo = (arr: ISideBarRoute[]) => string | undefined
type MapRoutes = (arr: ISideBarRoute) => ISideBarRoute

export const findTo: FindTo = (routesArr: ISideBarRoute[]) => {
  for (const rootSubRoute of routesArr) {
    if (rootSubRoute.to) return rootSubRoute.to
    if (rootSubRoute.routes) {
      const rootSubRouteTo: string | undefined = findTo(rootSubRoute.routes)
      if (rootSubRouteTo) return rootSubRouteTo
    }
  }
}

export const NavigationContext = React.createContext<INavigationContext | null>(
  null,
)

export const useNavigation = () => React.useContext(NavigationContext)!
export const NavigationProvider: React.FC = ({ children }) => {
  const isNewNavigation = applyFeature(ENV_FEATURE_FLAG.IS_NEW_NAVIGATION)
  const sidebarLinksNew = useSidebarLinkNew()
  const { id: paramId } = useParams<{ id: string }>()

  const { hasPermissions } = usePermission()
  const { organisation } = useOrganisation()

  const activeFeatures = organisation?.active_features
  const [navigation, setNavigation] = React.useState(
    isNewNavigation ? sidebarLinksNew : dropdownLinks,
  )
  const partyRelations = useSelector(partySelectors.partyRelations)
  const partyRelation = partyRelations.find((p) => p.parent_id === paramId)

  const mapRoutes: MapRoutes = (r: ISideBarRoute) => {
    const { routes: subRoutes } = r

    const filteredSubRoutes: ISideBarRoute[] | undefined = subRoutes
      ? subRoutes.map(mapRoutes).filter(filterRoutes)
      : undefined

    return {
      ...r,
      ...(filteredSubRoutes ? { routes: filteredSubRoutes } : {}),
    }
  }

  const filterRoutes = (route: ISideBarRoute) => {
    const { features = [], permissions = [], routes } = route
    const hasPermission = (() => {
      if (!permissions.length) return true
      const getHasAccess = () => {
        if (route.to === ROUTE_PATHS.TEAM_MEMBER_DASHBOARD) {
          const isGroupAdmin = partyRelation?.roles?.find(
            (el) =>
              el === RoleNames.GROUP_ADMIN ||
              el === RoleNames.ORGANIZATION_ADMIN ||
              el === RoleNames.ORGANIZATION_OWNER,
          )

          return hasPermissions(permissions) && Boolean(isGroupAdmin)
        } else return hasPermissions(permissions)
      }

      return getHasAccess()
    })()

    const hasFeature = (() => {
      if (features.length === 0) return true
      return (
        activeFeatures &&
        features.every((f: FeaturesEnum) => {
          return activeFeatures[f]
        })
      )
    })()
    const hasNoSubRoutes = routes?.length === 0
    return hasFeature && hasPermission && !hasNoSubRoutes
  }

  const excludeFeaturesNavigation = navigation
    .map(mapRoutes)
    .filter(filterRoutes)
    .map((rootRoute) => {
      const { routes: rootRoutes = [] } = rootRoute
      const to = rootRoute.to ? rootRoute.to : findTo(rootRoutes)
      return { ...rootRoute, to }
    })

  return (
    <NavigationContext.Provider
      value={{ navigation: excludeFeaturesNavigation, setNavigation }}
    >
      {children}
    </NavigationContext.Provider>
  )
}
