import { SagaIterator } from "redux-saga"
import { call, put, takeLatest } from "redux-saga/effects"

import * as api from "app/api/organisation"

import * as actions from "./people.actions"

export function* getPeopleFlow(action: actions.GetPeopleAction): SagaIterator {
  try {
    const response = yield call(api.getOrganisationPeople, action.payload)
    yield put(actions.getPeopleSuccess(response.data))
  } catch (e) {
    yield put(actions.getPeopleFail(e))
  }
}

export default function* peopleSaga(): SagaIterator {
  yield takeLatest(actions.Types.GET, getPeopleFlow)
}
