import ColorUtil from "color"

import DailyCheck from "app/models/dailycheck"
import PersonFunction from "app/models/functions"
import { PermissionEnum } from "app/models/permission"

import { OptionType } from "components/TagSelect"

export function isAccessTokenExpired(accessToken: any): boolean {
  const expirationDate = accessToken.exp * 1000
  return isExpired(expirationDate)
}

export function isExpired(date: number, today = Date.now() - 2000) {
  return date <= today
}

export function parseToken(t: string) {
  if (typeof t !== "string") {
    return
  }
  const base64Url = t.split(".")[1]
  const base64 = base64Url.replace("-", "+").replace("_", "/")
  return JSON.parse(window.atob(base64))
}

export function nameInitials(firstname: string, lastname: string) {
  const short = firstname.replace(/(.{7}).+/, "$1")
  const initials = lastname.replace(/(.{1}).+/, "$1.")

  return `${short} ${initials}`
}

export const brighten = (color: string, brightness: number) => {
  return ColorUtil({
    h: ColorUtil(color).hsv().round().object().h,
    s: ColorUtil(color).hsv().round().object().s,
    v: ColorUtil(color).hsv().round().object().v + brightness,
  }).string()
}

export const opacify = (color: string, opacity: number) => {
  return ColorUtil([
    ColorUtil(color).red(),
    ColorUtil(color).green(),
    ColorUtil(color).blue(),
    opacity,
  ]).string()
}

export const colorMapping = {
  darkred: "#C00001",
  green: "#517E33",
  grey: "#E0E0E0",
  lightgreen: "#92D050",
  red: "#FF0100",
  yellow: "#FEC002",
}

export const getColor = (color: number) => {
  if (color === -1) {
    return colorMapping.grey
  }
  if (color === 0) {
    return colorMapping.green
  }
  if (color === 1) {
    return colorMapping.lightgreen
  }
  if (color > 1 && color <= 5) {
    return colorMapping.yellow
  }
  if (color > 5 && color < 20) {
    return colorMapping.red
  }
  if (color >= 20) {
    return colorMapping.darkred
  }
  return colorMapping.grey
}

export const getRemarksFromDailyCheck = (dailyCheck: DailyCheck) => {
  const remarks = Object.keys(dailyCheck).filter(
    (key) => key.includes("remark") && dailyCheck[key] !== null,
  )
  return remarks
}

export const functionToOption = (func: PersonFunction) => {
  const { name, id, color } = func
  return { value: name, label: name, id, color } as OptionType
}

export const sanitizeObject = (input: { [key: string]: any }) => {
  Object.keys(input).forEach((key: string) => {
    if (input[key] === null || input[key] === undefined || input[key] === "") {
      delete input[key]
    }
    input[key] = input[key]?.trim() || input[key]
  })
  return input
}

export const capitalizeFirstLetter = (string: string) => {
  if (typeof string === "string")
    return string?.charAt(0)?.toUpperCase() + string?.slice(1)
  return string
}

export const validatePermissions = (
  neededPermissions?: PermissionEnum[],
  userPermissions?: PermissionEnum[],
): boolean => {
  if (!neededPermissions) return true
  const acceptedPermissions = neededPermissions.filter((permission) =>
    userPermissions?.includes(permission),
  )
  return acceptedPermissions.length > 0
}
