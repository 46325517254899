import Nuance, {
  GetNuancesPayload,
  PutNuancesPayload,
  ArchiveNuancesPayload,
  DeleteNuancesPayload
} from "app/models/nuance"

export enum Types {
  ARCHIVE = "ARCHIVE",
  ARCHIVE_SUCCESS = "ARCHIVE_SUCCESS",
  ARCHIVE_FAIL = "ARCHIVE_FAIL",
  GET = "NUANCES_GET",
  GET_SUCCESS = "NUANCES_GET_SUCCESS",
  GET_FAIL = "NUANCES_GET_FAIL",
  PUT = "NUANCES_PUT",
  PUT_SUCCESS = "NUANCES_PUT_SUCCESS",
  PUT_FAIL = "NUANCES_PUT_FAIL",
  CLEAR_SUCCESS = "NUANCES_CLEAR_SUCCESS",
  DELETE = "NUANCES_DELETE",
  DELETE_SUCCESS = "NUANCES_DELETE_SUCCESS",
  DELETE_FAIL = "NUANCES_DELETE_FAIL"
}

export type Action =
  | GetNuancesAction
  | GetNuancesSuccessAction
  | GetNuancesFailAction
  | PutNuancesAction
  | PutNuancesSuccessAction
  | PutNuancesFailAction
  | ArchiveNuancesAction
  | ArchiveNuancesSuccessAction
  | ArchiveNuancesFailAction
  | DeleteNuancesAction
  | DeleteNuancesSuccessAction
  | DeleteNuancesFailAction
  | ClearSuccessMessageAction

export type GetNuancesAction = {
  payload: GetNuancesPayload
  type: Types.GET
}

export type GetNuancesSuccessAction = {
  payload: Nuance[]
  type: Types.GET_SUCCESS
}

export type GetNuancesFailAction = {
  payload: { errors: object }
  type: Types.GET_FAIL
}

export type PutNuancesAction = {
  payload: PutNuancesPayload
  type: Types.PUT
}

export type PutNuancesSuccessAction = {
  payload: Nuance
  type: Types.PUT_SUCCESS
}

export type PutNuancesFailAction = {
  payload: { errors: object }
  type: Types.PUT_FAIL
}

export type ArchiveNuancesAction = {
  payload: ArchiveNuancesPayload
  type: Types.ARCHIVE
}

export type ArchiveNuancesSuccessAction = {
  type: Types.ARCHIVE_SUCCESS
}

export type ArchiveNuancesFailAction = {
  payload: { errors: object }
  type: Types.ARCHIVE_FAIL
}

export type DeleteNuancesAction = {
  payload: DeleteNuancesPayload
  type: Types.DELETE
}

export type DeleteNuancesSuccessAction = {
  type: Types.DELETE_SUCCESS
}

export type DeleteNuancesFailAction = {
  payload: { errors: object }
  type: Types.DELETE_FAIL
}

export type ClearSuccessMessageAction = {
  type: Types.CLEAR_SUCCESS
}

export function getNuances(data: GetNuancesPayload): GetNuancesAction {
  return {
    payload: data,
    type: Types.GET
  }
}

export function getNuancesSuccess(data: Nuance[]): GetNuancesSuccessAction {
  return {
    payload: data,
    type: Types.GET_SUCCESS
  }
}

export function getNuancesFail(errors: object): GetNuancesFailAction {
  return {
    payload: { errors },
    type: Types.GET_FAIL
  }
}

export function putNuance(data: PutNuancesPayload): PutNuancesAction {
  return {
    payload: data,
    type: Types.PUT
  }
}

export function putNuanceSuccess(data: Nuance): PutNuancesSuccessAction {
  return {
    payload: data,
    type: Types.PUT_SUCCESS
  }
}

export function putNuanceFail(errors: object): PutNuancesFailAction {
  return {
    payload: { errors },
    type: Types.PUT_FAIL
  }
}

export function archiveNuance(
  data: ArchiveNuancesPayload
): ArchiveNuancesAction {
  return {
    payload: data,
    type: Types.ARCHIVE
  }
}

export function archiveNuanceSuccess(): ArchiveNuancesSuccessAction {
  return {
    type: Types.ARCHIVE_SUCCESS
  }
}

export function archiveNuanceFail(errors: object): ArchiveNuancesFailAction {
  return {
    payload: { errors },
    type: Types.ARCHIVE_FAIL
  }
}

export function deleteNuance(data: DeleteNuancesPayload): DeleteNuancesAction {
  return {
    payload: data,
    type: Types.DELETE
  }
}

export function deleteNuanceSuccess(): DeleteNuancesSuccessAction {
  return {
    type: Types.DELETE_SUCCESS
  }
}

export function deleteNuanceFail(errors: object): DeleteNuancesFailAction {
  return {
    payload: { errors },
    type: Types.DELETE_FAIL
  }
}

export function clearSuccessMessage(): ClearSuccessMessageAction {
  return {
    type: Types.CLEAR_SUCCESS
  }
}
