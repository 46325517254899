import { getSurveyProcessing, postSurveyAnswers } from "app/api/users"
import { SagaIterator } from "redux-saga"
import { call, put, takeLatest } from "redux-saga/effects"
import {
  GetSurveyQuestions,
  getSurveyQuestionsFail,
  getSurveyQuestionsSuccess,
  SurveyRequestActions,
  PostSurveyAnswers,
  postSurveyAnswersSuccess
} from "./surveyRequest.actions"

export function* getSurveyQuestionsFlow(
  action: GetSurveyQuestions
): SagaIterator {
  try {
    const surveyRequest = yield call(getSurveyProcessing, action.payload)
    yield put(getSurveyQuestionsSuccess(surveyRequest.data))
  } catch (e) {
    yield put(getSurveyQuestionsFail(e))
  }
}

export function* postSurveyQuestionsFlow(
  action: PostSurveyAnswers
): SagaIterator {
  const { payload } = action
  try {
    yield call(postSurveyAnswers, payload)
    yield put(postSurveyAnswersSuccess())
  } catch (e) {
    yield put(getSurveyQuestionsFail(e))
  }
}

export default function* surveyQuestionsSaga(): SagaIterator {
  yield takeLatest(
    SurveyRequestActions.GET_SURVEY_QUESTIONS,
    getSurveyQuestionsFlow
  )
  yield takeLatest(
    SurveyRequestActions.POST_SURVEY_ANSWERS,
    postSurveyQuestionsFlow
  )
}
