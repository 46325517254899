import { Action, Types } from "./functions.actions"
import PersonFunction from "app/models/functions"

export type FunctionsState = {
  errors: object
  loading: boolean
  functions: PersonFunction[]
  editedField: string | undefined
  showModal: boolean
  showDeleteModal: string
}

const initialState: FunctionsState = {
  editedField: undefined,
  errors: {},
  functions: [],
  loading: false,
  showDeleteModal: "",
  showModal: false
}

export default (state = initialState, action: Action): FunctionsState => {
  switch (action.type) {
    case Types.GET:
      return {
        ...state,
        loading: true
      }

    case Types.GET_SUCCESS:
      return {
        ...state,
        functions: action.payload,
        loading: false
      }

    case Types.GET_FAIL:
      return {
        ...state,
        errors: action.payload.errors,
        loading: false
      }

    case Types.PATCH:
      return state

    case Types.PATCH_SUCCESS:
      const { id, name } = action.payload

      return {
        ...state,
        editedField: undefined,
        functions: [
          ...state.functions!.map((f) => {
            return {
              ...f,
              ...(f.id === id ? { name } : {})
            }
          })
        ]
      }

    case Types.PATCH_FAIL:
      return {
        ...state,
        errors: action.payload.errors
      }

    case Types.POST:
      return {
        ...state,
        loading: true
      }

    case Types.POST_SUCCESS:
      const { organisation: _, ...newFunction } = action.payload

      return {
        ...state,
        functions: [...state.functions, ...[newFunction]],
        loading: false
      }

    case Types.POST_FAIL:
      return {
        ...state,
        errors: action.payload.errors,
        loading: false
      }

    case Types.DELETE:
      return {
        ...state,
        loading: true
      }

    case Types.DELETE_SUCCESS:
      const functionId = action.payload
      const newFunctions = state.functions.filter((f) => f.id !== functionId)

      return {
        ...state,
        functions: newFunctions,
        loading: false
      }

    case Types.DELETE_FAIL:
      return {
        ...state,
        errors: action.payload.errors,
        loading: false
      }

    case Types.SET_EDITED:
      return {
        ...state,
        editedField: action.payload.id
      }

    case Types.TOGGLE_MODAL:
      return {
        ...state,
        showModal: !state.showModal
      }

    case Types.TOGGLE_DELETE_MODAL:
      const modalId = action.payload
      const newId = modalId === state.showDeleteModal ? "" : modalId

      return {
        ...state,
        showDeleteModal: newId
      }

    default:
      return state
  }
}
