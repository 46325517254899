import { Action, Types } from "./organisation.actions"
import { Organisation } from "app/models/organisation"

export type OrganisationsState = {
  dispatched: boolean
  errors: object
  loading: boolean
  organisation: Organisation | Pick<Organisation, "name"> | undefined
}

const initialState: OrganisationsState = {
  dispatched: false,
  errors: {},
  loading: false,
  organisation: undefined
}

export default (state = initialState, action: Action): OrganisationsState => {
  switch (action.type) {
    case Types.POST:
      return {
        ...state,
        loading: true
      }

    case Types.POST_SUCCESS:
      return {
        ...state,
        loading: false
      }

    case Types.POST_FAIL:
      return {
        ...state,
        errors: action.payload.errors,
        loading: false
      }

    case Types.GET:
      return {
        ...state,
        loading: true
      }

    case Types.GET_SUCCESS:
      return {
        ...state,
        dispatched: true,
        loading: false,
        organisation: action.payload
      }

    case Types.GET_FAIL:
      return {
        ...state,
        dispatched: true,
        errors: action.payload.errors,
        loading: false
      }

    default:
      return state
  }
}
