import { AxiosError, AxiosRequestConfig, AxiosResponse } from "axios"
import { setToken, getToken } from "./utils"

export async function requestInterceptor(config: AxiosRequestConfig) {
  const {
    data: { token: requestToken }
  } = config

  if (requestToken) {
    setToken(requestToken)
    delete config.data.token
  }

  const token = getToken()

  if (token) {
    config.headers.Authorization = `Bearer ${token}`
  }

  return config
}

export function responseInterceptor(response: AxiosResponse) {
  return response
}

export function responseErrorInterceptor(error: AxiosError) {
  return Promise.reject(error)
}
