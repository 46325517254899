import { useCallback } from "react"
import { useApi } from "./api"
import { postEvents } from "app/redux/events/events.actions"
import { useAuth0 } from "app/context/AuthContext"

const logout = {
  business_event: "Vioras.Account.Logout",
  interaction_type: "bearer_token",
  payload: {},
  reference_type: "read",
  subject_type: "user"
}

const login = {
  business_event: "Vioras.Account.Login",
  interaction_type: "bearer_token",
  payload: {},
  reference_type: "read",
  subject_type: "user"
}

export const useEvents = () => {
  const { user } = useAuth0()
  const { callApi } = useApi()

  const logoutEvent = useCallback(() => {
    if (!user) return
    return callApi(postEvents, { ...logout, subject_id: user.sub })
  }, [callApi, user])

  const loginEvent = useCallback(() => {
    if (!user) return
    return callApi(postEvents, { ...login, subject_id: user.sub })
  }, [callApi, user])

  return { logoutEvent, loginEvent }
}
