export type TableActionTypes = {
  CHANGE_ADD: string
  CHANGE_ADDED_ROWS: string
  CHANGE_COLUMN_ORDER: string
  CHANGE_COLUMN_VISIBILITY: string
  CHANGE_COLUMN_WIDTHS: string
  CHANGE_EDITING_ROWIDS: string
  CHANGE_FILTERS: string
  CHANGE_ROW_CHANGES: string
  CHANGE_SELECTION: string
  CHANGE_SORTING: string
  CHANGE_ACTIVE_SORTING: string
  CHANGE_SUCCESS: string
  COMMIT_CHANGES: string
  CONFIRM_DELETE: string
  SET_DELETING_ROWIDS: string
  SET_VALIDATION_ERRORS: string
  RESET: string
}

export function actionTypesFor(tableName: string): TableActionTypes {
  return {
    CHANGE_ACTIVE_SORTING: `${tableName}_CHANGE_ACTIVE_SORTING`,
    CHANGE_ADD: `${tableName}_CHANGE_ADDED_ROWS`,
    CHANGE_ADDED_ROWS: `${tableName}_CHANGE_ROW_CHANGES`,
    CHANGE_COLUMN_ORDER: `${tableName}_CHANGE_COLUMN_ORDER`,
    CHANGE_COLUMN_VISIBILITY: `${tableName}_CHANGE_COLUMN_VISIBILITY`,
    CHANGE_COLUMN_WIDTHS: `${tableName}_CHANGE_COLUMN_WIDTHS`,
    CHANGE_EDITING_ROWIDS: `${tableName}_CHANGE_EDITING_ROWIDS`,
    CHANGE_FILTERS: `${tableName}_CHANGE_FILTERS`,
    CHANGE_ROW_CHANGES: `${tableName}_CHANGE_ROW_CHANGES`,
    CHANGE_SELECTION: `${tableName}_CHANGE_SELECTION`,
    CHANGE_SORTING: `${tableName}_CHANGE_SORTING`,
    CHANGE_SUCCESS: `${tableName}_CHANGE_SUCCESS`,
    COMMIT_CHANGES: `${tableName}_COMMIT_CHANGES`,
    CONFIRM_DELETE: `${tableName}_CONFIRM_DELETE`,
    RESET: `${tableName}_RESET`,
    SET_DELETING_ROWIDS: `${tableName}_SET_DELETING_ROWIDS`,
    SET_VALIDATION_ERRORS: `${tableName}_SET_VALIDATION_ERRORS`
  }
}

export type TableActionResult<P = undefined> = {
  type: string
  payload?: P
}

export type Actions = {
  changeAddedRows: (addedRows: any[]) => TableActionResult<{ addedRows: any[] }>
  changeColumnOrder: (
    columnOrder: any[]
  ) => TableActionResult<{ columnOrder: any[] }>
  changeEditingRowIds: (
    editingRowIds: any[]
  ) => TableActionResult<{ editingRowIds: any[] }>
  changeRowChanges: (
    rowChanges: any[]
  ) => TableActionResult<{ rowChanges: any[] }>
  changeSelection: (
    selectedRows: any[]
  ) => TableActionResult<{ selectedRows: any[] }>
  changeSorting: (sorting: string[]) => TableActionResult<{ sorting: string[] }>
  changeSuccess: () => TableActionResult
  reset: () => TableActionResult
}

export function actionsFor(tableName: string): Actions {
  const types = actionTypesFor(tableName)
  return {
    changeAddedRows: (addedRows: any[]) => ({
      payload: { addedRows },
      type: types.CHANGE_ADDED_ROWS
    }),
    changeColumnOrder: (columnOrder: any[]) => ({
      payload: { columnOrder },
      type: types.CHANGE_COLUMN_ORDER
    }),
    changeEditingRowIds: (editingRowIds: any[]) => ({
      payload: { editingRowIds },
      type: types.CHANGE_EDITING_ROWIDS
    }),
    changeRowChanges: (rowChanges: any[]) => ({
      payload: { rowChanges },
      type: types.CHANGE_ROW_CHANGES
    }),
    changeSelection: (selectedRows: any[]) => ({
      payload: { selectedRows },
      type: types.CHANGE_SELECTION
    }),
    changeSorting: (sorting: string[]) => ({
      payload: { sorting },
      type: types.CHANGE_SORTING
    }),
    changeSuccess: () => ({ type: types.CHANGE_SUCCESS }),
    reset: () => ({
      type: types.RESET
    })
  }
}
