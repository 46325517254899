import { TableCellClassKey } from "@material-ui/core"
import {
  CSSProperties,
  CreateCSSProperties
} from "@material-ui/core/styles/withStyles"
import { PropsFunc } from "style/muiTheme/types"
import funToWorkWithTheme from "style/funToWorkWithTheme"

type TableCell = Partial<
  Record<
    TableCellClassKey,
    | CSSProperties
    | CreateCSSProperties<{}>
    | PropsFunc<{}, CreateCSSProperties<{}>>
  >
>
export const tableCell: TableCell = {
  head: {
    fontSize: "1.4rem",
    fontWeight: "bold",
    lineHeight: "1.7rem",
    borderColor: funToWorkWithTheme.colors.primaryExtraLight
  },
  body: {
    fontSize: "2.0rem"
  },
  root: {
    paddingLeft: "0rem"
  }
}
