import styled from "@emotion/styled"
import { ButtonBase, SwipeableDrawer } from "@material-ui/core"
import { IStyled, viorasTheme } from "style/theme"

import Close from "assets/icons/Close"

export const StyledSwipeableDrawer = styled(SwipeableDrawer)`
  .MuiDrawer-paper {
    width: 64rem;
  }
`

export const EditProfileHeaderWrapper = styled.header`
  text-align: center;
  background-color: ${viorasTheme.colors.white};
  padding: ${({ theme }: IStyled) =>
    `${theme.spacing(4.3)} ${theme.spacing(4.3)} 0 ${theme.spacing(4.3)}`};
`

export const CloseButton = styled(ButtonBase)`
  display: inline-flex;
  flex-direction: column;
  color: ${viorasTheme.colors.black};
  padding: ${({ theme }: IStyled) => `${theme.spacing(1)} ${theme.spacing(2)}`};
`

export const CloseButtonIcon = styled(Close)`
  margin-bottom: ${({ theme }: IStyled) => theme.spacing(1)};
`

export const AvatarWrapper = styled.div`
  margin-bottom: ${({ theme }: IStyled) => theme.spacing(2)};
`

export const EditProfileForm = styled.form`
  padding: ${({ theme }: IStyled) =>
    `${theme.spacing(3)} ${theme.spacing(2)} ${theme.spacing(
      6,
    )} ${theme.spacing(2)}`};
`

export const EditProfileFormSection = styled.fieldset`
  padding: ${({ theme }: IStyled) => `${theme.spacing(2.75)}`};
  border: 0;
  box-shadow: -1px -2px 2px rgba(0, 0, 0, 0.05), 0px 4px 4px rgba(0, 0, 0, 0.15);
`
