import React from "react"
import { useDispatch, useSelector } from "react-redux"

import * as actions from "app/redux/message/message.actions"
import * as selectors from "app/redux/message/message.selectors"

import Snackbar from "components/Snackbar"

const TIME_TO_SHOW_NOTIFICATION = 8000 // 8s

const AppNotifications = () => {
  const dispatch = useDispatch()
  const message = useSelector(selectors.message)
  const values = useSelector(selectors.values)

  function toggleMessage() {
    dispatch(actions.hideMessage())
  }

  if (!message) {
    return <></>
  }

  setTimeout(() => {
    toggleMessage()
  }, TIME_TO_SHOW_NOTIFICATION)

  return (
    <Snackbar message={{ label: message, values }} onClose={toggleMessage} />
  )
}

export default AppNotifications
