import { actionTypesFor } from "./table.actions"

export type TableState = {
  columnOrder: any[]
  defaultSorting: string[]
  sorting: string[]
  rowChanges: object
  addedRows: any[]
  editingRowIds: string[]
  deletingRowIds: any[]
  errors: object
  hiddenColumns: any[]
  columnWidths: any[]
  filters: any[]
  selectedRows: any[]
  grouping: any[]
}

export const DEFAULT_STATE: TableState = {
  addedRows: [],
  columnOrder: [],
  columnWidths: [],
  defaultSorting: [],
  deletingRowIds: [],
  editingRowIds: [],
  errors: {},
  filters: [],
  grouping: [],
  hiddenColumns: [],
  rowChanges: {},
  selectedRows: [],
  sorting: []
}

const createTableReducer = (
  tableName: string = "",
  defaultRow: object
): ((state: TableState, action: any) => TableState) => {
  return (state = DEFAULT_STATE, action: any) => {
    const {
      CHANGE_COLUMN_ORDER,
      CHANGE_SORTING,
      CHANGE_ROW_CHANGES,
      CHANGE_ADDED_ROWS,
      CHANGE_SUCCESS,
      CHANGE_EDITING_ROWIDS,
      SET_DELETING_ROWIDS,
      SET_VALIDATION_ERRORS,
      CHANGE_COLUMN_VISIBILITY,
      CHANGE_COLUMN_WIDTHS,
      CHANGE_FILTERS,
      CHANGE_SELECTION,
      RESET
    } = actionTypesFor(tableName)

    if (!action.type || !action.payload) {
      return state
    }

    switch (action.type) {
      case CHANGE_COLUMN_ORDER: {
        return { ...state, columnOrder: action.payload.columnOrder }
      }

      case CHANGE_SORTING: {
        return {
          ...state,
          sorting: action.payload.sorting
        }
      }

      case CHANGE_ROW_CHANGES: {
        return { ...state, rowChanges: action.payload.rowChanges }
      }

      case CHANGE_ADDED_ROWS: {
        return {
          ...state,
          addedRows: action.payload.addedRows || [defaultRow],
          errors: action.payload.addedRows.length > 0 ? state.errors : {}
        }
      }

      case CHANGE_SUCCESS: {
        return {
          ...state,
          addedRows: [],
          editingRowIds: []
        }
      }

      case CHANGE_EDITING_ROWIDS: {
        return {
          ...state,
          editingRowIds: action.payload.editingRowIds.filter(
            (rowId: string) => !state.editingRowIds.includes(rowId)
          ),
          errors: action.payload.editingRowIds.length > 0 ? state.errors : {}
        }
      }

      case SET_DELETING_ROWIDS: {
        return {
          ...state,
          deletingRowIds: action.payload.deletingRowIds || state.deletingRowIds
        }
      }

      case SET_VALIDATION_ERRORS: {
        return { ...state, errors: action.payload.errors }
      }

      case CHANGE_COLUMN_VISIBILITY: {
        return { ...state, hiddenColumns: action.payload.hiddenColumns }
      }

      case CHANGE_COLUMN_WIDTHS: {
        return { ...state, columnWidths: action.payload.columnWidths }
      }

      case CHANGE_FILTERS: {
        return { ...state, filters: action.payload.filters }
      }

      case CHANGE_SELECTION: {
        return { ...state, selectedRows: action.payload.selectedRows }
      }

      case RESET: {
        return { ...state, sorting: [] }
      }

      default:
        return state
    }
  }
}

export default createTableReducer
