import * as api from "app/api/organisation"
import { SagaIterator } from "redux-saga"
import { call, put, takeLatest } from "redux-saga/effects"
import * as actions from "./functions.actions"

export function* getFunctionsFlow({
  payload
}: actions.GetFunctionsAction): SagaIterator {
  try {
    const response = yield call(api.getFunctions, payload)
    yield put(actions.getFunctionsSuccess(response.data))
  } catch (e) {
    yield put(actions.getFunctionsFail(e))
  }
}

export function* patchFunctionsFlow(
  action: actions.PatchFunctionsAction
): SagaIterator {
  try {
    yield call(api.patchFunctions, action.payload)
    yield put(actions.patchFunctionsSuccess(action.payload))
  } catch (e) {
    yield put(actions.patchFunctionsFail(e))
  }
}

export function* postFunctionFlow({
  payload
}: actions.PostFunctionsAction): SagaIterator {
  try {
    const response = yield call(api.postFunction, payload)
    yield put(actions.postFunctionSuccess(response.data))
    yield put(actions.toggleFunctionModal())
  } catch (e) {
    yield put(actions.postFunctionFail(e))
  }
}

export function* deleteFunctionsFlow({
  payload
}: actions.DeleteFunctionsAction): SagaIterator {
  const { id } = payload
  try {
    yield call(api.deleteFunction, payload)
    yield put(actions.deleteFunctionSuccess(id))
    yield put(actions.toggleFunctionDeleteModal(id))
  } catch (e) {
    yield put(actions.postFunctionFail(e))
  }
}

export default function* FunctionsSaga(): SagaIterator {
  yield takeLatest(actions.Types.GET, getFunctionsFlow)
  yield takeLatest(actions.Types.POST, postFunctionFlow)
  yield takeLatest(actions.Types.PATCH, patchFunctionsFlow)
  yield takeLatest(actions.Types.DELETE, deleteFunctionsFlow)
}
