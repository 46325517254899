import { AxiosError } from "axios"
import { SagaIterator } from "redux-saga"
import { call, put, takeLatest } from "redux-saga/effects"

import { getMyNodes } from "app/api/me"
import { getTeamNodes } from "app/api/users"

import * as actions from "./nodes.actions"

export function* getMyNodesFlow(
  action: actions.GetMyNodesAction,
): SagaIterator {
  try {
    const response = yield call(getMyNodes, action.payload)
    yield put(actions.getMyNodesSuccess(response.data))
  } catch (e) {
    yield put(actions.getMyNodesFail(e as AxiosError))
  }
}

export function* getTeamNodesFlow(
  action: actions.GetTeamNodesAction,
): SagaIterator {
  try {
    const response = yield call(getTeamNodes, action.payload)
    yield put(actions.getTeamNodesSuccess(response.data))
  } catch (e) {
    yield put(actions.getTeamNodesFail(e as AxiosError))
  }
}

export function* setActiveNodesIdFlow(
  action: actions.SetActiveNodesIdAction,
): SagaIterator {
  yield put(actions.setActiveNodesIdSuccess({ id: action.payload.id }))
}

export default function* nodesSaga(): SagaIterator {
  yield takeLatest(actions.Types.GET_MY_NODES, getMyNodesFlow)
  yield takeLatest(actions.Types.GET_TEAM_NODES, getTeamNodesFlow)
  yield takeLatest(actions.Types.SET_ACTIVE_ID, setActiveNodesIdFlow)
}
