import { getSurveyRequestPreferredLanguage } from "app/redux/surveyRequest/surveyRequest.selectors"
import moment from "moment"
import React, { ReactChild, ReactChildren, useEffect } from "react"
import { IntlProvider } from "react-intl"
import { useSelector } from "react-redux"
import { locale, translations, Locales } from "../../../i18n/config"
import { getPreferredLanguage } from "../../redux/me/me.selectors"

type Props = {
  children: ReactChild | ReactChildren
}

const ConnectedIntlProvider = ({ children }: Props): JSX.Element => {
  const preferredLanguage = useSelector(getPreferredLanguage)
  const preferredSurveyRequestLanguage = useSelector(
    getSurveyRequestPreferredLanguage
  )
  const activeLanguage =
    preferredLanguage || preferredSurveyRequestLanguage || locale

  useEffect(() => {
    moment.locale(activeLanguage)
  }, [activeLanguage])

  return (
    <IntlProvider
      messages={translations[activeLanguage as Locales]}
      locale={activeLanguage}
    >
      {children}
    </IntlProvider>
  )
}

export default ConnectedIntlProvider
