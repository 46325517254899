import { AxiosPromise } from "axios"
import { usersApi } from "./api"
import { PartySettingsPayload } from "app/models/partySettings"

export const getPartySettings = (
  data: Partial<PartySettingsPayload>
): AxiosPromise =>
  usersApi(`/parties/${data.party_id}/party_settings`, {
    data,
    method: "GET"
  })

export const patchPartySettings = (
  data: Partial<PartySettingsPayload>
): AxiosPromise =>
  usersApi(
    `/parties/${data.party_id}/party_settings/${data.party_setting_id}`,
    {
      data,
      method: "PATCH"
    }
  )
