import React, { memo } from "react"
import { useSelector } from "react-redux"

import * as selectors from "app/redux/me/me.selectors"

import { getInitials } from "utils/getInitials"

import {
  AvatarInitials,
  AvatarInitialsWrapper,
  AvatarText,
  AvatarWrapper,
} from "./style"

interface IAvatarUser {
  name?: string
  email?: string
}

interface IAvatar {
  user?: IAvatarUser
  size?: number
  showName?: boolean
}

interface IAvatarComponent
  extends Required<Pick<IAvatar, "user">>,
    Omit<IAvatar, "user"> {}

const AvatarComponent: React.FC<IAvatarComponent> = ({
  user,
  size,
  showName = true,
  ...rest
}) => {
  const userInitials = user?.name ? getInitials(user.name) : ""

  return (
    <AvatarWrapper container alignItems="center" {...rest}>
      <AvatarInitialsWrapper>
        <AvatarInitials>{userInitials}</AvatarInitials>
      </AvatarInitialsWrapper>
      {showName && <AvatarText variant="overline">{user.name}</AvatarText>}
    </AvatarWrapper>
  )
}

const CurrentUserAvatar: React.FC<{ size?: number }> = (props) => {
  const me = useSelector(selectors.me)
  const meLoading = useSelector(selectors.loading)
  if (meLoading || !me) return null

  const { first_name, last_name, email } = me
  const currentUser = {
    email,
    name: `${first_name} ${last_name}`,
  }

  return <AvatarComponent size={props.size} user={currentUser} {...props} />
}

const Avatar: React.FC<IAvatar> = ({ user, size, ...rest }) => {
  if (user) {
    return <AvatarComponent size={size} user={user} {...rest} />
  }

  return <CurrentUserAvatar size={size} {...rest} />
}

export default memo(Avatar)
