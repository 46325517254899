import { InputLabelClassKey } from "@material-ui/core"
import { CSSProperties, CreateCSSProperties } from "@material-ui/core/styles/withStyles"
import { PropsFunc } from "../types"
import funToWorkWithTheme from "style/funToWorkWithTheme"

type InputLabel = Partial<
  Record<
    InputLabelClassKey,
    | CSSProperties
    | CreateCSSProperties<{}>
    | PropsFunc<{}, CreateCSSProperties<{}>>
  >
>
export const inputLabel: InputLabel = {
  root: {
    fontWeight: "bold",
    fontSize: "1.4rem",
    lineHeight: "1.7rem",
    textTransform: "uppercase",
    color: funToWorkWithTheme.colors.primary,
    paddingBottom: "1.6rem",
    "&.Mui-error": {
      fontWeight: "normal",
      textTransform: "unset"
    }
  }
}
