import { Box } from "@material-ui/core"
import { IRouteComponent } from "app/routes"
import FunToWorkWithLogo from "assets/icons/FunToWorkWithLogo"
import React from "react"
import { LogoContainer, SurveyContent, CustomSurveyWrapper } from "../style"

export const CustomSurveyLayout: React.FC<IRouteComponent> = ({
  children
}) => {
  return (
    <CustomSurveyWrapper>
      <SurveyContent elevation={0}>
        <Box p={5}>{children}</Box>
      </SurveyContent>

      <LogoContainer>
        <FunToWorkWithLogo fontSize="inherit" />
      </LogoContainer>
    </CustomSurveyWrapper>
  )
}

export default CustomSurveyLayout
