import { SagaIterator } from "redux-saga"
import { all, call, put, select, takeLatest } from "redux-saga/effects"

import * as api from "app/api/users"

import { toggleInvitationModal } from "../invites/invites.actions"
import * as meActions from "../me/me.actions"
import { getPartyRelations } from "../partyRelations/partyRelations.actions"
import { getTeams } from "../teams/teams.actions"
import * as actions from "./groups.actions"
import * as selectors from "./groups.selectors"

export function* getGroupsFlow(action: actions.GetGroupsAction): SagaIterator {
  try {
    const response = yield call(api.getPartyRelationChildren, action.payload)
    yield put(actions.getGroupsSuccess(response.data))
  } catch (e) {
    if (e.response.status === 403) {
      yield put(actions.getGroupsSuccess(undefined))
    }
    yield put(actions.getGroupsFail(e))
  }
}

export function* refreshGroupsFlow(
  action: actions.RefreshGroupsAction,
): SagaIterator {
  try {
    const { id: currentGroupId } = yield select(selectors.groups)
    const parameters = yield select(selectors.parameters)
    const response = yield call(api.getPartyRelationChildren, {
      id: currentGroupId,
      token: action.payload.token,
      ...parameters,
    })

    yield put(actions.getGroupsSuccess(response.data))
  } catch (e) {
    yield put(actions.getGroupsFail(e))
  }
}

export function* patchGroupsFlow(
  action: actions.PatchGroupsAction,
): SagaIterator {
  try {
    yield call(api.patchGroups, action.payload)
    yield put(actions.patchGroupsSuccess(action.payload))
  } catch (e) {
    yield put(actions.patchGroupsFail(e))
  }
}

export function* postGroupsFlow(
  action: actions.PostGroupsAction,
): SagaIterator {
  try {
    const { data, token, id } = action.payload
    delete action.payload.token
    yield all(
      Object.values(data!).map((group) =>
        call(api.postGroups, { ...group, token, parent_id: id }),
      ),
    )
    yield put(actions.postGroupsSuccess())
    yield put(actions.getGroups({ id, sort: ["name=asc"] }))
    yield put(meActions.getMyGroups({ sort: ["name=asc"], token }))
    //getTeams is for the new teamsoverview, getGroups should be deleted when we swap on production
    yield put(getTeams({ group_id: id }))
    yield put(getPartyRelations({}))
    yield put(toggleInvitationModal())
  } catch (e) {
    yield put(actions.postGroupsFail(e))
  }
}

export function* getPartyParentsFlow(
  action: actions.GetPartyParentsAction,
): SagaIterator {
  try {
    const partyParents = yield call(api.getPartyParents, action.payload)
    yield put(actions.getPartyParentsSuccess(partyParents.data))
  } catch (e) {
    yield put(actions.getPartyParentsFail(e))
  }
}

export default function* groupsSaga(): SagaIterator {
  yield takeLatest(actions.Types.GET, getGroupsFlow)
  yield takeLatest(actions.Types.REFRESH_GROUPS, refreshGroupsFlow)
  yield takeLatest(actions.Types.PATCH, patchGroupsFlow)
  yield takeLatest(actions.Types.POST, postGroupsFlow)
  yield takeLatest(actions.Types.GET_PARENTS, getPartyParentsFlow)
}
