import { createMuiTheme } from "@material-ui/core"
import { funToWorkWithTheme } from "style/theme"
import { button } from "./button"
import { buttonGroup } from "./buttonGroup"
import { chip } from "./chip"
import { iconButton } from "./iconButton"
import { inputBase } from "./inputBase"
import { inputLabel } from "./inputLabel"
import { link } from "./link"
import oldTheme from "./oldTheme"
import { paper } from "./paper"
import { select } from "./select"
import { table } from "./table"
import { tableCell } from "./table/cell"
import { typography } from "./typography"
import { cy } from "./typography/fonts"

const theme = createMuiTheme({
  ...oldTheme,

  palette: {
    ...oldTheme.palette,
    primary: {
      main: funToWorkWithTheme.colors.primary,
      light: funToWorkWithTheme.colors.primaryLight
    },
    secondary: {
      main: funToWorkWithTheme.colors.secondary,
      light: funToWorkWithTheme.colors.secondaryLight
    },
    text: {
      primary: funToWorkWithTheme.colors.primary,
      secondary: funToWorkWithTheme.colors.accent1
    }
  },

  typography,

  overrides: {
    MuiButton: button,
    MuiButtonGroup: buttonGroup,
    MuiChip: chip,
    MuiIconButton: iconButton,
    MuiInputBase: inputBase,
    MuiInputLabel: inputLabel,
    MuiTypography: {
      gutterBottom: {
        marginBottom: "3.2rem"
      }
    },
    MuiLink: link,
    MuiTable: table,
    MuiTableCell: tableCell,
    MuiPaper: paper,
    MuiSelect: select,
    MuiCssBaseline: {
      "@global": {
        "@font-face": [cy as any]
      }
    }
  }
})

export default theme
