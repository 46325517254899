import React from "react"
import { BoxProps } from "@material-ui/core"
import { StyledDivider } from "./style"

type Props = BoxProps & { backgroundColor?: string }

const Divider = (props: Props) => {
  return <StyledDivider {...props} />
}

export default Divider
