import React from "react"
import { SvgIconProps, SvgIcon } from "@material-ui/core"

function ChevronRight(props: SvgIconProps): JSX.Element {
  return (
    <SvgIcon viewBox="0 0 10 16" {...props}>
      <path d="M2.27596 15.6093L9.88529 7.99996L2.27596 0.390625L0.390625 2.27596L6.11463 7.99996L0.390625 13.724L2.27596 15.6093Z" />
    </SvgIcon>
  )
}

export default ChevronRight
