import DailyCheck, { DailyCheckCommentsResponse } from "app/models/dailycheck"

import { Action, Types } from "./dailyCheck.actions"

export type DailyCheckState = {
  comments: DailyCheckCommentsResponse
  errors: object
  loading: boolean
  previousDailyCheck: DailyCheck | undefined
  recentComments: DailyCheckCommentsResponse | undefined
}

const initialState: DailyCheckState = {
  comments: {
    data: [],
    meta: {
      total_count: 0,
      total_pages: 0,
    },
  },
  errors: {},
  loading: false,
  previousDailyCheck: undefined,
  recentComments: undefined,
}

export default (state = initialState, action: Action): DailyCheckState => {
  switch (action.type) {
    case Types.GET:
      return {
        ...state,
        loading: true,
      }

    case Types.GET_SUCCESS:
      return {
        ...state,
        loading: false,
        previousDailyCheck: action.payload,
      }

    case Types.GET_FAIL:
      return {
        ...state,
        errors: action.payload.errors,
        loading: false,
      }

    case Types.GET_RECENT_COMMENTS:
      return {
        ...state,
        recentComments: undefined,
        loading: true,
      }

    case Types.GET_RECENT_COMMENTS_SUCCESS:
      return {
        ...state,
        loading: false,
        recentComments: action.payload,
      }

    case Types.GET_RECENT_COMMENTS_FAIL:
      return {
        ...state,
        errors: action.payload.errors,
        loading: false,
      }

    case Types.GET_ALL_COMMENTS:
      return {
        ...state,
        loading: true,
      }

    case Types.GET_ALL_COMMENTS_SUCCESS:
      const { data, meta } = action.payload
      return {
        ...state,
        comments: {
          data: [...state.comments.data, ...data],
          meta,
        },
        loading: false,
      }

    case Types.GET_ALL_COMMENTS_FAIL:
      return {
        ...state,
        errors: action.payload.errors,
        loading: false,
      }

    case Types.POST:
      return {
        ...state,
        loading: true,
      }

    case Types.POST_SUCCESS:
      return {
        ...state,
        loading: false,
      }

    case Types.POST_FAIL:
      return {
        ...state,
        errors: action.payload.errors,
        loading: false,
      }
    case Types.CLEAR_DAILY_CHECK:
      return {
        ...state,
        ...initialState,
      }

    default:
      return state
  }
}
