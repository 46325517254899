import { useAuth0 } from "app/context/AuthContext"
import { useEvents } from "./events"

export const useLogout = () => {
  const { logout } = useAuth0()
  const { logoutEvent } = useEvents()

  const handleLogout = () => {
    logoutEvent()
    return logout({
      returnTo: window.location.origin
    })
  }

  return { handleLogout }
}
