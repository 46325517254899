import * as api from "app/api/nuance"
import { SagaIterator } from "redux-saga"
import { call, put, takeLatest } from "redux-saga/effects"
import * as actions from "./partyRelationNuances.actions"

export function* getPartyRelationNuancesFlow({
  payload
}: actions.GetPartyRelationNuancesAction): SagaIterator {
  try {
    const response = yield call(api.getPartyRelationNuances, payload)
    yield put(actions.getPartyRelationNuancesSuccess(response.data))
  } catch (e) {
    if (e.response.status === 404) {
      yield put(actions.getPartyRelationNuancesNotFound())
      return
    }

    yield put(actions.getPartyRelationNuancesFail(e))
  }
}

export default function* partyRelationNuancesSaga(): SagaIterator {
  yield takeLatest(
    actions.Types.GET_PARTYRELATION_NUANCES,
    getPartyRelationNuancesFlow
  )
}
