import { MessageStateValues } from "./message.reducer"

export enum Types {
  SHOW = "MESSAGE_SHOW",
  HIDE = "MESSAGE_HIDE",
}

export type Action = ShowMessageAction | HideMessageAction

export type ShowMessageAction = {
  payload: string
  values?: MessageStateValues
  type: Types.SHOW
}

export type HideMessageAction = {
  type: Types.HIDE
}

export function showMessage(
  payload: string,
  values?: MessageStateValues,
): ShowMessageAction {
  return {
    payload,
    values,
    type: Types.SHOW,
  }
}
export function hideMessage(): HideMessageAction {
  return {
    type: Types.HIDE,
  }
}
