import { LanguageEnum } from "app/models/invite"
import { RoleNames } from "app/models/roles"
import { Actions, SurveyRequestActions } from "./surveyRequest.actions"
import { TranslatedField } from "app/models/survey"
import { Question } from "app/models/question"
import { ActiveFeaturesObj } from "./../../models/organisation"

export type SurveyQuestionsState = {
  loading: boolean
  errors: object
  data: {
    id: string
    title: string
    role: RoleNames.TEAM_LEAD | RoleNames.USER
    language: LanguageEnum
    questions: Question[]
    intro_text: TranslatedField<string>
    outro_text: TranslatedField<string>
    survey_type: string
    active_features: ActiveFeaturesObj
    check_name_i18n: TranslatedField<string>
  }
}
const initialState: SurveyQuestionsState = {
  loading: false,
  errors: {},
  data: {
    id: "",
    title: "",
    intro_text: {},
    outro_text: {},
    role: RoleNames.USER,
    language: LanguageEnum.ENGLISH,
    questions: [],
    survey_type: "",
    active_features: {},
    check_name_i18n: {}
  }
}

export default (
  state = initialState,
  action: Actions
): SurveyQuestionsState => {
  switch (action.type) {
    case SurveyRequestActions.GET_SURVEY_QUESTIONS:
      return { ...state, loading: true }
    case SurveyRequestActions.GET_SURVEY_QUESTIONS_SUCCESS:
      return { ...state, loading: false, data: action.payload }
    case SurveyRequestActions.GET_SURVEY_QUESTIONS_FAIL:
      return { ...state, loading: false, errors: action.payload }

    default:
      return state
  }
}
