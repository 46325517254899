import styled from "@emotion/styled"
import { Icon } from "@material-ui/core"
import { ENV_FEATURE_FLAG } from "enums"
import { IStyled, funToWorkWithTheme } from "style/theme"

import applyFeature from "utils/applyFeature"

const isNewNavigation = applyFeature(ENV_FEATURE_FLAG.IS_NEW_NAVIGATION)

export const TitleWrapper = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: ${({ theme }: IStyled) =>
    theme.spacing(isNewNavigation ? 2 : 1)};
`

export const TitleIcon = styled(Icon)`
  vertical-align: text-bottom;
  margin-right: 1.4rem;
  font-size: 2.6rem;
` as typeof Icon

export const StyledPunctuation = styled.span`
  color: ${funToWorkWithTheme.colors.secondary};
`
