import { AxiosPromise } from "axios"
import { usersApi } from "./api"
import {
  GetNuancesPayload,
  PutNuancesPayload,
  ArchiveNuancesPayload,
  DeleteNuancesPayload
} from "app/models/nuance"

export const getNuances = ({ id, ...data }: GetNuancesPayload): AxiosPromise =>
  usersApi(`/parties/${id}/nuances`, {
    data,
    method: "GET"
  })

export const getPartyRelationNuances = ({
  id,
  ...data
}: GetNuancesPayload): AxiosPromise =>
  usersApi(`/party_relations/${id}/nuances`, {
    data,
    method: "GET"
  })

export const putNuance = ({
  party_id,
  data
}: PutNuancesPayload): AxiosPromise =>
  usersApi(`/parties/${party_id}/nuances`, {
    data,
    method: "PUT"
  })

export const deleteNuance = ({
  party_id,
  id,
  ...data
}: DeleteNuancesPayload): AxiosPromise =>
  usersApi(`/parties/${party_id}/nuances/${id}`, {
    data,
    method: "DELETE"
  })

export const archiveNuance = ({
  party_id,
  token
}: ArchiveNuancesPayload): AxiosPromise =>
  usersApi(`/parties/${party_id}/nuances/archive`, {
    data: token,
    method: "PATCH"
  })
