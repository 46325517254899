import DailyCheck, {
  DailyCheckCommentsPayload,
  DailyCheckCommentsResponse,
  DailyCheckPayload,
} from "app/models/dailycheck"
import { PartyRelationPayload } from "app/models/partyRelation"

export enum Types {
  GET = "DAILYCHECK_GET",
  GET_SUCCESS = "DAILYCHECK_GET_SUCCESS",
  GET_FAIL = "DAILYCHECK_GET_FAIL",
  POST = "DAILYCHECK_POST",
  POST_SUCCESS = "DAILYCHECK_POST_SUCCESS",
  POST_FAIL = "DAILYCHECK_POST_FAIL",
  GET_RECENT_COMMENTS = "DAILYCHECK_GET_RECENT_COMMENTS",
  GET_RECENT_COMMENTS_SUCCESS = "DAILYCHECK_GET_RECENT_COMMENTS_SUCCESS",
  GET_RECENT_COMMENTS_FAIL = "DAILYCHECK_GET_RECENT_COMMENTS_FAIL",
  GET_ALL_COMMENTS = "DAILYCHECK_GET_ALL_COMMENTS",
  GET_ALL_COMMENTS_SUCCESS = "DAILYCHECK_GET_ALL_COMMENTS_SUCCESS",
  GET_ALL_COMMENTS_FAIL = "DAILYCHECK_GET_ALL_COMMENTS_FAIL",
  CLEAR_DAILY_CHECK = "CLEAR_DAILY_CHECK",
}

export type Action =
  | GetDailyCheckAction
  | GetDailyCheckSuccessAction
  | GetDailyCheckFailAction
  | GetRecentCommentsAction
  | GetRecentCommentsSuccessAction
  | GetRecentCommentsFailAction
  | GetAllCommentsAction
  | GetAllCommentsSuccessAction
  | GetAllCommentsFailAction
  | PostDailyCheckAction
  | PostDailyCheckSuccessAction
  | PostDailyCheckFailAction
  | ClearDailyCheckAction

export type GetDailyCheckAction = {
  payload: PartyRelationPayload
  type: Types.GET
}

export type GetDailyCheckSuccessAction = {
  payload: DailyCheck
  type: Types.GET_SUCCESS
}

export type GetDailyCheckFailAction = {
  payload: { errors: object }
  type: Types.GET_FAIL
}

export type GetRecentCommentsAction = {
  payload: DailyCheckCommentsPayload
  type: Types.GET_RECENT_COMMENTS
}

export type GetRecentCommentsSuccessAction = {
  payload: DailyCheckCommentsResponse
  type: Types.GET_RECENT_COMMENTS_SUCCESS
}

export type GetRecentCommentsFailAction = {
  payload: { errors: object }
  type: Types.GET_RECENT_COMMENTS_FAIL
}

export type GetAllCommentsAction = {
  payload: DailyCheckCommentsPayload
  type: Types.GET_ALL_COMMENTS
}

export type GetAllCommentsSuccessAction = {
  payload: DailyCheckCommentsResponse
  type: Types.GET_ALL_COMMENTS_SUCCESS
}

export type GetAllCommentsFailAction = {
  payload: { errors: object }
  type: Types.GET_ALL_COMMENTS_FAIL
}

export type PostDailyCheckAction = {
  payload: DailyCheckPayload
  type: Types.POST
}

export type PostDailyCheckSuccessAction = {
  type: Types.POST_SUCCESS
}

export type PostDailyCheckFailAction = {
  payload: { errors: object }
  type: Types.POST_FAIL
}

export type ClearDailyCheckAction = {
  type: Types.CLEAR_DAILY_CHECK
}

export function postDailyCheck(
  payload: DailyCheckPayload,
): PostDailyCheckAction {
  return {
    payload,
    type: Types.POST,
  }
}

export function postDailyCheckSuccess(): PostDailyCheckSuccessAction {
  return {
    type: Types.POST_SUCCESS,
  }
}

export function postDailyCheckFail(errors: object): PostDailyCheckFailAction {
  return {
    payload: { errors },
    type: Types.POST_FAIL,
  }
}

export function getDailyCheck(data: PartyRelationPayload): GetDailyCheckAction {
  return {
    payload: data,
    type: Types.GET,
  }
}

export function getDailyCheckSuccess(
  data: DailyCheck,
): GetDailyCheckSuccessAction {
  return {
    payload: data,
    type: Types.GET_SUCCESS,
  }
}

export function getDailyCheckFail(errors: object): GetDailyCheckFailAction {
  return {
    payload: { errors },
    type: Types.GET_FAIL,
  }
}

export function getRecentComments(
  data: DailyCheckCommentsPayload,
): GetRecentCommentsAction {
  return {
    payload: data,
    type: Types.GET_RECENT_COMMENTS,
  }
}

export function getRecentCommentsSuccess(
  data: DailyCheckCommentsResponse,
): GetRecentCommentsSuccessAction {
  return {
    payload: data,
    type: Types.GET_RECENT_COMMENTS_SUCCESS,
  }
}

export function getRecentCommentsFail(
  errors: object,
): GetRecentCommentsFailAction {
  return {
    payload: { errors },
    type: Types.GET_RECENT_COMMENTS_FAIL,
  }
}

export function getAllComments(
  data: DailyCheckCommentsPayload,
): GetAllCommentsAction {
  return {
    payload: data,
    type: Types.GET_ALL_COMMENTS,
  }
}

export function getAllCommentsSuccess(
  data: DailyCheckCommentsResponse,
): GetAllCommentsSuccessAction {
  return {
    payload: data,
    type: Types.GET_ALL_COMMENTS_SUCCESS,
  }
}

export function getAllCommentsFail(errors: object): GetAllCommentsFailAction {
  return {
    payload: { errors },
    type: Types.GET_ALL_COMMENTS_FAIL,
  }
}

export function clearDailyCheck(): ClearDailyCheckAction {
  return {
    type: Types.CLEAR_DAILY_CHECK,
  }
}
