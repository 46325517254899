import { Actions } from "../table/table.actions"
import createTableReducer, { DEFAULT_STATE } from "../table/table.reducer"

const INITIAL_STATE = {
  ...DEFAULT_STATE,
  defaultSorting: ["name=asc"],
  sorting: ["name=asc"]
}

const groupsTableReducer = (state = INITIAL_STATE, action: Actions) => {
  return createTableReducer("GROUPS_TABLE", {})(state, action)
}

export default groupsTableReducer
