import { push } from "connected-react-router"
import { SagaIterator } from "redux-saga"
import { call, put, take, takeLatest } from "redux-saga/effects"

import * as api from "app/api/organisation"

import * as userActions from "./../users/users.actions"
import * as actions from "./organisation.actions"

export function* postOrganisationsFlow(
  action: actions.PostOrganisationsAction,
): SagaIterator {
  try {
    const { data } = action.payload
    yield take(userActions.Types.POST_SUCCESS)
    yield call(api.postOrganisation, data)
    yield put(actions.postOrganisationsSuccess())
    yield put(push("/reality-check/dashboard"))
  } catch (e) {
    yield put(actions.postOrganisationsFail(e))
  }
}

export function* getOrganisationsFlow({
  payload,
}: actions.GetOrganisationsAction): SagaIterator {
  try {
    const response = yield call(api.getOrganisations, payload)
    yield put(actions.getOrganisationsSuccess(response.data))
  } catch (e) {
    yield put(actions.getOrganisationsFail(e))
  }
}

export function* getOrganisationNameFlow({
  payload,
}: actions.GetOrganisationNameAction): SagaIterator {
  try {
    const response = yield call(api.getOrganisationName, payload)
    yield put(actions.getOrganisationsSuccess(response.data))
  } catch (e) {
    yield put(actions.getOrganisationsFail(e))
  }
}

export default function* organisationsSaga(): SagaIterator {
  yield takeLatest(actions.Types.POST, postOrganisationsFlow)
  yield takeLatest(actions.Types.GET, getOrganisationsFlow)
  yield takeLatest(actions.Types.GET_NAME, getOrganisationNameFlow)
}
