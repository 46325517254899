import { Box } from "@material-ui/core"
import AppHeader from "app/components/AppHeader"
import { IRouteComponent } from "app/routes"
import FunToWorkWithLogo from "assets/icons/FunToWorkWithLogo"
import React from "react"
import { LogoContainer, DailyCheckSurveyWrapper, SurveyContent } from "../style"

export const DailyCheckSurveyLayout: React.FC<IRouteComponent> = ({
  children
}) => {
  return (
    <DailyCheckSurveyWrapper>
      <SurveyContent elevation={0}>
        <Box p={5}>
          <AppHeader />
          {children}
        </Box>
      </SurveyContent>

      <LogoContainer>
        <FunToWorkWithLogo fontSize="inherit" />
      </LogoContainer>
    </DailyCheckSurveyWrapper>
  )
}

export default DailyCheckSurveyLayout
