import { useEffect, useState } from "react"
import { useLocation } from "react-router-dom"

import { usersApi } from "app/api/api"
import { useAuth0 } from "app/context/AuthContext"
import { PartyRelation } from "app/models/partyRelation"
import { adminRoutes, appRoutes, authRoutes, surveyRoutes } from "app/routes"
import { addAccessToRoute } from "app/routes/utils"

import { useApi } from "./api"
import { useOrganisation } from "./organisation"
import { usePermission } from "./permissions"

export const useCreateRoutesObject = () => {
  const { organisation } = useOrganisation()
  const { hasPermissions } = usePermission()
  const location = useLocation()
  const { callApi } = useApi()

  const [partyRelationState, setPartyRelationState] = useState<
    PartyRelation[] | undefined
  >()

  const paramId = location.pathname?.split("/")?.[2] || null
  const { getTokenSilently, loading, user } = useAuth0()

  // NOTE: We use local state because when we try to use redux partyRelation selector, it causes infinite loops on some screens.
  const fetchData = async () => {
    try {
      const token = await getTokenSilently()
      let res = await usersApi("/party_relations", {
        data: { token },
        method: "GET",
      })
      setPartyRelationState(res.data)
    } catch (err) {
      console.log(err)
    }
  }

  useEffect(() => {
    if (!partyRelationState && user) fetchData()
  }, [callApi, getTokenSilently, user])

  const partyRelation = partyRelationState?.find((p) => p.parent_id === paramId)

  if (partyRelationState || (!user && !loading)) {
    const allRoutes = {
      adminRoutes: {
        ...adminRoutes,
        routes: adminRoutes.routes.map((route) =>
          addAccessToRoute({
            organisation,
            hasPermissions,
            route,
            partyRelation,
          }),
        ),
      },
      appRoutes: {
        ...appRoutes,
        routes: appRoutes.routes.map((route) =>
          addAccessToRoute({
            organisation,
            hasPermissions,
            route,
            partyRelation,
          }),
        ),
      },
      authRoutes,
      surveyRoutes,
    }
    return allRoutes
  }
}
