import { Actions } from "../table/table.actions"
import createTableReducer, { DEFAULT_STATE } from "../table/table.reducer"

const INITIAL_STATE = {
  ...DEFAULT_STATE,
  defaultSorting: ["name=asc"],
  sorting: ["name=asc"]
}

const leadershipTableReducer = (state = INITIAL_STATE, action: Actions) => {
  return createTableReducer("LEADER_SHIP_TABLE", {})(state, action)
}

export default leadershipTableReducer
