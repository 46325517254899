import isEqual from "lodash.isequal"
import React, { useEffect, useMemo, useRef } from "react"
import { useSelector } from "react-redux"
import {
  Route,
  RouteComponentProps,
  RouteProps,
  withRouter,
} from "react-router-dom"

import { useApi } from "app/hooks/api"
import { useEvents } from "app/hooks/events"
import { getMe } from "app/redux/me/me.actions"
import * as selectors from "app/redux/me/me.selectors"
import { ROUTE_PATHS } from "app/routes/config"

import { useAuth0 } from "../../context/AuthContext"

const PrivateRoute: React.FC<RouteProps & RouteComponentProps> = ({
  render: layoutRender,
  path,
  history,
  ...rest
}) => {
  const { push } = history
  const { loading, isAuthenticated, loginWithRedirect, user } = useAuth0()
  const { callApi } = useApi()
  const me = useSelector(selectors.me)
  const meDispatched = useSelector(selectors.dispatched)
  const currentUser = useRef()
  const { loginEvent } = useEvents()

  useEffect(() => {
    if (loading || isAuthenticated) {
      return
    }
    const redirectPath = path === "/" ? `${ROUTE_PATHS.REALITY_CHECK_OVERVIEW}/dashboard` : path
    const login = async () => {
      await loginWithRedirect({
        appState: { targetUrl: redirectPath },
      })
    }
    login()
  }, [loading, isAuthenticated, loginWithRedirect, path])

  useEffect(() => {
    if (me) {
      const { terms_of_use } = me
      if (!terms_of_use) push("/terms-of-use")
    }
  }, [me, me?.terms_of_use])

  useMemo(() => {
    if (!user) return
    if (isEqual(user, currentUser.current)) return
    currentUser.current = user
    const isVerified = !!user.email_verified
    if (isVerified) {
      loginEvent()
      if (!me) {
        callApi(getMe)
      }
      return
    }
    push("/verify")
  }, [push, user, callApi, loginEvent, me])

  const isNewUser = !me
  const isAuthenticatedUser = isAuthenticated && user

  const render = isAuthenticatedUser
    ? isNewUser || meDispatched
      ? layoutRender
      : undefined
    : undefined

  return <Route path={path} render={render} {...rest} />
}

export default withRouter(PrivateRoute)
