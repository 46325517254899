import React from "react"
import {
  SidebarWrapper,
  SidebarSection,
  SidebarFooter,
  StyledButton
} from "./style"
import Button from "components/Button"
import { SidebarDropdown } from "./Dropdown"
import { useLogout } from "app/hooks/logout"
import { Navigation } from "./Navigation"
import { NavigationProvider } from "app/context/NavigationContext"
import Avatar from "../Avatar"
import { ButtonBase } from "@material-ui/core"
import EditProfileDrawer from "../EditProfileDrawer"
import { useHistory } from "react-router"
import { ROUTE_PATHS } from "app/routes/config"

export const Sidebar: React.FC = () => {
  const history = useHistory()
  const { handleLogout } = useLogout()
  const [isDrawerVisible, setDrawerVisibility] = React.useState(false)

  const handleClick = () => {
    setDrawerVisibility(true)
  }

  const handleClose = () => {
    setDrawerVisibility(false)
  }

  const handleDailyChecks = () => {
    history.push(ROUTE_PATHS.REALITY_CHECK_OVERVIEW)
  }

  return (
    <>
      <NavigationProvider>
        <SidebarWrapper>
          <SidebarSection>
            <ButtonBase disableRipple onClick={handleClick}>
              <Avatar />
            </ButtonBase>
            <StyledButton
              fullWidth
              label="action.daily_checks"
              onClick={handleDailyChecks}
            />
          </SidebarSection>
          <SidebarSection>
            <SidebarDropdown />
            <Navigation />
          </SidebarSection>
          <SidebarFooter>
            <Button
              variant="outlined"
              label="action.logout"
              onClick={handleLogout}
            />
          </SidebarFooter>
        </SidebarWrapper>
      </NavigationProvider>

      <EditProfileDrawer
        onClose={handleClose}
        open={isDrawerVisible}
        anchor="left"
      />
    </>
  )
}
