import { PartyRelation } from "app/models/partyRelation"

import State from "../state"

export const partyRelations = (state: State): PartyRelation[] =>
  state.partyRelations.partyRelations
export const partyRelationsByParentId = (id: string) => (state: State) => {
  const allPartyRelations = partyRelations(state)
  const partyRelation = allPartyRelations.find(
    ({ parent_id }) => parent_id === id,
  )
  return partyRelation
}
export const dispatched = (state: State): boolean =>
  state.partyRelations.dispatched
export const loading = (state: State): boolean => state.partyRelations.loading
export const showDeleteConfirmation = (state: State): string =>
  state.partyRelations.showDeleteConfirmation
export const showDeleteMultipleConfirmation = (state: State): string[] =>
  state.partyRelations.showDeleteMultipleConfirmation
