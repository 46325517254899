import styled from "@emotion/styled"

export const DropdownWrapper = styled.div`
  width: 48rem;
`

export const DropDownContent = styled.div`
  padding: 2em;
  box-shadow: 0px 2px 5px 1px rgba(204, 204, 204, 1);
`

export const AvatarIconWrapper = styled.div`
  cursor: pointer;
`
