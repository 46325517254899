import { SelectClassKey } from "@material-ui/core"
import {
  CSSProperties,
  CreateCSSProperties
} from "@material-ui/core/styles/withStyles"
import { PropsFunc } from "../types"
import funToWorkWithTheme from "style/funToWorkWithTheme"

type Select = Partial<
  Record<
    SelectClassKey,
    | CSSProperties
    | CreateCSSProperties<{}>
    | PropsFunc<{}, CreateCSSProperties<{}>>
  >
>
export const select: Select = {
  select: {
    "&:focus": {
      backgroundColor: funToWorkWithTheme.colors.secondaryLight
    }
  }
}
