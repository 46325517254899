import { AxiosError } from "axios"
import { ENV_FEATURE_FLAG } from "enums"
import { SagaIterator } from "redux-saga"
import { call, put, takeLatest } from "redux-saga/effects"

import * as api from "app/api/users"
import { ErrorCode } from "app/models/errorCode"
import { ResponseError } from "app/models/responseError"
import { MetaTypes, User } from "app/models/user"

import applyFeature from "utils/applyFeature"

import * as groupActions from "../groups/groups.actions"
import { showMessage } from "../message/message.actions"
import * as peopleActions from "../people/people.actions"
import * as actions from "./users.actions"

const isNewNavigation = applyFeature(ENV_FEATURE_FLAG.IS_NEW_NAVIGATION)
const metadataErrorMessageMap: Record<ErrorCode, string> = {
  [ErrorCode.LAST_GROUP_ADMIN]: "user.error.last_admin",
  [ErrorCode.LAST_ORGANIZATION_OWNER]: "user.error.last_organization_owner",
}

export function* getUsersFlow(): SagaIterator {
  try {
    const response = yield call(api.getUsers)
    yield put(actions.getUsersSuccess(response && response.data))
  } catch (e) {
    yield put(actions.getUsersFail(e))
  }
}

export function* deleteUsersByIdFlow(
  action: actions.DeleteUsersByIdAction,
): SagaIterator {
  try {
    const { id } = action.payload
    yield call(api.deleteUsersById, id)
    yield put(actions.deleteUsersByIdSuccess())
  } catch (e) {
    yield put(actions.deleteUsersByIdFail(e))
  }
}

export function* postUsersFlow({
  payload,
}: actions.PostUsersAction): SagaIterator {
  try {
    yield call(api.postUsers, payload)
    yield put(actions.postUsersSuccess())
  } catch (e) {
    yield put(actions.postUsersFail(e))
  }
}

export function* getUsersByIdFlow(
  action: actions.GetUsersByIdAction,
): SagaIterator {
  try {
    const response = yield call(api.getUsersById, action.payload.id)
    const data: User = response && response.data
    yield put(actions.getUsersByIdSuccess(data))
  } catch (e) {
    yield put(actions.getUsersByIdFail(e))
  }
}

export function* patchPartyRelationsMetaFlow(
  action: actions.PatchMetaAction,
): SagaIterator {
  try {
    const { payload } = action
    const response = yield call(api.patchPartyRelationsMeta, payload)
    const successPayload = {
      ...response.data,
      ...payload,
    }
    yield put(actions.patchUserMetaSuccess(successPayload))
    yield put(groupActions.patchMetaSuccess(successPayload))

    isNewNavigation
      ? yield put(
          peopleActions.getPeople({
            organisation_id: payload.organisation_id!,
            team_id: payload.teamId,
            token: payload.token,
          }),
        )
      : yield put(groupActions.refreshGroups({ token: payload.token! }))
    if (action.payload.metadata_type === MetaTypes.Role) {
      window.location.reload()
    }
  } catch (e) {
    yield put(actions.patchUserMetaFail(e))
  }
}

export function* postPartyRelationsMetaFlow(
  action: actions.PostMetaAction,
): SagaIterator {
  try {
    const { payload } = action
    const response = yield call(api.postPartyRelationsMeta, payload)
    yield put(actions.postUserMetaSuccess(response.data))
    yield put(groupActions.postMetaSuccess(response.data))

    isNewNavigation
      ? yield put(
          peopleActions.getPeople({
            organisation_id: payload.organisation_id!,
            team_id: payload.teamId,
            token: payload.token,
          }),
        )
      : yield put(groupActions.refreshGroups({ token: payload.token! }))
    if (action.payload.metadata_type === MetaTypes.Role) {
      window.location.reload()
    }
  } catch (e) {
    yield put(actions.postUserMetaFail(e))
  }
}

export function* deletePartyRelationsMetaFlow(
  action: actions.DeleteMetaAction,
): SagaIterator {
  const { payload } = action
  try {
    yield call(api.deletePartyRelationsMeta, payload)
    const { metadata_id } = payload
    yield put(actions.deleteUserMetaSuccess(metadata_id))
    yield put(groupActions.deleteMetaSuccess(payload))

    isNewNavigation
      ? yield put(
          peopleActions.getPeople({
            organisation_id: payload.organisation_id!,
            team_id: payload.teamId,
            token: payload.token,
          }),
        )
      : yield put(groupActions.refreshGroups({ token: payload.token! }))
    if (action.payload.metadata_type === MetaTypes.Role) {
      window.location.reload()
    }
  } catch (e) {
    const error = e as AxiosError<ResponseError>
    yield put(actions.deleteUserMetaFail(error))

    if (isNewNavigation) {
      yield put(
        peopleActions.getPeople({
          organisation_id: payload.organisation_id!,
          team_id: payload.teamId,
          token: payload.token,
        }),
      )

      yield put(
        showMessage(metadataErrorMessageMap[error.response?.data.errorCode!]),
      )
    } else {
      yield put(showMessage("user.error.last_admin"))
    }
  }
}

export default function* usersSaga(): SagaIterator {
  yield takeLatest(actions.Types.GET, getUsersFlow)
  yield takeLatest(actions.Types.DELETE, deleteUsersByIdFlow)
  yield takeLatest(actions.Types.POST, postUsersFlow)
  yield takeLatest(actions.Types.GET_BY_ID, getUsersByIdFlow)
  yield takeLatest(actions.Types.PATCH_META, patchPartyRelationsMetaFlow)
  yield takeLatest(actions.Types.POST_META, postPartyRelationsMetaFlow)
  yield takeLatest(actions.Types.DELETE_META, deletePartyRelationsMetaFlow)
}
