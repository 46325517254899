import {
  DeleteSurveyPayload,
  GetSurveysPayload,
  PostSurveyPayload,
  Survey,
  PatchSurveyPayload,
  SURVEY_TYPE
} from "app/models/survey"
import { AxiosError } from "axios"

export enum Types {
  GET_ALL = "SURVEY_GET_ALL",
  GET_ALL_SUCCESS = "SURVEY_GET_ALL_SUCCESS",
  GET_ALL_FAIL = "SURVEY_GET_ALL_FAIL",
  POST = "SURVEY_POST",
  POST_SUCCESS = "SURVEY_POST_SUCCESS",
  POST_FAIL = "SURVEY_POST_FAIL",
  TOGGLE_DELETE_CONFIRMATION = "TOGGLE_DELETE_CONFIRMATION",
  SET_SURVEY_TYPE = "SET_SURVEY_TYPE",
  DELETE = "SURVEY_DELETE",
  DELETE_SUCCESS = "SURVEY_DELETE_SUCCESS",
  DELETE_FAIL = "SURVEY_DELETE_FAIL",
  PATCH = "SURVEY_PATCH",
  PATCH_SUCCESS = "SURVEY_PATCH_SUCCESS",
  PATCH_FAIL = "SURVEY_PATCH_FAIL"
}

export type Action =
  | GetSurveysAction
  | GetSurveysSuccessAction
  | GetSurveysFailAction
  | PostSurveyAction
  | PostSurveySuccessAction
  | PostSurveyFailAction
  | ToggleDeleteConfirmationAction
  | SetSurveyType
  | DeleteSurveyAction
  | DeleteSurveySuccessAction
  | DeleteSurveyFailAction
  | PatchSurveyAction
  | PatchSurveySuccessAction
  | PatchSurveyFailAction

export type GetSurveysAction = {
  payload: GetSurveysPayload
  type: Types.GET_ALL
}

export type GetSurveysSuccessAction = {
  payload: Survey[]
  type: Types.GET_ALL_SUCCESS
}

export type GetSurveysFailAction = {
  payload: AxiosError
  type: Types.GET_ALL_FAIL
}

export type PostSurveyAction = {
  payload: PostSurveyPayload
  type: Types.POST
}

export type PostSurveySuccessAction = {
  type: Types.POST_SUCCESS
}

export type PostSurveyFailAction = {
  payload: AxiosError
  type: Types.POST_FAIL
}

export type ToggleDeleteConfirmationAction = {
  payload: string
  type: Types.TOGGLE_DELETE_CONFIRMATION
}

export type SetSurveyType = {
  payload: SURVEY_TYPE
  type: Types.SET_SURVEY_TYPE
}

export type DeleteSurveyAction = {
  payload: DeleteSurveyPayload
  type: Types.DELETE
}

export type DeleteSurveySuccessAction = {
  type: Types.DELETE_SUCCESS
}

export type DeleteSurveyFailAction = {
  payload: { errors: object }
  type: Types.DELETE_FAIL
}

export type PatchSurveyAction = {
  payload: PatchSurveyPayload
  type: Types.PATCH
}

export type PatchSurveySuccessAction = {
  type: Types.PATCH_SUCCESS
}

export type PatchSurveyFailAction = {
  payload: AxiosError
  type: Types.PATCH_FAIL
}

export function getSurveys(payload: GetSurveysPayload): GetSurveysAction {
  return {
    payload,
    type: Types.GET_ALL
  }
}

export function getSurveysSuccess(payload: Survey[]): GetSurveysSuccessAction {
  return {
    payload,
    type: Types.GET_ALL_SUCCESS
  }
}

export function getSurveysFail(payload: AxiosError): GetSurveysFailAction {
  return {
    payload,
    type: Types.GET_ALL_FAIL
  }
}

export function postSurvey(payload: PostSurveyPayload): PostSurveyAction {
  return {
    payload,
    type: Types.POST
  }
}

export function postSurveySuccess(): PostSurveySuccessAction {
  return {
    type: Types.POST_SUCCESS
  }
}

export function postSurveyFail(payload: AxiosError): PostSurveyFailAction {
  return {
    payload,
    type: Types.POST_FAIL
  }
}

export function toggleConfirmDeleteModal(surveyId: string) {
  return {
    payload: surveyId,
    type: Types.TOGGLE_DELETE_CONFIRMATION
  }
}

export function setSurveyType(surveyType: string) {
  return {
    payload: surveyType,
    type: Types.SET_SURVEY_TYPE
  }
}

export function deleteSurvey(payload: DeleteSurveyPayload): DeleteSurveyAction {
  return {
    payload,
    type: Types.DELETE
  }
}

export function deleteSurveySuccess(): DeleteSurveySuccessAction {
  return {
    type: Types.DELETE_SUCCESS
  }
}

export function deleteSurveyFail(errors: object): DeleteSurveyFailAction {
  return {
    payload: { errors },
    type: Types.DELETE_FAIL
  }
}
export function patchSurvey(payload: PatchSurveyPayload): PatchSurveyAction {
  return {
    payload,
    type: Types.PATCH
  }
}

export function patchSurveySuccess(): PatchSurveySuccessAction {
  return {
    type: Types.PATCH_SUCCESS
  }
}

export function patchSurveyFail(payload: AxiosError): PatchSurveyFailAction {
  return {
    payload,
    type: Types.PATCH_FAIL
  }
}
