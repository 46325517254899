import { PaperClassKey } from "@material-ui/core"
import { PropsFunc } from "../types"
import {
  CSSProperties,
  CreateCSSProperties
} from "@material-ui/core/styles/withStyles"

type Paper = Partial<
  Record<
    PaperClassKey,
    | CSSProperties
    | CreateCSSProperties<{}>
    | PropsFunc<{}, CreateCSSProperties<{}>>
  >
>

export const paper: Paper = {
  rounded: {
    borderRadius: "unset"
  }
}
