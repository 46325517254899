import React from "react"
import { ITopBarNavigation_new } from "app/context/NavigationContext"
import Translation from "components/Translation"
import { SidebarLinkContentWrapper } from "./style"

type Props = Pick<ITopBarNavigation_new, "label" | "icon">

const LinkContent = ({ label }: Props) => {
  return (
    <SidebarLinkContentWrapper>
      <Translation label={label} />
    </SidebarLinkContentWrapper>
  )
}

export default LinkContent
