import {
  User,
  UserPayload,
  PatchMetaPayload,
  PatchUserMetaSuccessPayload,
  PostMetaPayload,
  PostUserMetaSuccessPayload,
  DeleteMetaPayload
} from "app/models/user"

export enum Types {
  GET = "USERS_GET",
  GET_SUCCESS = "USERS_GET_SUCCESS",
  GET_FAIL = "USERS_GET_FAIL",
  DELETE = "USERS_DELETE",
  DELETE_SUCCESS = "USERS_DELETE_SUCCESS",
  DELETE_FAIL = "USERS_DELETE_FAIL",
  POST = "USERS_POST",
  POST_SUCCESS = "USERS_POST_SUCCESS",
  POST_FAIL = "USERS_POST_FAIL",
  GET_BY_ID = "USER_GET_BY_ID",
  GET_BY_ID_SUCCESS = "USER_GET_BY_ID_SUCCESS",
  GET_BY_ID_FAIL = "USER_GET_BY_ID_FAIL",
  PATCH_META = "USER_PATCH_META",
  PATCH_META_SUCCESS = "USER_PATCH_META_SUCCESS",
  PATCH_META_FAIL = "USER_PATCH_META_FAIL",
  POST_META = "USER_POST_META",
  POST_META_SUCCESS = "USER_POST_META_SUCCESS",
  POST_META_FAIL = "USER_POST_META_FAIL",
  DELETE_META = "USER_DELETE_META",
  DELETE_META_SUCCESS = "USER_DELETE_META_SUCCESS",
  DELETE_META_FAIL = "USER_DELETE_META_FAIL"
}

export type Action =
  | GetUsersAction
  | GetUsersSuccessAction
  | GetUsersFailAction
  | DeleteUsersByIdAction
  | DeleteUsersByIdSuccessAction
  | DeleteUsersByIdFailAction
  | PostUsersAction
  | PostUsersSuccessAction
  | PostUsersFailAction
  | GetUsersByIdAction
  | GetUsersByIdSuccessAction
  | GetUsersByIdFailAction
  | PatchMetaAction
  | PatchMetaSuccessAction
  | PatchMetaFailAction
  | PostMetaAction
  | PostMetaSuccessAction
  | PostMetaFailAction
  | DeleteMetaAction
  | DeleteMetaSuccessAction
  | DeleteMetaFailAction

export type GetUsersAction = {
  type: Types.GET
}

export type GetUsersSuccessAction = {
  payload: { data: User[] | undefined }
  type: Types.GET_SUCCESS
}

export type GetUsersFailAction = {
  payload: { errors: object }
  type: Types.GET_FAIL
}

export type PostUsersAction = {
  payload: UserPayload
  type: Types.POST
}

export type PostUsersSuccessAction = {
  type: Types.POST_SUCCESS
}

export type PostUsersFailAction = {
  payload: { errors: object }
  type: Types.POST_FAIL
}

export type DeleteUsersByIdAction = {
  payload: { id: string }
  type: Types.DELETE
}

export type DeleteUsersByIdSuccessAction = {
  type: Types.DELETE_SUCCESS
}

export type DeleteUsersByIdFailAction = {
  payload: { errors: object }
  type: Types.DELETE_FAIL
}

export type GetUsersByIdAction = {
  type: Types.GET_BY_ID
  payload: { id: string }
}

export type GetUsersByIdSuccessAction = {
  type: Types.GET_BY_ID_SUCCESS
  payload: { data: User | undefined }
}

export type GetUsersByIdFailAction = {
  type: Types.GET_BY_ID_FAIL
  payload: { errors: object }
}

export type PatchMetaAction = {
  type: Types.PATCH_META
  payload: PatchMetaPayload
}

export type PatchMetaSuccessAction = {
  type: Types.PATCH_META_SUCCESS
  payload: PatchUserMetaSuccessPayload
}

export type PatchMetaFailAction = {
  type: Types.PATCH_META_FAIL
  payload: { errors: object }
}

export type PostMetaAction = {
  type: Types.POST_META
  payload: PostMetaPayload
}

export type PostMetaSuccessAction = {
  type: Types.POST_META_SUCCESS
  payload: PostUserMetaSuccessPayload
}

export type PostMetaFailAction = {
  type: Types.POST_META_FAIL
  payload: { errors: object }
}

export type DeleteMetaAction = {
  type: Types.DELETE_META
  payload: DeleteMetaPayload
}

export type DeleteMetaSuccessAction = {
  type: Types.DELETE_META_SUCCESS
  payload: string
}

export type DeleteMetaFailAction = {
  type: Types.DELETE_META_FAIL
  payload: { errors: object }
}

export function getUsers(): GetUsersAction {
  return {
    type: Types.GET
  }
}

export function getUsersSuccess(
  data: User[] | undefined
): GetUsersSuccessAction {
  return {
    payload: { data },
    type: Types.GET_SUCCESS
  }
}

export function getUsersFail(errors: object): GetUsersFailAction {
  return {
    payload: { errors },
    type: Types.GET_FAIL
  }
}

export function deleteUsersById(id: string): DeleteUsersByIdAction {
  return {
    payload: { id },
    type: Types.DELETE
  }
}

export function deleteUsersByIdSuccess(): DeleteUsersByIdSuccessAction {
  return {
    type: Types.DELETE_SUCCESS
  }
}

export function deleteUsersByIdFail(errors: object): DeleteUsersByIdFailAction {
  return {
    payload: { errors },
    type: Types.DELETE_FAIL
  }
}

export function postUsers(payload: UserPayload): PostUsersAction {
  return {
    payload,
    type: Types.POST
  }
}

export function postUsersSuccess(): PostUsersSuccessAction {
  return {
    type: Types.POST_SUCCESS
  }
}

export function postUsersFail(errors: object): PostUsersFailAction {
  return {
    payload: { errors },
    type: Types.POST_FAIL
  }
}

export function getUsersById(id: string): GetUsersByIdAction {
  return {
    payload: { id },
    type: Types.GET_BY_ID
  }
}

export function getUsersByIdSuccess(
  data: User | undefined
): GetUsersByIdSuccessAction {
  return {
    payload: { data },
    type: Types.GET_BY_ID_SUCCESS
  }
}

export function getUsersByIdFail(errors: object): GetUsersByIdFailAction {
  return {
    payload: { errors },
    type: Types.GET_BY_ID_FAIL
  }
}

export function patchUserMeta(data: PatchMetaPayload): PatchMetaAction {
  return {
    payload: data,
    type: Types.PATCH_META
  }
}

export function patchUserMetaSuccess(
  data: PatchUserMetaSuccessPayload
): PatchMetaSuccessAction {
  return {
    payload: data,
    type: Types.PATCH_META_SUCCESS
  }
}

export function patchUserMetaFail(errors: object): PatchMetaFailAction {
  return {
    payload: { errors },
    type: Types.PATCH_META_FAIL
  }
}

export function postUserMeta(data: PostMetaPayload): PostMetaAction {
  return {
    payload: data,
    type: Types.POST_META
  }
}

export function postUserMetaSuccess(
  data: PostUserMetaSuccessPayload
): PostMetaSuccessAction {
  return {
    payload: data,
    type: Types.POST_META_SUCCESS
  }
}

export function postUserMetaFail(errors: object): PostMetaFailAction {
  return {
    payload: { errors },
    type: Types.POST_META_FAIL
  }
}

export function deleteUserMeta(data: DeleteMetaPayload): DeleteMetaAction {
  return {
    payload: data,
    type: Types.DELETE_META
  }
}

export function deleteUserMetaSuccess(
  payload: string
): DeleteMetaSuccessAction {
  return {
    payload,
    type: Types.DELETE_META_SUCCESS
  }
}

export function deleteUserMetaFail(errors: object): DeleteMetaFailAction {
  return {
    payload: { errors },
    type: Types.DELETE_META_FAIL
  }
}
