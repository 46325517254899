import { Popover } from "@material-ui/core"
import React from "react"

import { StyledEditProfileDrawer } from "../AppHeader/style"
import Avatar from "../Avatar"
import Dropdown from "./Dropdown"
import { AvatarIconWrapper } from "./style"

const TopNavigationAvatar = () => {
  const [isDrawerVisible, setDrawerVisibility] = React.useState(false)
  const [anchorEl, setAnchorEl] = React.useState<HTMLDivElement | null>(null)

  const handleClick = (event: React.MouseEvent<HTMLDivElement>) => {
    setAnchorEl(event.currentTarget)
  }

  const handleClose = () => {
    setAnchorEl(null)
  }
  const handleCloseProfileEdit = () => setDrawerVisibility(false)

  const open = Boolean(anchorEl)
  const id = open ? "simple-popover" : undefined

  return (
    <>
      <div>
        <AvatarIconWrapper aria-describedby={id} onClick={handleClick}>
          <Avatar showName={false} />
        </AvatarIconWrapper>
        <Popover
          id={id}
          open={open}
          anchorEl={anchorEl}
          onClose={handleClose}
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "left",
          }}
          style={{ marginTop: "10px" }}
        >
          <Dropdown
            handleClose={handleClose}
            setDrawerVisibility={setDrawerVisibility}
          />
        </Popover>
      </div>
      <StyledEditProfileDrawer
        onClose={handleCloseProfileEdit}
        open={isDrawerVisible}
        anchor="left"
        showTopClose={true}
        paperCoverFullBackground={true}
      />
    </>
  )
}

export default TopNavigationAvatar
