import React from "react"
import { SvgIconProps, SvgIcon } from "@material-ui/core"

function Dashboard(props: SvgIconProps): JSX.Element {
  return (
    <SvgIcon viewBox="0 0 40 40" {...props}>
      <path
        fillRule="evenodd"
        d="M0 4C0 1.79086 1.79086 0 4 0H36C38.2091 0 40 1.79086 40 4V36C40 38.2091 38.2091 40 36 40H4C1.79086 40 0 38.2091 0 36V4ZM14.322 30C13.9698 30.001 13.6219 29.9219 13.3048 29.7686C12.9877 29.6153 12.7095 29.3919 12.4915 29.1152C12.2793 28.8495 12.1303 28.539 12.0557 28.2072C11.9811 27.8754 11.983 27.531 12.061 27.2C12.5021 25.3374 13.5589 23.6781 15.0602 22.4907C16.5616 21.3034 18.4197 20.6574 20.3339 20.6574C22.248 20.6574 24.1062 21.3034 25.6075 22.4907C27.1089 23.6781 28.1657 25.3374 28.6068 27.2C28.6844 27.5312 28.6856 27.8758 28.6104 28.2076C28.5353 28.5394 28.3856 28.8498 28.1729 29.1152C27.9555 29.3918 27.678 29.6152 27.3615 29.7685C27.0449 29.9219 26.6975 30.001 26.3458 30H14.322ZM17.8255 18.2633C18.5675 18.7591 19.4398 19.0237 20.3322 19.0237C21.5285 19.0228 22.6756 18.5472 23.5216 17.7012C24.3675 16.8553 24.8431 15.7082 24.844 14.5119C24.844 13.6195 24.5794 12.7472 24.0837 12.0052C23.5879 11.2632 22.8832 10.6849 22.0588 10.3434C21.2344 10.002 20.3272 9.91261 19.452 10.0867C18.5767 10.2608 17.7728 10.6905 17.1418 11.3215C16.5108 11.9525 16.0811 12.7564 15.907 13.6316C15.7329 14.5069 15.8223 15.414 16.1638 16.2385C16.5053 17.0629 17.0835 17.7676 17.8255 18.2633Z"
      />
    </SvgIcon>
  )
}

export default Dashboard
