import { Typography } from "@material-ui/core"
import React from "react"

import BreadcrumbsContainer from "../BreadcrumbsContainer"
import { Link } from "../Link"

export interface IBreadCrumb {
  id: string
  label: string | React.ReactElement
  key: string | number
  to?: string
}

interface IBreadCrumbs {
  breadcrumbs?: IBreadCrumb[]
  onClick?: (...args: any) => void
}

export const BreadcrumbsFromParam: React.FC<IBreadCrumbs> = ({
  breadcrumbs,
  onClick,
}) => {
  return (
    <>
      {breadcrumbs && (
        <BreadcrumbsContainer>
          {breadcrumbs.map(({ label, key, id, to }: IBreadCrumb) => {
            if (!to && !onClick) {
              return (
                <Typography variant="overline" key={key}>
                  {label}
                </Typography>
              )
            }

            return (
              <Link
                variant="overline"
                key={key}
                onClick={onClick && onClick.bind(null, id)}
                to={to}
              >
                {label}
              </Link>
            )
          })}
        </BreadcrumbsContainer>
      )}
    </>
  )
}
