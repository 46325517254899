import { LinkClassKey } from "@material-ui/core"
import {
  CreateCSSProperties,
  CSSProperties
} from "@material-ui/core/styles/withStyles"
import funToWorkWithTheme from "style/funToWorkWithTheme"
import { PropsFunc } from "../types"

type Link = Partial<
  Record<
    LinkClassKey,
    | CSSProperties
    | CreateCSSProperties<{}>
    | PropsFunc<{}, CreateCSSProperties<{}>>
  >
>
export const link: Link = {
  root: {
    fontSize: "1.4rem",
    paddingBottom: "0.4rem",
    borderBottom: `2px solid ${funToWorkWithTheme.colors.secondary}`,
    textDecoration: "none",
    textTransform: "uppercase",
    fontWeight: "bold",
    color: funToWorkWithTheme.colors.primary,
    cursor: "pointer",

    "&:hover": {
      borderBottomColor: funToWorkWithTheme.colors.accent1
    }
  },

  underlineHover: {
    "&:hover": {
      textDecoration: "none"
    }
  },

  button: {
    borderBottom: `2px solid ${funToWorkWithTheme.colors.secondary}`,
    "&:hover": {
      textDecoration: "none",
      borderBottomColor: funToWorkWithTheme.colors.accent1
    }
  }
}
