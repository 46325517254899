import { brighten, opacify } from "utils"

const viorasTheme = {
  colors: {
    background: "#fff",
    black: "#000",
    body: "#505050",
    border: "#C8C8C8",
    boxShadow: {
      dark: "rgba(43, 43, 43, 0.5)",
      normal: "rgba(183, 183, 183, 0.5)"
    },
    error: {
      dark: "#cc0303",
      hover: "#ffb8b8",
      light: "#FFB9B9",
      normal: "#ff0000"
    },
    grey: {
      normal: "#000",
      40: "",
      60: "",
      70: "",
      80: "",
      90: "",
      95: "",
      99: ""
    },
    info: {
      dark: "#0074e0",
      hover: "",
      light: "#dff0ff",
      normal: "#51abff"
    },
    input: {
      hover: "#f2f2f2"
    },
    link: {
      dark: "#1566b3",
      normal: "#1e92ff"
    },
    primary: {
      dark: "",
      hover: "",
      light: "",
      normal: "#6c71c4"
    },
    secondary: {
      dark: "",
      hover: "",
      light: "",
      normal: "#19847a"
    },
    success: {
      dark: "#5d9b0e",
      hover: "",
      light: "#eefcdc",
      normal: "#add876"
    },
    warning: {
      dark: "#e0a100",
      hover: "",
      light: "#ffcc33",
      normal: "#FCF5DC"
    },
    white: "#fff"
  },
  typography: {
    fontFamilySansSerif: "verdana",
    fontSize: "1.6rem",
    fontSizeHTML: 10,
    fontSize_h1: "3.2rem",
    fontSize_h2: "2.6rem",
    fontSize_h3: "2.2rem",
    fontSize_h4: "2rem",
    fontSize_h5: "1.8rem",
    fontSize_h6: "1.6rem",
    fontSize_md: "1.6rem",
    fontSize_sm: "1.2rem",
    fontSize_xs: "1rem",
    fontWeight: 400,
    lineHeight: 1.5
  }
}
viorasTheme.colors.grey[40] = brighten(viorasTheme.colors.grey.normal, 43) // #6e6e6e
viorasTheme.colors.grey[60] = brighten(viorasTheme.colors.grey.normal, 60) // #999
viorasTheme.colors.grey[70] = brighten(viorasTheme.colors.grey.normal, 70) // #b3b3b3
viorasTheme.colors.grey[80] = brighten(viorasTheme.colors.grey.normal, 80) // #ccc
viorasTheme.colors.grey[90] = brighten(viorasTheme.colors.grey.normal, 90) // #e6e6e6
viorasTheme.colors.grey[95] = brighten(viorasTheme.colors.grey.normal, 97) // #f6f6f6
viorasTheme.colors.grey[99] = brighten(viorasTheme.colors.grey.normal, 99) // #fcfcfc
viorasTheme.colors.primary.dark = brighten(
  viorasTheme.colors.primary.normal,
  -20
)
viorasTheme.colors.primary.light = opacify(
  viorasTheme.colors.primary.normal,
  0.2
)
viorasTheme.colors.primary.hover = brighten(
  viorasTheme.colors.primary.normal,
  -20
)
viorasTheme.colors.secondary.dark = brighten(
  viorasTheme.colors.secondary.normal,
  -20
)
viorasTheme.colors.secondary.light = opacify(
  viorasTheme.colors.secondary.normal,
  0.2
)
viorasTheme.colors.secondary.hover = brighten(
  viorasTheme.colors.secondary.normal,
  -20
)

export default viorasTheme
