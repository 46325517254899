// Importing Breadcrumbs directly causes an error
import {
  BreadcrumbsFromParam as Breadcrumbs,
  IBreadCrumb
} from "app/components/Breadcrumbs/BreadcrumbsFromParam"
import { useApi } from "app/hooks/api"
import { usePermission } from "app/hooks/permissions"
import { PartyType } from "app/models/parties"
import { PermissionEnum } from "app/models/permission"
import { getPartyParents } from "app/redux/groups/groups.actions"
import * as selectors from "app/redux/groups/groups.selectors"
import * as meSelectors from "app/redux/me/me.selectors"
import React, { useEffect } from "react"
import { useSelector } from "react-redux"

const GroupsBreadcrumb = () => {
  const { callApi } = useApi()
  const partyParents = useSelector(selectors.parents)
  const group = useSelector(selectors.groups)
  const myGroups = useSelector(meSelectors.myGroups)
  const loading = useSelector(selectors.isLoading)
  const parentsLoading = useSelector(selectors.parentsLoading)
  const groupId = group?.id
  const myGroupIds = myGroups?.map((g) => g.id)
  const { hasPermission } = usePermission()
  const isAdmin = hasPermission(PermissionEnum.ORGANIZATION_ADMIN)

  useEffect(() => {
    if (groupId) {
      callApi(getPartyParents, { party_id: groupId })
    }
  }, [groupId, callApi])

  if (loading || parentsLoading) return null

  const breadcrumbs: IBreadCrumb[] = partyParents
    .filter((p) => p.party_type !== PartyType.ORGANIZATION)
    .map((p) => {
      const { id, name } = p
      const crumb: IBreadCrumb = {
        id,
        key: id,
        label: name
      }

      if (isAdmin || myGroupIds?.includes(id)) {
        crumb.to = `/account/groups/${p.id}`
      }

      return crumb
    })
    .reverse()

  if (group?.name) {
    breadcrumbs.push({
      id: group.id,
      key: group.id,
      label: group.name
    })
  }

  return <Breadcrumbs breadcrumbs={breadcrumbs} />
}

export default GroupsBreadcrumb
