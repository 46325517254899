import People from "app/models/people"

import { Action, Types } from "./people.actions"

export type PeopleState = {
  errors: object
  editedField: string | undefined
  loading: boolean
  data: People[]
}

const initialState: PeopleState = {
  editedField: undefined,
  errors: {},
  data: [],
  loading: true,
}

export default (state = initialState, action: Action): PeopleState => {
  switch (action.type) {
    case Types.GET:
      return {
        ...state,
        errors: {},
        loading: true,
      }

    case Types.GET_SUCCESS:
      return {
        ...state,
        data: action.payload,
        loading: false,
      }

    case Types.GET_FAIL:
      const statusCode = action.payload.errors.response?.status
      const newState = {
        ...state,
        errors: action.payload.errors,
        loading: false,
      }

      if (statusCode === 403) {
        newState.data = []
      }

      return newState

    default:
      return state
  }
}
