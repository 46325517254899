import Role from "./roles"

export interface Organisation {
  party_id: string
  level: number
  parent_id: string
  name: string
  active_features?: ActiveFeaturesObj
  party_relation_id?: string
  roles?: Role[]
}

export type DailyCheckType = {
  current_type: "default" | "extended"
  current_updated_at: Date
  previous_type: "default" | "extended"
  previous_updated_at: Date
}

export enum FeaturesEnum {
  FEATURE_CUSTOM_FUNCTIONS = "organization_custom_functions",
  FEATURE_DAILY_CHECK_DASHBOARD = "organization_daily_check_dashboard",
  FEATURE_SURVEY_LEADERSHIP_DASHBOARD = "organization_survey_leadership_dashboard",
  FEATURE_SURVEY_CLIMATE_DASHBOARD = "organization_survey_climate_dashboard",
  FEATURE_DAILY_CHECK_EXTRA_NUANCES = "daily_check_extra_nuances",
  FEATURE_DAILY_CHECK_TYPE = "daily_check_type",
  FEATURE_RATIONALE = "organization_rationale",
}

export type ActiveFeaturesObj = {
  [FeaturesEnum.FEATURE_CUSTOM_FUNCTIONS]?: boolean
  [FeaturesEnum.FEATURE_DAILY_CHECK_DASHBOARD]?: boolean
  [FeaturesEnum.FEATURE_SURVEY_LEADERSHIP_DASHBOARD]?: boolean
  [FeaturesEnum.FEATURE_SURVEY_CLIMATE_DASHBOARD]?: boolean
  [FeaturesEnum.FEATURE_DAILY_CHECK_EXTRA_NUANCES]?: boolean
  [FeaturesEnum.FEATURE_DAILY_CHECK_TYPE]?: DailyCheckType
  [FeaturesEnum.FEATURE_RATIONALE]?: boolean
}
