import Divider from "@material-ui/core/Divider"
import styled from "@emotion/styled"
import { spacing } from "@material-ui/system"
import { BoxProps } from "@material-ui/core"

type Props = BoxProps & { backgroundColor?: string }

export const StyledDivider = styled(Divider)<Props>`
  ${spacing};
  height: 0.1rem;
  ${(props) =>
    props?.backgroundColor && `background-color: ${props.backgroundColor}`}
`

StyledDivider.defaultProps = {
  mb: 2,
  mt: 2
}
