import { Box } from "@material-ui/core"
import { IRouteComponent } from "app/routes"
import FunToWorkWithLogo from "assets/icons/FunToWorkWithLogo"
import React from "react"
import { LogoContainer, SurveyContent, LeadershipSurveyWrapper } from "../style"

export const LeadershipSurveyLayout: React.FC<IRouteComponent> = ({
  children
}) => {
  return (
    <LeadershipSurveyWrapper>
      <SurveyContent elevation={0}>
        <Box p={5}>{children}</Box>
      </SurveyContent>

      <LogoContainer>
        <FunToWorkWithLogo fontSize="inherit" />
      </LogoContainer>
    </LeadershipSurveyWrapper>
  )
}

export default LeadershipSurveyLayout
