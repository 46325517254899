import styled from "@emotion/styled"

export const TopNavigationWrapper = styled.div`
  display: flex;
  justify-content: space-between;
`

export const NavigationItemsWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 100%;
`

export const AvatarWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0 3rem 0 3rem;
`
