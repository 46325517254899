import { Action, Types } from "./events.actions"

export type EventState = {
  errors: object
  loading: boolean
}

const initialState: EventState = {
  errors: {},
  loading: false
}

export default (state = initialState, action: Action): EventState => {
  switch (action.type) {
    case Types.POST:
      return {
        ...state,
        loading: true
      }

    case Types.POST_SUCCESS:
      return {
        ...state,
        loading: false
      }

    case Types.POST_FAIL:
      return {
        ...state,
        errors: action.payload.errors,
        loading: false
      }

    default:
      return state
  }
}
