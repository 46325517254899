import styled from "@emotion/styled"
import { IStyled } from "style/theme"
import EditProfileDrawer from "../EditProfileDrawer"

type StyledEditProfileDrawerProps = {
  paperCoverFullBackground?: boolean
}

export const AppHeaderWrapper = styled.div`
  margin-bottom: ${({ theme }: IStyled) => theme.spacing(3)};
`

export const StyledEditProfileDrawer = styled(EditProfileDrawer)<
  StyledEditProfileDrawerProps
>`
  max-width: 55rem;

  & .MuiDrawer-paper {
    max-width: 55rem;
  }

  & .MuiBackdrop-root {
    max-width: ${(props) =>
      props.paperCoverFullBackground ? "100vw" : "55rem"};
  }
`
