import { Box, Typography } from "@material-ui/core"
import React from "react"
import { useSelector } from "react-redux"

import {
  REACT_APP_PRIVACY_POLICY_LINK,
  REACT_APP_TERMS_OF_USE_LINK,
} from "app/api/config"
import { useLogout } from "app/hooks/logout"
import * as selectors from "app/redux/me/me.selectors"

import Button from "components/Button"
import Divider from "components/Divider"
import Translation from "components/Translation"

import Close from "assets/icons/Close"

import Avatar from "../Avatar"
import { Link } from "../Link"
import { DropDownContent, DropdownWrapper } from "./style"

type Props = {
  handleClose: () => void
  setDrawerVisibility: (value: boolean) => void
}

const Dropdown = ({ handleClose, setDrawerVisibility }: Props) => {
  const { handleLogout } = useLogout()
  const me = useSelector(selectors.me)
  const meLoading = useSelector(selectors.loading)

  const closeDropdown = () => handleClose()
  const handleOpenProfileEdit = () => {
    setDrawerVisibility(true)
    handleClose()
  }

  const logOff = () => {
    handleClose()
    handleLogout()
  }
  const userName = (!meLoading || me) && `${me?.first_name} ${me?.last_name}`

  return (
    <DropdownWrapper>
      <DropDownContent>
        <Box display="flex" justifyContent="end">
          <Button variant="text" onClick={closeDropdown} size="small">
            <Close />
          </Button>
        </Box>
        <Box display="flex" mb={4}>
          <Box alignContent="start" mt={0.2}>
            <Avatar showName={false} />
          </Box>
          <Box ml={2} mb={1}>
            <Box mb={1}>
              <Typography variant="h3" component="h3">
                {userName}
              </Typography>
            </Box>
            <Box>
              <Link onClick={handleOpenProfileEdit}>
                <Translation label="profile.edit" />
              </Link>
            </Box>
          </Box>
        </Box>
        <Button fullWidth={true} label="action.logout" onClick={logOff} />
        <Divider mt={4} mb={3} />

        <Box mb={3}>
          <Typography variant="body2" component="p">
            <Translation label="content.terms_of_use.description" />
          </Typography>
          <Link to={{ pathname: REACT_APP_TERMS_OF_USE_LINK }} target="_blank">
            <Translation label="content.terms_of_use.link" />
          </Link>
        </Box>

        <Typography variant="body2" component="p">
          <Translation label="content.privacy_policy.description" />
        </Typography>
        <Link to={{ pathname: REACT_APP_PRIVACY_POLICY_LINK }} target="_blank">
          <Translation label="content.privacy_policy.link" />
        </Link>
      </DropDownContent>
    </DropdownWrapper>
  )
}

export default Dropdown
