import { Grid, SwipeableDrawerProps, Typography } from "@material-ui/core"
import { FormikConfig, useFormik } from "formik"
import { languages } from "i18n/config"
import React from "react"
import { useSelector } from "react-redux"

import { useApi } from "app/hooks/api"
import { patchMe } from "app/redux/me/me.actions"
import * as selectors from "app/redux/me/me.selectors"

import Button from "components/Button"
import Select from "components/Select"
import TextField from "components/TextField"
import Translation from "components/Translation"

import { isProdEnv, isQAEnv, isTestEnv } from "utils/environment"

import LanguageKeySwitch from "../LanguageKeySwitch"
import NavigationSwitch from "../NavigationSwitch"
import EditProfileHeader from "./EditProfileHeader"
import { EditProfileUser, getInitialValues, validationSchema } from "./config"
import {
  EditProfileForm,
  EditProfileFormSection,
  StyledSwipeableDrawer,
} from "./style"

export interface EditProfileDrawerProps
  extends Pick<SwipeableDrawerProps, "open" | "anchor"> {
  onClose: () => void
  showTopClose?: boolean
}

const EditProfileDrawer: React.FC<EditProfileDrawerProps> = ({
  onClose,
  open,
  anchor = "top",
  showTopClose = false,
  ...rest
}) => {
  const handleOpen = () => {
    return
  }
  const { callApi } = useApi()
  const me = useSelector(selectors.me)
  const isDispatched = useSelector(selectors.dispatched)
  const formConfig: FormikConfig<EditProfileUser> = {
    enableReinitialize: true,
    initialValues: getInitialValues(me),
    onSubmit: (v) => {
      callApi(patchMe, {
        data: {
          party_id: me!.party_id,
          ...v,
        },
      })
    },
    validationSchema,
  }

  const handleClose = () => {
    onClose()
  }

  const {
    values,
    handleChange,
    handleSubmit,
    isSubmitting,
    setSubmitting,
    isValid,
    errors,
  } = useFormik(formConfig)

  React.useEffect(() => {
    if (!open || !isSubmitting || !isDispatched) return

    setSubmitting(false)
    onClose()
  }, [open, isDispatched, isSubmitting, onClose, setSubmitting])

  return (
    <>
      <StyledSwipeableDrawer
        onClose={handleClose}
        onOpen={handleOpen}
        open={open}
        anchor={anchor}
        {...rest}
      >
        <EditProfileHeader
          onClose={onClose}
          showClose={anchor === "top" || showTopClose === true}
        />

        <EditProfileForm onSubmit={handleSubmit}>
          <Grid container direction="column" spacing={2}>
            <Grid item>
              <EditProfileFormSection>
                <Typography variant="h3" component="h3" gutterBottom>
                  <Translation label="profile.edit.personal_details" />
                </Typography>

                <TextField
                  name="first_name"
                  label="profile.edit.first_name"
                  value={values.first_name}
                  onChange={handleChange}
                  error={errors.first_name}
                />

                <TextField
                  name="last_name"
                  label="profile.edit.last_name"
                  value={values.last_name}
                  onChange={handleChange}
                  error={errors.last_name}
                />

                <TextField
                  disabled
                  name="email"
                  label="profile.edit.email"
                  value={values.email}
                />

                <TextField
                  name="phone_number"
                  label="profile.edit.phone_number"
                  value={values.phone_number}
                  onChange={handleChange}
                  error={errors.phone_number}
                />
              </EditProfileFormSection>
            </Grid>

            <Grid item>
              <EditProfileFormSection>
                <Typography variant="h3" component="h3" gutterBottom>
                  <Translation label="profile.edit.settings" />
                </Typography>

                <Select
                  options={languages}
                  name="pref_language"
                  label="profile.edit.language"
                  value={values.pref_language}
                  onChange={handleChange}
                  error={errors.pref_language}
                />
              </EditProfileFormSection>
            </Grid>

            {(isTestEnv || isQAEnv) && (
              <Grid item>
                <EditProfileFormSection>
                  <Typography variant="h3" component="h3" gutterBottom>
                    Experimental - visible only on TEST or QA.
                  </Typography>

                  <NavigationSwitch />
                  <LanguageKeySwitch />
                </EditProfileFormSection>
              </Grid>
            )}

            <Grid item container justify="center">
              <Button
                type="submit"
                label="profile.edit.submit"
                disabled={!isValid || isSubmitting}
              />
            </Grid>
          </Grid>
        </EditProfileForm>
      </StyledSwipeableDrawer>
    </>
  )
}

export default EditProfileDrawer
