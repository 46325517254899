import { Typography } from "@material-ui/core"
import React, { memo } from "react"
import { TitleWrapper, StyledPunctuation } from "./style"

type Props = {
  value: string | React.ReactNode
  punctuation?: "dot" | "exclamation"
}

const getPunctuation = (punctuation: string) => {
  switch (punctuation) {
    case "exclamation":
      return "!"
    default:
      return "."
  }
}

const Title = ({ value, punctuation = "dot" }: Props) => {
  return (
    <TitleWrapper>
      <Typography variant="h1" component="h1">
        {value}
        <StyledPunctuation>{getPunctuation(punctuation)}</StyledPunctuation>
      </Typography>
    </TitleWrapper>
  )
}

export default memo(Title)
