import { Action, Types } from "./nuances.actions"
import Nuance from "app/models/nuance"

export type NuancesState = {
  errors: object | undefined
  loading: boolean
  nuances: Nuance[]
  success: boolean
}

const initialState: NuancesState = {
  errors: undefined,
  loading: false,
  nuances: [],
  success: false
}

export default (state = initialState, action: Action): NuancesState => {
  switch (action.type) {
    case Types.GET:
      return {
        ...state,
        errors: undefined,
        loading: true
      }

    case Types.GET_SUCCESS:
      return {
        ...state,
        loading: false,
        nuances: action.payload
      }

    case Types.GET_FAIL:
      return {
        ...state,
        loading: false,
        nuances: []
      }

    case Types.PUT:
      return {
        ...state,
        loading: true,
        success: false
      }

    case Types.PUT_SUCCESS:
      return {
        ...state,
        loading: false,
        nuances: [...state.nuances, action.payload],
        success: true
      }

    case Types.PUT_FAIL:
      return {
        ...state,
        errors: action.payload.errors,
        loading: false
      }

    case Types.ARCHIVE:
      return {
        ...state,
        loading: true
      }

    case Types.ARCHIVE_SUCCESS:
      return {
        ...state,
        loading: false
      }

    case Types.ARCHIVE_FAIL:
      return {
        ...state,
        errors: action.payload.errors,
        loading: false
      }

    case Types.CLEAR_SUCCESS:
      return {
        ...state,
        errors: undefined,
        success: false
      }

    case Types.DELETE_SUCCESS:
      return {
        ...state,
        nuances: []
      }

    default:
      return state
  }
}
