import { funToWorkWithTheme } from "style/theme"
import { ButtonClassKey } from "@material-ui/core"
import { PropsFunc } from "../types"
import {
  CSSProperties,
  CreateCSSProperties
} from "@material-ui/core/styles/withStyles"

type Button = Partial<
  Record<
    ButtonClassKey,
    | CSSProperties
    | CreateCSSProperties<{}>
    | PropsFunc<{}, CreateCSSProperties<{}>>
  >
>

export const button: Button = {
  root: {
    fontSize: "2rem",
    fontWeight: "bold",
    lineHeight: "2.4rem",
    padding: "1.6rem 4rem",
    textTransform: "uppercase",
    borderRadius: "unset"
  },

  contained: {
    boxShadow: "unset"
  },

  containedPrimary: {
    "&:hover": {
      backgroundColor: funToWorkWithTheme.colors.accent1,
      boxShadow: "unset"
    },
    "&.Mui-disabled": {
      backgroundColor: funToWorkWithTheme.colors.primaryLight,
      color: funToWorkWithTheme.colors.white
    }
  },

  containedSecondary: {
    "&:hover": {
      backgroundColor: funToWorkWithTheme.colors.accent3,
      boxShadow: "unset"
    },
    "&.Mui-disabled": {
      backgroundColor: funToWorkWithTheme.colors.primaryLight,
      color: funToWorkWithTheme.colors.white
    }
  },

  outlinedPrimary: {
    padding: "1.6rem 4rem",
    border: `2px solid ${funToWorkWithTheme.colors.primary}`,
    "&:hover": {
      backgroundColor: funToWorkWithTheme.colors.accent3,
      boxShadow: "unset",
      border: `2px solid ${funToWorkWithTheme.colors.primary}`
    },
    "&.Mui-disabled": {
      backgroundColor: funToWorkWithTheme.colors.primaryLight,
      color: funToWorkWithTheme.colors.white,
      border: "unset"
    }
  }
}
