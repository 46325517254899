import { Box, Drawer, Theme, createStyles, makeStyles } from "@material-ui/core"
import React from "react"

import { isTestEnv } from "utils/environment"

import FunToWorkWithLogoWithText from "assets/icons/FuntToWorkWithLogoWithText"

import LanguageKeySwitch from "../LanguageKeySwitch"
import NavigationSwitch from "../NavigationSwitch"
import { SideNavigation } from "./SideNavigation"
import { SidebarSection, SidebarWrapper } from "./style"

const drawerWidth = "28.8rem"
const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    drawer: {
      width: drawerWidth,
    },
    drawerPaper: {
      width: drawerWidth,
    },
  }),
)

export const SidebarNew: React.FC = () => {
  const classes = useStyles()

  return (
    <Drawer
      variant="permanent"
      anchor="left"
      className={classes.drawer}
      classes={{ paper: classes.drawerPaper }}
    >
      <SidebarWrapper>
        <SidebarSection>
          <Box display="flex" justifyContent="center" paddingTop="4rem">
            <FunToWorkWithLogoWithText style={{ fontSize: "12rem" }} />
          </Box>
        </SidebarSection>
        <SidebarSection>
          <SideNavigation />
        </SidebarSection>
        {isTestEnv && (
          <SidebarSection>
            <Box paddingX="5rem">
              <NavigationSwitch />
              <LanguageKeySwitch />
            </Box>
          </SidebarSection>
        )}
      </SidebarWrapper>
    </Drawer>
  )
}
