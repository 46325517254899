import JsSurvey, { SurveyJsPayload } from "app/models/surveyjs"

export enum Types {
  POST = "SURVEYJS_POST",
  POST_SUCCESS = "SURVEYJS_POST_SUCCESS",
  POST_SUCCESS_CALLBACK = "SURVEYJS_POST_SUCCESS_CALLBACK",
  POST_FAIL = "SURVEYJS_POST_FAIL",
  GET = "SURVEYJS_GET",
  GET_SUCCESS = "SURVEYJS_GET_SUCCESS",
  GET_FAIL = "SURVEYJS_GET_FAIL"
}

export type Action =
  | GetJsSurveyAction
  | GetJsSurveySuccessAction
  | GetJsSurveyFailAction
  | PostJsSurveyAction
  | PostJsSurveySuccessAction
  | PostJsSurveySuccessCallbackAction
  | PostJsSurveyFailAction

export type GetJsSurveyAction = {
  payload: SurveyJsPayload
  type: Types.GET
}

export type GetJsSurveySuccessAction = {
  payload: JsSurvey[]
  type: Types.GET_SUCCESS
}

export type GetJsSurveyFailAction = {
  payload: { errors: object }
  type: Types.GET_FAIL
}

export type PostJsSurveyAction = {
  payload: SurveyJsPayload
  type: Types.POST
}

export type PostJsSurveySuccessAction = {
  type: Types.POST_SUCCESS
}

export type PostJsSurveySuccessCallbackAction = {
  type: Types.POST_SUCCESS_CALLBACK
}

export type PostJsSurveyFailAction = {
  payload: { errors: object }
  type: Types.POST_FAIL
}

export function getJsSurvey(data: SurveyJsPayload): GetJsSurveyAction {
  return {
    payload: data,
    type: Types.GET
  }
}

export function getJsSurveySuccess({
  data
}: {
  data: JsSurvey[]
}): GetJsSurveySuccessAction {
  return {
    payload: data,
    type: Types.GET_SUCCESS
  }
}

export function getJsSurveyFail(errors: object): GetJsSurveyFailAction {
  return {
    payload: { errors },
    type: Types.GET_FAIL
  }
}

export function postJsSurvey(payload: SurveyJsPayload): PostJsSurveyAction {
  return {
    payload,
    type: Types.POST
  }
}

export function postJsSurveySuccess(): PostJsSurveySuccessAction {
  return {
    type: Types.POST_SUCCESS
  }
}

export function postJsSurveySuccessCallback(): PostJsSurveySuccessCallbackAction {
  return {
    type: Types.POST_SUCCESS_CALLBACK
  }
}

export function postJsSurveyFail(errors: object): PostJsSurveyFailAction {
  return {
    payload: { errors },
    type: Types.POST_FAIL
  }
}
