import { Typography } from "@material-ui/core"
import { ENV_FEATURE_FLAG } from "enums"
import React, { memo } from "react"
import { FormattedMessage } from "react-intl"

import applyFeature from "utils/applyFeature"

export type TranslationProps = {
  label: string
  values?: {}
}

type Props = TranslationProps

const Translation = ({ label, values }: Props) => {
  const isLanguageKeyVisible = applyFeature(
    ENV_FEATURE_FLAG.IS_LANGUAGE_KEY_VISIBLE,
  )

  return label ? (
    <>
      {isLanguageKeyVisible ? (
        <div style={{ display: "block" }}>
          <div>
            <FormattedMessage
              id={label}
              defaultMessage={label}
              values={values}
            />
          </div>
          <div>
            <Typography variant="caption" style={{ color: "green" }}>
              {label}
            </Typography>
          </div>
        </div>
      ) : (
        <FormattedMessage id={label} defaultMessage={label} values={values} />
      )}
    </>
  ) : (
    <></>
  )
}

export default memo(Translation)
