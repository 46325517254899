import { TokenPayload } from "./token"

export enum RelationTypes {
  PERSON_TO_GROUP = "person_to_group",
  PERSON_TO_ORGANIZATION = "person_to_organization",
  GROUP_TO_ORGANIZATION = "group_to_organization",
  GROUP_TO_GROUP = "group_to_group"
}

export enum PartyType {
  ORGANIZATION = "organization",
  GROUP = "group",
  PERSON = "person"
}

export interface PartyPayload extends Partial<TokenPayload> {
  party_id: string
}

export interface PartyChildrenPayload extends PartyPayload {
  depth?: number
  limit?: number
}

export interface PartyParent {
  child_id: string
  id: string
  parent_id: string
  user_id: string
  party_type: PartyType
  created_at: string
  is_deleted?: boolean
  deleted_on: string
  name: string
  level: number
}

export type PartyParents = PartyParent[]

export interface PartyRelation {
  id: string
  relation_type: RelationTypes
  party_type: RelationTypes
  first_name: string
  last_name: string
  group_name: string
  organization_name: string
}
