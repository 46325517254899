import State from "../state"
import {
  Organisation,
  FeaturesEnum,
  ActiveFeaturesObj
} from "app/models/organisation"

export const dispatched = (state: State): boolean =>
  state.organisations.dispatched
export const loading = (state: State): boolean => state.organisations.loading
export const organisation = (state: State): Organisation | undefined => {
  if (state.organisations.organisation?.hasOwnProperty("parent_id")) {
    return state.organisations.organisation as Organisation
  }
}
export const name = (state: State): string | undefined =>
  state.organisations.organisation?.name
export const errors = (state: State): object => state.organisations.errors

export const activeFeatures = (state: State): ActiveFeaturesObj | undefined => {
  const currentOrganisation = organisation(state)
  return currentOrganisation?.active_features
}

export const hasFeature = (
  state: State,
  feature: FeaturesEnum
): boolean | string | Record<string, unknown> => {
  const currentActiveFeatures = activeFeatures(state)
  const feat = currentActiveFeatures && currentActiveFeatures[feature]
  if (feat) {
    return feat
  }
  return false
}
