import { AxiosError } from "axios"
import { SagaIterator } from "redux-saga"
import { call, put, takeLatest } from "redux-saga/effects"

import * as api from "app/api/users"

import * as meActions from "../me/me.actions"
import { showMessage } from "../message/message.actions"
import * as actions from "./teams.actions"

export function* getTeamsFlow(action: actions.GetTeamsAction): SagaIterator {
  const { payload } = action
  try {
    const { data } = yield call(api.getTeams, payload)
    yield put(actions.getTeamsSuccess(data))
  } catch (e) {
    yield put(actions.getTeamsFail(e as AxiosError))
  }
}

export function* patchTeamFlow(action: actions.PatchTeamAction): SagaIterator {
  try {
    yield call(api.patchGroups, action.payload)
    yield put(actions.patchTeamSuccess(action.payload))
    yield put(
      actions.getTeams({
        group_id: action.payload.parent_id,
        token: action.payload.token!,
      }),
    )
    yield put(
      meActions.getMyGroups({
        sort: ["name=asc"],
        token: action.payload.token,
      }),
    )
  } catch (e) {
    const error = e as AxiosError
    if (error.response?.status === 400) {
      yield put(showMessage("groups.patch.name.error.message"))
    }
    yield put(actions.patchTeamFail(error))
  }
}

export default function* teamSaga(): SagaIterator {
  yield takeLatest(actions.Types.GET_ALL, getTeamsFlow)
  yield takeLatest(actions.Types.PATCH, patchTeamFlow)
}
