import React from "react"

import LoadingIndicator from "components/LoadingIndicator"

import { LoaderWrapper } from "./style"

const Loader = () => {
  return (
    <LoaderWrapper>
      <LoadingIndicator />
    </LoaderWrapper>
  )
}

export default Loader
