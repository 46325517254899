import { Box } from "@material-ui/core"
import React from "react"
import { useSelector } from "react-redux"
import { matchPath, useLocation, useParams } from "react-router"
import { validatePermissions } from "utils"

import { ITopBarRoute_new, useNavigation } from "app/context/NavigationContext"
import { PermissionEnum } from "app/models/permission"
import { partyRelations } from "app/redux/partyRelations/partyRelations.selectors"

import NavigationItem from "./NavigationItem"
import { SidebarNav } from "./style"

const getNavigationItemToPath = (
  to: string | undefined,
  locationPathname: string,
) => {
  if (to?.includes(":id")) {
    return to.replace(":id", locationPathname.split("/")?.[2])
  }
  return to || ""
}

export const Navigation: React.FC = () => {
  const location = useLocation()
  const { navigation = [] } = useNavigation()
  const { id } = useParams<{ id: string }>()
  const parties = useSelector(partyRelations)
  const findNavigationById = (id?: string): ITopBarRoute_new[] | undefined => {
    const result = navigation.find((n) => n.id === id)
    return result?.routes
  }

  const navigationRoute = navigation.find(({ to }) => {
    if (!to) return false

    const match = matchPath(to, {
      exact: false,
      path: location.pathname.split("/", 2).join("/"),
    })

    return !!match
  })

  const navigationKey = navigationRoute ? navigationRoute.id : undefined
  const currentNavigation = findNavigationById(navigationKey)

  if (!currentNavigation) return <></>

  const [currentParty] = parties.filter(({ parent_id }) => parent_id === id)
  const allUserPermissions = parties.map((el) => el.permissions?.flat())?.flat()
  const permissionsForParty =
    !id ||
    allUserPermissions.includes(PermissionEnum.ORGANIZATION_ADMIN) ||
    allUserPermissions.includes(PermissionEnum.ORGANIZATION_OWNER)
      ? allUserPermissions
      : currentParty?.permissions?.flat()

  const navigationItems = currentNavigation?.map((el) => {
    const { label, routes, dividerLeft, to, permissions } = el
    const navigationItemToPath = getNavigationItemToPath(to, location?.pathname)

    if (!validatePermissions(permissions, permissionsForParty)) return
    return (
      <NavigationItem
        to={navigationItemToPath}
        label={label}
        routes={routes || []}
        dividerLeft={dividerLeft || false}
      />
    )
  })

  return (
    <>
      <SidebarNav>
        <Box display="flex">{navigationItems}</Box>
      </SidebarNav>
    </>
  )
}
