import { AxiosError } from "axios"

import { Team } from "app/models/team"

import { Action, Types } from "./teams.actions"

export type TeamsState = {
  error?: AxiosError | null
  loading: boolean
  dispatched: boolean
  data: Team[]
}

const initialState: TeamsState = {
  data: [],
  dispatched: false,
  loading: true,
  error: null,
}

export default (state = initialState, action: Action): TeamsState => {
  switch (action.type) {
    case Types.GET_ALL:
      return {
        ...state,
        loading: true,
        data: [],
      }

    case Types.GET_ALL_SUCCESS:
      return {
        ...state,
        data: action.payload,
        loading: false,
      }

    case Types.PATCH:
      return {
        ...state,
        error: null,
      }

    case Types.PATCH_FAIL:
      return {
        ...state,
        error: action.payload,
      }

    case Types.GET_ALL_FAIL:
    default:
      return state
  }
}
