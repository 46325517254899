import { Nodes } from "app/models/nodes"

import { Action, Types } from "./nodes.actions"

export type NodesState = {
  errors?: {}
  loading: boolean
  dispatched: boolean
  data: Nodes | null
  activeId: string | null
}

const initialState: NodesState = {
  data: null,
  dispatched: false,
  loading: true,
  activeId: null,
}

export default (state = initialState, action: Action): NodesState => {
  switch (action.type) {
    case Types.GET_MY_NODES:
      return {
        ...state,
        data: null,
        loading: true,
      }

    case Types.GET_MY_NODES_SUCCESS:
      return {
        ...state,
        data: action.payload,
        loading: false,
      }

    case Types.GET_TEAM_NODES:
      return {
        ...state,
        data: null,
        loading: true,
      }

    case Types.GET_TEAM_NODES_SUCCESS:
      return {
        ...state,
        data: action.payload,
        loading: false,
      }

    case Types.SET_ACTIVE_ID:
      return {
        ...state,
        activeId: action.payload.id,
        loading: false,
      }
    default:
      return state
  }
}
