import Group, {
  GroupPayload,
  PatchGroupPayload
} from "app/models/groups"
import { PartyPayload, PartyParents } from "app/models/parties"
import { TokenPayload } from "app/models/token"
import {
  PatchUserMetaSuccessPayload,
  PostUserMetaSuccessPayload,
  DeleteMetaPayload
} from "app/models/user"
import { AxiosError } from "axios"

export enum Types {
  GET = "GROUPS_GET",
  REFRESH_GROUPS = "GROUPS_REFRESH_GROUPS",
  GET_SUCCESS = "GROUPS_GET_SUCCESS",
  GET_FAIL = "GROUPS_GET_FAIL",
  PATCH = "GROUPS_PATCH",
  PATCH_SUCCESS = "GROUPS_PATCH_SUCCESS",
  PATCH_FAIL = "GROUPS_PATCH_FAIL",
  POST = "GROUPS_POST",
  POST_SUCCESS = "GROUPS_POST_SUCCESS",
  POST_FAIL = "GROUPS_POST_FAIL",
  GET_PARENTS = "PARTY_PARENTS_GET",
  GET_PARENTS_SUCCESS = "PARTY_PARENTS_GET_SUCCESS",
  GET_PARENTS_FAIL = "PARTY_PARENTS_GET_FAIL",
  SET_EDITED = "GROUPS_SET_EDITED",
  PATCH_META_SUCCESS = "GROUPS_PATCH_META_SUCCESS",
  POST_META_SUCCESS = "GROUPS_POST_META_SUCCESS",
  DELETE_META_SUCCESS = "GROUPS_DELETE_META_SUCCESS",
  POST_ROLE_SUCCESS = "GROUPS_POST_META_SUCCESS"
}

export type Action =
  | GetGroupsAction
  | RefreshGroupsAction
  | GetGroupsSuccessAction
  | GetGroupsFailAction
  | PostGroupsAction
  | PostGroupsSuccessAction
  | PostGroupsFailAction
  | PatchGroupsAction
  | PatchGroupsSuccessAction
  | PatchGroupsFailAction
  | GetPartyParentsAction
  | GetPartyParentsSuccessAction
  | GetPartyParentsFailAction
  | SetGroupEditAction
  | PatchMetaSuccessAction
  | PostMetaSuccessAction
  | DeleteMetaSuccessAction

export type GetGroupsAction = {
  payload: GroupPayload
  type: Types.GET
}

export type RefreshGroupsAction = {
  payload: TokenPayload
  type: Types.REFRESH_GROUPS
}

export type GetGroupsSuccessAction = {
  payload: Group | undefined
  type: Types.GET_SUCCESS
}

export type GetGroupsFailAction = {
  payload: { errors: AxiosError }
  type: Types.GET_FAIL
}

export type PatchGroupsAction = {
  payload: PatchGroupPayload
  type: Types.PATCH
}

export type PatchGroupsSuccessAction = {
  payload: { name: string; party_id: string }
  type: Types.PATCH_SUCCESS
}

export type PatchGroupsFailAction = {
  payload: { errors: object }
  type: Types.PATCH_FAIL
}

export type PostGroupsAction = {
  payload: GroupPayload
  type: Types.POST
}

export type PostGroupsSuccessAction = {
  type: Types.POST_SUCCESS
}

export type PostGroupsFailAction = {
  payload: { errors: object }
  type: Types.POST_FAIL
}

export type GetPartyParentsAction = {
  payload: PartyPayload
  type: Types.GET_PARENTS
}

export type GetPartyParentsSuccessAction = {
  payload: PartyParents
  type: Types.GET_PARENTS_SUCCESS
}

export type GetPartyParentsFailAction = {
  payload: { errors: object }
  type: Types.GET_PARENTS_FAIL
}

export type SetGroupEditAction = {
  payload: { id: string | undefined }
  type: Types.SET_EDITED
}

export type PatchMetaSuccessAction = {
  payload: PatchUserMetaSuccessPayload
  type: Types.PATCH_META_SUCCESS
}

export type PostMetaSuccessAction = {
  payload: PostUserMetaSuccessPayload
  type: Types.POST_META_SUCCESS
}

export type DeleteMetaSuccessAction = {
  payload: DeleteMetaPayload
  type: Types.DELETE_META_SUCCESS
}

export function getGroups(data: GroupPayload): GetGroupsAction {
  return {
    payload: data,
    type: Types.GET
  }
}

export function refreshGroups(payload: TokenPayload): RefreshGroupsAction {
  return {
    payload,
    type: Types.REFRESH_GROUPS
  }
}

export function getGroupsSuccess(
  data: Group | undefined
): GetGroupsSuccessAction {
  return {
    payload: data,
    type: Types.GET_SUCCESS
  }
}

export function getGroupsFail(errors: AxiosError): GetGroupsFailAction {
  return {
    payload: { errors },
    type: Types.GET_FAIL
  }
}

export function patchGroups(data: PatchGroupPayload): PatchGroupsAction {
  return {
    payload: data,
    type: Types.PATCH
  }
}

export function patchGroupsSuccess({
  name,
  party_id
}: {
  name: string
  party_id: string
}): PatchGroupsSuccessAction {
  return {
    payload: { name, party_id },
    type: Types.PATCH_SUCCESS
  }
}

export function patchGroupsFail(errors: object): PatchGroupsFailAction {
  return {
    payload: { errors },
    type: Types.PATCH_FAIL
  }
}

export function postGroups(data: GroupPayload): PostGroupsAction {
  return {
    payload: data,
    type: Types.POST
  }
}

export function postGroupsSuccess(): PostGroupsSuccessAction {
  return {
    type: Types.POST_SUCCESS
  }
}

export function postGroupsFail(errors: object): PostGroupsFailAction {
  return {
    payload: { errors },
    type: Types.POST_FAIL
  }
}

export function getPartyParents(data: PartyPayload): GetPartyParentsAction {
  return {
    payload: data,
    type: Types.GET_PARENTS
  }
}

export function getPartyParentsSuccess(
  data: PartyParents
): GetPartyParentsSuccessAction {
  return {
    payload: data,
    type: Types.GET_PARENTS_SUCCESS
  }
}

export function getPartyParentsFail(errors: object): GetPartyParentsFailAction {
  return {
    payload: { errors },
    type: Types.GET_PARENTS_FAIL
  }
}

export function setGroupEdit(id: string | undefined): SetGroupEditAction {
  return {
    payload: { id },
    type: Types.SET_EDITED
  }
}

export function patchMetaSuccess(
  data: PatchUserMetaSuccessPayload
): PatchMetaSuccessAction {
  return {
    payload: data,
    type: Types.PATCH_META_SUCCESS
  }
}

export function postMetaSuccess(
  data: PostUserMetaSuccessPayload
): PostMetaSuccessAction {
  return {
    payload: data,
    type: Types.POST_META_SUCCESS
  }
}

export function deleteMetaSuccess(
  payload: DeleteMetaPayload
): DeleteMetaSuccessAction {
  return {
    payload,
    type: Types.DELETE_META_SUCCESS
  }
}
