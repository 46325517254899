import {
  DeletePartyRelationPayload,
  PartyRelation,
  PartyRelationPayload,
} from "app/models/partyRelation"

export enum Types {
  GET = "PARTY_RELATIONS_GET",
  GET_SUCCESS = "PARTY_RELATIONS_GET_SUCCESS",
  GET_FAIL = "PARTY_RELATIONS_GET_FAIL",
  DELETE = "PARTY_RELATIONS_DELETE",
  DELETE_SUCCESS = "PARTY_RELATIONS_DELETE_SUCCESS",
  DELETE_FAIL = "PARTY_RELATIONS_DELETE_FAIL",
  TOGGLE_DELETE_CONFIRMATION = "PARTY_RELATIONS_TOGGLE_DELETE_CONFIRMATION",
  TOGGLE_DELETE_MULTIPLE_CONFIRMATION = "PARTY_RELATIONS_TOGGLE_DELETE_MULTIPLE_CONFIRMATION",
  TOGGLE_DELETE_SUCCESS_MESSAGE = "PARTY_RELATIONS_TOGGLE_DELETE_SUCCESS_MESSAGE",
}

export type Action =
  | GetPartyRelationsAction
  | GetPartyRelationsSuccessAction
  | GetPartyRelationsFailAction
  | ToggleDeleteConfirmationAction
  | ToggleDeleteMultipleConfirmationAction
  | DeletePartyRelationAction
  | DeletePartyRelationSuccessAction
  | DeletePartyRelationFailAction

export type GetPartyRelationsAction = {
  payload: PartyRelationPayload
  type: Types.GET
}

export type GetPartyRelationsSuccessAction = {
  payload: PartyRelation[]
  type: Types.GET_SUCCESS
}

export type GetPartyRelationsFailAction = {
  payload: { errors: object }
  type: Types.GET_FAIL
}

export type DeletePartyRelationAction = {
  payload: DeletePartyRelationPayload
  type: Types.DELETE
}

export type DeletePartyRelationSuccessAction = {
  payload: string
  type: Types.DELETE_SUCCESS
}

export type DeletePartyRelationFailAction = {
  payload: { errors: object }
  type: Types.DELETE_FAIL
}

export type ToggleDeleteConfirmationAction = {
  payload: string
  type: Types.TOGGLE_DELETE_CONFIRMATION
}

export type ToggleDeleteMultipleConfirmationAction = {
  payload: string[]
  type: Types.TOGGLE_DELETE_MULTIPLE_CONFIRMATION
}

export function getPartyRelations(
  payload: PartyRelationPayload,
): GetPartyRelationsAction {
  return {
    payload,
    type: Types.GET,
  }
}

export function getPartyRelationsSuccess(
  payload: PartyRelation[],
): GetPartyRelationsSuccessAction {
  return {
    payload,
    type: Types.GET_SUCCESS,
  }
}

export function getPartyRelationsFail(
  errors: object,
): GetPartyRelationsFailAction {
  return {
    payload: { errors },
    type: Types.GET_FAIL,
  }
}

export function deletePartyRelation(
  payload: DeletePartyRelationPayload,
): DeletePartyRelationAction {
  return {
    payload,
    type: Types.DELETE,
  }
}

export function deletePartyRelationSuccess(
  payload: string,
): DeletePartyRelationSuccessAction {
  return {
    payload,
    type: Types.DELETE_SUCCESS,
  }
}

export function deletePartyRelationFail(
  errors: object,
): DeletePartyRelationFailAction {
  return {
    payload: { errors },
    type: Types.DELETE_FAIL,
  }
}

export function toggleDeleteConfirmation(
  payload: string = "",
): ToggleDeleteConfirmationAction {
  return {
    payload,
    type: Types.TOGGLE_DELETE_CONFIRMATION,
  }
}

export function toggleDeleteMultipleConfirmation(
  payload: string[] = [""],
): ToggleDeleteMultipleConfirmationAction {
  return {
    payload,
    type: Types.TOGGLE_DELETE_MULTIPLE_CONFIRMATION,
  }
}
