import React from "react"

type ErrorBoundaryProps = {
  element: any
}

type ErrorBoundaryState = {
  error: any
}

export class ErrorBoundary extends React.Component<
  ErrorBoundaryProps,
  ErrorBoundaryState
> {
  constructor(props: any) {
    super(props)
    this.state = { error: null }
  }

  static getDerivedStateFromError(error: any) {
    return { error }
  }

  componentDidCatch(error: any, errorInfo: any) {
    console.error(error, errorInfo)
  }

  render() {
    const Element = this.props.element
    if (this.state.error) {
      return <Element error={this.state.error} />
    }
    return this.props.children
  }
}
