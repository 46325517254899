export enum PermissionEnum {
  GROUP_UPDATE = "account:group:update",
  GROUP_READ = "account:group:read",
  GROUP_CREATE = "account:group:create",
  ORGANIZATION_READ = "account:organization:read",
  FUNCTION_READ = "account:function:read",
  FUNCTION_CREATE = "account:function:create",
  FUNCTION_UPDATE = "account:function:update",
  FUNCTION_DELETE = "account:function:delete",
  INVITE_CREATE = "account:invite:create",
  PARTY_CHILDREN_READ = "account:party:children:read",
  PARTY_SETTINGS_READ = "account:party:setting:read",
  PARTY_SETTINGS_CREATE = "account:party:setting:create",
  PARTY_SETTINGS_UPDATE = "account:party:setting:update",
  PARTY_RELATION_DELETE = "account:party_relation:delete",
  PARTY_RELATION_METADATA_READ = "account:party_relation:metadata:read",
  PARTY_RELATION_METADATA_CREATE = "account:party_relation:metadata:create",
  PARTY_RELATION_METADATA_UPDATE = "account:party_relation:metadata:update",
  PARTY_RELATION_METADATA_DELETE = "account:party_relation:metadata:delete",
  PEOPLE_READ = "account:people:read",
  NUANCE_CREATE = "account:nuance:create",
  NUANCE_UPDATE = "account:nuance:update",
  NUANCE_READ = "account:nuance:read",
  NUANCE_DELETE = "account:nuance:delete",
  SURVEY_READ = "account:survey:read",
  SURVEY_CREATE = "account:survey:create",
  SURVEY_UPDATE = "account:survey:update",
  SURVEY_DELETE = "account:survey:delete",
  SURVEYJS_READ = "account:surveyjs:read",
  SURVEYJS_CREATE = "account:surveyjs:create",
  SURVEYJS_DELETE = "account:surveyjs:delete",
  DAILY_CHECK_TREE_READ = "account:daily_check:tree:read",
  REALITY_CHECK_TREE_READ = "account:reality_check:tree:read",
  DAILY_CHECK_READ = "account:daily_check:read",
  DAILY_CHECK_CREATE = "account:daily_check:create",
  ROLES_READ = "account:roles:read",
  GROUP_ROLES_CREATE = "account:group:roles:create",
  GROUP_ROLES_DELETE = "account:group:roles:delete",
  ORGANIZATION_ADMIN = "account:organization:admin",
  MANAGE_PEOPLE_FUNCTIONS = "account:function:people:manage",
  ORGANIZATION_OWNER = "account:organization:owner",
}
