import { SagaIterator } from "redux-saga"
import { call, put, takeLatest } from "redux-saga/effects"

import * as api from "app/api/users"

import { getMyGroups } from "../me/me.actions"
import { showMessage } from "../message/message.actions"
import { getPeople } from "../people/people.actions"
import { getGroups } from "./../groups/groups.actions"
import * as actions from "./invites.actions"

export function* postInvitesFlow(
  action: actions.PostInvitesAction,
): SagaIterator {
  try {
    const { token, data, party_id, organisation_id, teamId } = action.payload
    for (const invitedPerson of data) {
      yield call(api.postInvite, { ...invitedPerson, party_id })
    }
    yield put(getGroups({ token, id: party_id }))
    //getPeople is for the new team member overview, getGroups should be deleted when we swap on production
    yield put(
      getPeople({
        organisation_id: organisation_id!,
        team_id: teamId,
        token: token,
      }),
    )
    yield put(getMyGroups({ sort: ["name=asc"] }))
    yield put(actions.postInvitesSuccess())
    yield put(actions.toggleInvitationModal())
  } catch (e) {
    yield put(showMessage("user.error.already.exists"))
    yield put(actions.postInvitesFail(e))
  }
}

export default function* invitesSaga(): SagaIterator {
  yield takeLatest(actions.Types.POST, postInvitesFlow)
}
