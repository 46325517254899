import React from "react"
import { IconButton, Slide, Snackbar as MuiSnackbar } from "@material-ui/core"
import Translation from "components/Translation"
import { TranslationProps } from "components/Translation/Translation"
import CloseIcon from "@material-ui/icons/Close"

type Props = {
  onClose: () => void
  message: TranslationProps
}

function SlideTransition(props: any) {
  return <Slide {...props} direction="down" />
}

const Snackbar = ({ onClose, message }: Props) => (
  <MuiSnackbar
    open
    anchorOrigin={{ vertical: "top", horizontal: "center" }}
    onClose={onClose}
    TransitionComponent={SlideTransition}
    message={<Translation {...message} />}
    action={
      <IconButton size="small" aria-label="close" color="inherit" onClick={onClose}>
        <CloseIcon fontSize="small" />
      </IconButton>
    }
  />
)

export default Snackbar
