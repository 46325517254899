import PersonFunction, {
  GetFunctionsPayload,
  PostFunctionPayload,
  PostFunction,
  PatchFunctionPayload,
  PatchFunctionSuccessPayload,
  DeleteFunctionPayload
} from "app/models/functions"

export enum Types {
  GET = "FUNCTIONS_GET",
  GET_SUCCESS = "FUNCTIONS_GET_SUCCESS",
  GET_FAIL = "FUNCTIONS_GET_FAIL",
  PATCH = "FUNCTIONS_PATCH",
  PATCH_SUCCESS = "FUNCTIONS_PATCH_SUCCESS",
  PATCH_FAIL = "FUNCTIONS_PATCH_FAIL",
  POST = "FUNCTIONS_POST",
  POST_SUCCESS = "FUNCTIONS_POST_SUCCESS",
  POST_FAIL = "FUNCTIONS_POST_FAIL",
  DELETE = "FUNCTIONS_DELETE",
  DELETE_SUCCESS = "FUNCTIONS_DELETE_SUCCESS",
  DELETE_FAIL = "FUNCTIONS_DELETE_FAIL",
  TOGGLE_MODAL = "TOGGLE_FUNCTION_MODAL",
  TOGGLE_DELETE_MODAL = "TOGGLE_FUNCTION_DELETE_MODAL",
  SET_EDITED = "FUNCTIONS_SET_EDITED"
}

export type Action =
  | GetFunctionsAction
  | GetFunctionsSuccessAction
  | GetFunctionsFailAction
  | PostFunctionsAction
  | PostFunctionsSuccessAction
  | PostFunctionsFailAction
  | PatchFunctionsAction
  | PatchFunctionsSuccessAction
  | PatchFunctionsFailAction
  | DeleteFunctionsAction
  | DeleteFunctionsSuccessAction
  | DeleteFunctionsFailAction
  | ToggleFunctionModalAction
  | ToggleFunctionDeleteModalAction
  | SetFunctionEditAction

export type GetFunctionsAction = {
  payload: GetFunctionsPayload
  type: Types.GET
}

export type GetFunctionsSuccessAction = {
  payload: PersonFunction[]
  type: Types.GET_SUCCESS
}

export type GetFunctionsFailAction = {
  payload: { errors: object }
  type: Types.GET_FAIL
}

export type PatchFunctionsAction = {
  payload: PatchFunctionPayload
  type: Types.PATCH
}

export type PatchFunctionsSuccessAction = {
  payload: PatchFunctionSuccessPayload
  type: Types.PATCH_SUCCESS
}

export type PatchFunctionsFailAction = {
  payload: { errors: object }
  type: Types.PATCH_FAIL
}

export type SetFunctionEditAction = {
  payload: { id: string | undefined }
  type: Types.SET_EDITED
}

export type PostFunctionsAction = {
  payload: PostFunctionPayload
  type: Types.POST
}

export type PostFunctionsSuccessAction = {
  payload: PostFunction
  type: Types.POST_SUCCESS
}

export type PostFunctionsFailAction = {
  payload: { errors: object }
  type: Types.POST_FAIL
}

export type DeleteFunctionsAction = {
  payload: DeleteFunctionPayload
  type: Types.DELETE
}

export type DeleteFunctionsSuccessAction = {
  payload: string
  type: Types.DELETE_SUCCESS
}

export type DeleteFunctionsFailAction = {
  payload: { errors: object }
  type: Types.DELETE_FAIL
}

export type ToggleFunctionModalAction = {
  type: Types.TOGGLE_MODAL
}

export type ToggleFunctionDeleteModalAction = {
  payload: string
  type: Types.TOGGLE_DELETE_MODAL
}

export function getFunctions(data: GetFunctionsPayload): GetFunctionsAction {
  return {
    payload: data,
    type: Types.GET
  }
}

export function getFunctionsSuccess(
  data: PersonFunction[]
): GetFunctionsSuccessAction {
  return {
    payload: data,
    type: Types.GET_SUCCESS
  }
}

export function getFunctionsFail(errors: object): GetFunctionsFailAction {
  return {
    payload: { errors },
    type: Types.GET_FAIL
  }
}

export function patchFunctions(
  data: PatchFunctionPayload
): PatchFunctionsAction {
  return {
    payload: data,
    type: Types.PATCH
  }
}

export function patchFunctionsSuccess(
  payload: PatchFunctionSuccessPayload
): PatchFunctionsSuccessAction {
  return {
    payload,
    type: Types.PATCH_SUCCESS
  }
}

export function patchFunctionsFail(errors: object): PatchFunctionsFailAction {
  return {
    payload: { errors },
    type: Types.PATCH_FAIL
  }
}

export function setFunctionEdit(id: string | undefined): SetFunctionEditAction {
  return {
    payload: { id },
    type: Types.SET_EDITED
  }
}

export function postFunction(data: PostFunctionPayload): PostFunctionsAction {
  return {
    payload: data,
    type: Types.POST
  }
}

export function postFunctionSuccess(
  data: PostFunction
): PostFunctionsSuccessAction {
  return {
    payload: data,
    type: Types.POST_SUCCESS
  }
}

export function postFunctionFail(errors: object): PostFunctionsFailAction {
  return {
    payload: { errors },
    type: Types.POST_FAIL
  }
}

export function deleteFunction(
  data: DeleteFunctionPayload
): DeleteFunctionsAction {
  return {
    payload: data,
    type: Types.DELETE
  }
}

export function deleteFunctionSuccess(
  payload: string
): DeleteFunctionsSuccessAction {
  return {
    payload,
    type: Types.DELETE_SUCCESS
  }
}

export function deleteFunctionFail(errors: object): DeleteFunctionsFailAction {
  return {
    payload: { errors },
    type: Types.DELETE_FAIL
  }
}

export function toggleFunctionModal(): ToggleFunctionModalAction {
  return {
    type: Types.TOGGLE_MODAL
  }
}

export function toggleFunctionDeleteModal(
  payload: string
): ToggleFunctionDeleteModalAction {
  return {
    payload,
    type: Types.TOGGLE_DELETE_MODAL
  }
}
