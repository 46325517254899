import { FunctionData } from "./functions"
import { PartyRelation } from "./parties"
import { PermissionEnum } from "./permission"
import { TokenPayload } from "./token"

export enum MetaTypes {
  Function = "function",
  Role = "role",
}

export interface UserPayload extends Partial<TokenPayload> {
  data: User
}
export interface UserTermsOfUsePayload extends Partial<TokenPayload> {
  data: Pick<User, "party_id">
}

export interface Me extends User {
  permissions: PermissionEnum[]
}

export interface User {
  party_id: string
  first_name?: string
  last_name?: string
  email: string
  pref_language?: string
  phone_number?: string
  terms_of_use?: string | null
  terms_of_use_ip?: string | null
}

export type TCreateUser = Omit<User, "party_id">

export interface DeleteMetaPayload extends Partial<TokenPayload> {
  metadata_id: string
  metadata_type: MetaTypes
  party_relation_id: string
  teamId?: string
  organisation_id?: string
}

export interface PostMetaPayload extends Partial<TokenPayload> {
  metadata_id: string
  metadata_type: MetaTypes
  party_relation_id: string
  teamId?: string
  organisation_id?: string
}

export interface PatchMetaPayload extends PostMetaPayload {
  metadata_id: string
  metadata_type: MetaTypes
  party_relation_id: string
  current_metadata_id: string
  teamId?: string
  organisation_id?: string
}

export interface PostUserMetaSuccessPayload extends Partial<TokenPayload> {
  id: string
  metadata_id: string
  metadata_type: MetaTypes
  metadata: FunctionData
  party_relation: PartyRelation
}

export interface PatchUserMetaSuccessPayload extends Partial<TokenPayload> {
  id: string
  metadata_id: string
  metadata_type: MetaTypes
  current_metadata_id: string
  party_relation_id: string
}
