import React, { memo } from "react"
import Translation from "components/Translation"
import { Grid, InputLabel } from "@material-ui/core"
import Tooltip from "components/Tooltip"

type Props = {
  label?: string
  name?: string
  error?: string
  tooltip?: string
  tooltipValues?: object
}

const FormLabel = ({ error, label, name, tooltip, tooltipValues }: Props) => {
  return (
    <Grid spacing={0} container justify="space-between" alignItems="flex-end">
      {label && (
        <InputLabel htmlFor={name}>
          <Translation label={label} />
        </InputLabel>
      )}
      {error && (
        <InputLabel error={true}>
          <Translation label={error} />
        </InputLabel>
      )}
      {tooltip && <Tooltip label={tooltip} tooltipValues={tooltipValues} />}
    </Grid>
  )
}

export default memo(FormLabel)
