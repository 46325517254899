import React from "react"
import TooltipTrigger, { TooltipTriggerProps } from "react-popper-tooltip"
import { StyledButton, TooltipContainer } from "./style"

type TTooltipTriggerProps = Omit<TooltipTriggerProps, "children" | "tooltip">

interface IDropdown
  extends React.HTMLAttributes<HTMLDivElement>,
    Partial<TTooltipTriggerProps> {
  content: React.ReactNode
  children: React.ReactNode
}

export const Dropdown: React.FC<IDropdown> = ({
  children,
  content,
  className,
  ...props
}) => (
  <TooltipTrigger
    {...props}
    tooltip={({ tooltipRef, getTooltipProps }) => (
      <TooltipContainer
        {...getTooltipProps({
          className: "tooltip-container",
          ref: tooltipRef
        })}
      >
        {content}
      </TooltipContainer>
    )}
  >
    {({ getTriggerProps, triggerRef }) => (
      <StyledButton
        {...getTriggerProps({
          className,
          ref: triggerRef
        })}
      >
        {children}
      </StyledButton>
    )}
  </TooltipTrigger>
)

export default Dropdown
