import _ from "lodash"

import { PartyRelation } from "app/models/partyRelation"

import { Action, Types } from "./partyRelations.actions"

export type PartyRelationState = {
  dispatched: boolean
  errors: object
  loading: boolean
  partyRelations: PartyRelation[]
  showDeleteConfirmation: string
  showDeleteMultipleConfirmation: string[]
}

const initialState: PartyRelationState = {
  dispatched: false,
  errors: {},
  loading: false,
  partyRelations: [],
  showDeleteConfirmation: "",
  showDeleteMultipleConfirmation: [""],
}

export default (state = initialState, action: Action): PartyRelationState => {
  switch (action.type) {
    case Types.GET:
      return {
        ...state,
        loading: true,
      }

    case Types.GET_SUCCESS:
      return {
        ...state,
        dispatched: true,
        loading: false,
        partyRelations: action.payload,
      }

    case Types.GET_FAIL:
      return {
        ...state,
        dispatched: true,
        errors: action.payload.errors,
        loading: false,
      }

    case Types.DELETE:
      return {
        ...state,
        loading: true,
      }

    case Types.DELETE_SUCCESS:
      return {
        ...state,
        loading: false,
        showDeleteConfirmation: "",
      }

    case Types.DELETE_FAIL:
      return {
        ...state,
        loading: false,
      }

    case Types.TOGGLE_DELETE_CONFIRMATION:
      const modalId = action.payload
      const newId = modalId === state.showDeleteConfirmation ? "" : modalId

      return {
        ...state,
        showDeleteConfirmation: newId,
      }

    case Types.TOGGLE_DELETE_MULTIPLE_CONFIRMATION:
      const multipleModalIds = action.payload

      const newMultipleIds = _.isEqual(multipleModalIds, [
        state.showDeleteMultipleConfirmation,
      ])
        ? [""]
        : multipleModalIds

      return {
        ...state,
        showDeleteMultipleConfirmation: newMultipleIds,
      }

    default:
      return state
  }
}
