import React, { memo } from "react"
import Translation from "components/Translation"
import { SvgIconComponent } from "@material-ui/icons"
import MuiButton, { ButtonProps as MuiButtonProps } from "@material-ui/core/Button"

export type ButtonProps = MuiButtonProps & {
  label?: string
  startIcon?: SvgIconComponent
  endIcon?: SvgIconComponent
}

type Props = ButtonProps

const Button = ({
  children,
  color = "primary",
  label,
  size = "medium",
  variant = "contained",
  startIcon: StartIcon,
  endIcon: EndIcon,
  ...rest
}: Props) => {
  return (
    <MuiButton
      color={color}
      variant={variant}
      startIcon={StartIcon && <StartIcon />}
      endIcon={EndIcon && <EndIcon />}
      size={size}
      {...rest}
    >
      {label ? <Translation label={label} /> : <>{children}</>}
    </MuiButton>
  )
}

export default memo(Button)
