import { Child, MyGroupPayload } from "app/models/groups"
import { TokenPayload } from "app/models/token"
import { Me, User, UserPayload, UserTermsOfUsePayload } from "app/models/user"
import { AxiosError } from "axios"

export enum Types {
  GET = "ME_GET",
  GET_SUCCESS = "ME_GET_SUCCESS",
  GET_FAIL = "ME_GET_FAIL",
  PATCH = "ME_PATCH",
  PATCH_SUCCESS = "ME_PATCH_SUCCESS",
  PATCH_FAIL = "ME_PATCH_FAIL",
  PATCH_TERMS_OF_USE = "ME_PATCH_TERMS_OF_USE",
  PATCH_TERMS_OF_USE_SUCCESS = "ME_PATCH_TERMS_OF_USE_SUCCESS",
  PATCH_TERMS_OF_USE_FAIL = "ME_PATCH_TERMS_OF_USE_FAIL",
  GET_GROUPS = "ME_GET_GROUPS",
  GET_GROUPS_SUCCESS = "ME_GET_GROUPS_SUCCESS"
}

export type Action =
  GetMeAction
  | GetMeSuccessAction
  | GetMeFailAction
  | PatchMeAction
  | PatchMeSuccessAction
  | PatchMeFailAction
  | PatchMeTermsOfUseAction
  | PatchMeTermsOfUseSuccessAction
  | PatchMeTermsOfUseFailAction
  | GetMyGroupsAction
  | GetMyGroupsSuccessAction

export type GetMeAction = {
  payload: TokenPayload
  type: Types.GET
}

export type GetMeSuccessAction = {
  payload?: Me
  type: Types.GET_SUCCESS
}

export type GetMeFailAction = {
  payload: { errors: AxiosError }
  type: Types.GET_FAIL
}

export type PatchMeAction = {
  payload: UserPayload
  type: Types.PATCH
}

export type PatchMeSuccessAction = {
  payload?: User
  type: Types.PATCH_SUCCESS
}

export type PatchMeFailAction = {
  payload: { errors: AxiosError }
  type: Types.PATCH_FAIL
}
export type PatchMeTermsOfUseAction = {
  payload: UserTermsOfUsePayload
  type: Types.PATCH_TERMS_OF_USE
}

export type PatchMeTermsOfUseSuccessAction = {
  payload?: User
  type: Types.PATCH_TERMS_OF_USE_SUCCESS
}

export type PatchMeTermsOfUseFailAction = {
  payload: { errors: AxiosError }
  type: Types.PATCH_TERMS_OF_USE_FAIL
}

export type GetMyGroupsAction = {
  payload: MyGroupPayload
  type: Types.GET_GROUPS
}

export type GetMyGroupsSuccessAction = {
  payload?: Child[]
  type: Types.GET_GROUPS_SUCCESS
}

export function getMe(payload: TokenPayload): GetMeAction {
  return {
    payload,
    type: Types.GET
  }
}

export function getMeSuccess(payload: Me | undefined): GetMeSuccessAction {
  return {
    payload,
    type: Types.GET_SUCCESS
  }
}

export function getMeFail(errors: AxiosError): GetMeFailAction {
  return {
    payload: { errors },
    type: Types.GET_FAIL
  }
}

export function patchMe(payload: UserPayload): PatchMeAction {
  return {
    payload,
    type: Types.PATCH
  }
}

export function patchMeSuccess(payload: User): PatchMeSuccessAction {
  return {
    payload,
    type: Types.PATCH_SUCCESS
  }
}

export function patchMeFail(errors: AxiosError): PatchMeFailAction {
  return {
    payload: { errors },
    type: Types.PATCH_FAIL
  }
}

export function patchMeTermsOfUse(payload: UserTermsOfUsePayload): PatchMeTermsOfUseAction {
  return {
    payload,
    type: Types.PATCH_TERMS_OF_USE
  }
}

export function patchMeTermsOfUseSuccess(payload: User): PatchMeTermsOfUseSuccessAction {
  return {
    payload,
    type: Types.PATCH_TERMS_OF_USE_SUCCESS
  }
}

export function patchMeTermsOfUseFail(errors: AxiosError): PatchMeTermsOfUseFailAction {
  return {
    payload: { errors },
    type: Types.PATCH_TERMS_OF_USE_FAIL
  }
}

export function getMyGroups(payload: MyGroupPayload): GetMyGroupsAction {
  return {
    payload,
    type: Types.GET_GROUPS
  }
}

export function getMyGroupsSuccess(payload: Child[]): GetMyGroupsSuccessAction {
  return {
    payload,
    type: Types.GET_GROUPS_SUCCESS
  }
}
