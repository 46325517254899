import { AxiosPromise } from "axios"
import { usersApi } from "./api"
import { TokenPayload } from "app/models/token"

export interface GetSurveyTypesPayload extends Partial<TokenPayload> {
  is_active: boolean
}

export const getSurveyTypes = ({ is_active, ...data }: GetSurveyTypesPayload): AxiosPromise => {
  return usersApi(`/survey_types?is_active=${is_active}`, {
    data,
    method: "GET",
  })
}
