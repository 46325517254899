import { useSelector } from "react-redux"

import * as organisationSelectors from "app/redux/organisation/organisation.selectors"

export const useOrganisation = () => {
  const organisation = useSelector(organisationSelectors.organisation)

  return {
    organisation,
  }
}
