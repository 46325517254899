import { SurveyType } from "app/models/surveyType";

import { Action, Type } from "./surveyTypes.actions"
import { AxiosError } from "axios";

export type SurveyTypesState = {
  data: SurveyType[]
  errors: AxiosError | null | undefined
  loading: boolean
}

const initialState: SurveyTypesState = {
  data: [],
  errors: null,
  loading: false,
}

export default (state = initialState, action: Action): SurveyTypesState => {
  switch (action.type) {
    case Type.GET_SURVEY_TYPES:
      return {
        ...state,
        errors: null,
        loading: true,
      }
    case Type.GET_SURVEY_TYPES_SUCCESS:
      return {
        ...state,
        data: action.payload,
        loading: false
      }
    case Type.GET_SURVEY_TYPES_FAIL:
      return {
        ...state,
        errors: action.payload.errors,
        loading: false,
      }
    default:
      return state
  }
}
