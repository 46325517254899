import { User } from "app/models/user"
import { object, string } from "yup"
import { locale } from "i18n/config"
import { phoneRegExp } from "app/regex"

export type EditProfileUser = Omit<User, "party_id" | "terms_of_use" | "terms_of_use_ip">

const initialValues: EditProfileUser = {
  email: "",
  first_name: "",
  last_name: "",
  phone_number: "",
  pref_language: locale,
}

export const getInitialValues = (user: User | undefined): EditProfileUser => {
  if (!user) return initialValues

  return Object.keys(initialValues)
    .reduce((obj: Partial<EditProfileUser>, key: string) => {
      obj[key as keyof EditProfileUser] = user[key as keyof EditProfileUser]
      return obj
    }, {}) as EditProfileUser
}

export const validationSchema = object({
  email: string()
    .email("error.message.email")
    .required("error.message.required"),
  first_name: string().required("error.message.required"),
  last_name: string().required("error.message.required"),
  phone_number: string().matches(phoneRegExp, "error.message.telephone").nullable()
})
