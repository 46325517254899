import { Box, Icon, Typography } from "@material-ui/core"
import { useAuth0 } from "app/context/AuthContext"
import GeneralErrorIcon from "assets/boxIcons/GeneralErrorIcon"
import Button from "components/Button"
import Title from "components/Title"
import Translation from "components/Translation"
import React from "react"
import { ErrorContainer, IconWrapper } from "./style"

const GeneralError = () => {
  const { logout } = useAuth0()

  const handleClick = () => {
    logout({
      returnTo: window.location.origin
    })
  }
  return (
    <ErrorContainer>
      <Box
        display="flex"
        justifyContent="center"
        alignItems="center"
        height="100%"
      >
        <IconWrapper>
          <Icon component={GeneralErrorIcon} />
        </IconWrapper>
        <Box maxWidth="30%" marginLeft="70px">
          <Title value={<Translation label="global.error.title" />} />
          <Box mt={3}>
            <Typography variant="caption">
              <Translation label="global.error.message" />
            </Typography>
          </Box>
          <Box mt={18}>
            <Button
              onClick={handleClick}
              label="global.error.action"
              color="secondary"
            />
          </Box>
        </Box>
      </Box>
    </ErrorContainer>
  )
}

export default GeneralError
