import { AxiosError } from "axios"

import People, { PeoplePayload } from "app/models/people"

export enum Types {
  GET = "PEOPLE_GET",
  GET_SUCCESS = "PEOPLE_GET_SUCCESS",
  GET_FAIL = "PEOPLE_GET_FAIL",
  REFRESH_PEOPLE = "REFRESH_PEOPLE",
}

export type Action =
  | GetPeopleAction
  | GetPeopleSuccessAction
  | GetPeopleFailAction

export type GetPeopleAction = {
  payload: PeoplePayload
  type: Types.GET
}

export type GetPeopleSuccessAction = {
  payload: People[]
  type: Types.GET_SUCCESS
}

export type GetPeopleFailAction = {
  payload: { errors: AxiosError }
  type: Types.GET_FAIL
}

export function getPeople(data: PeoplePayload): GetPeopleAction {
  return {
    payload: data,
    type: Types.GET,
  }
}

export function getPeopleSuccess(data: People[]): GetPeopleSuccessAction {
  return {
    payload: data,
    type: Types.GET_SUCCESS,
  }
}

export function getPeopleFail(errors: AxiosError): GetPeopleFailAction {
  return {
    payload: { errors },
    type: Types.GET_FAIL,
  }
}
