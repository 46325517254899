import { Action, Types } from "./roles.actions"
import Role from "app/models/roles"

export type RolesState = {
  errors: object
  loading: boolean
  roles: Role[]
}

const initialState: RolesState = {
  errors: {},
  loading: false,
  roles: []
}

export default (state = initialState, action: Action): RolesState => {
  switch (action.type) {
    case Types.GET:
      return {
        ...state,
        loading: true
      }

    case Types.GET_SUCCESS:
      return {
        ...state,
        loading: false,
        roles: action.payload
      }

    case Types.GET_FAIL:
      return {
        ...state,
        errors: action.payload.errors,
        loading: false
      }

    default:
      return state
  }
}
